import ApiService from './services/api.service';
import TokenService from './services/token.service';
import {
  promptWindow,
  showHideLoadingMask,
  loadExplorer,
  data,
  duplicate,
  deleteCase,
  deleteTree,
  loadCasesInExplorer,
} from './services/app/cases.service';
import {
  errorAlert,
  setStatusBar,
  messageConfirmBox,
} from './services/app/global.service';
import appMessages from '../app/i18n/en/appMessages.json';

try {
  if (TokenService.hasToken()) {
    loadExplorer();
  }
} catch (e) {
  errorAlert(appMessages.app_general_messages.error, appMessages.serverError);
}

let caseComp, caseId, treeParentId, treeId, items;

TR2.Cases = {
  extend: 'Ext.tree.Panel',
  id: 'cases',
  xtype: 'tr2-cases',
  useArrows: true,
  autoScroll: true,
  autoLoad: false,
  stateful: true,
  containerScroll: true,
  rootVisible: false,
  waitMsg: 'Loading...',
  store: data,
  columns: [
    {
      xtype: 'treecolumn',
      itemId: 'header',
      flex: 2,
      sortable: true,
      dataIndex: 'name',
      editable: true,
      editor: {
        completeOnEnter: true,
        field: {
          xtype: 'textfield',
          allowBlank: false,
        },
        listeners: {
          complete(_x: any, _value: any): void {
            items = Ext.getCmp('cases').getSelectionModel().selected.items;
            let selectedItem;
            items.forEach((key) => {
              selectedItem = key.data;
              if (key.data.caseDataID) {
                caseId = key.data.caseDataID;
              }
              treeParentId = key.parentNode.data.caseDataID;
              treeId = key.data.id;
            });

            if (!selectedItem) {
              return setStatusBar('Please select a case or a tree');
            }

            if (caseId && selectedItem.type === 'case') {
              ApiService.saveCase(caseId, {
                name: _value,
              })
                .then((response) => {
                  setStatusBar(`${appMessages.case.renamed} ${response.name}`);
                })
                .catch(() => {
                  setStatusBar(
                    `${appMessages.case.error} ${appMessages.serverError}.`,
                  );
                  errorAlert(
                    appMessages.app_general_messages.error,
                    appMessages.serverError,
                  );
                });
              return;
            } else {
              setStatusBar(appMessages.app_general_messages.no_selection);
            }

            if (treeParentId && selectedItem.type === 'tree') {
              ApiService.saveTree(treeParentId, treeId, {
                name: _value,
              })
                .then((response) => {
                  setStatusBar(`${appMessages.tree.renamed} ${response.name}`);
                })
                .catch(() => {
                  setStatusBar(
                    `${appMessages.case.error} ${appMessages.serverError}.`,
                  );
                  errorAlert(
                    appMessages.app_general_messages.error,
                    appMessages.serverError,
                  );
                });
            } else {
              setStatusBar(appMessages.tree.no_selection);
            }
          },
        },
      },
    },
  ],
  contextMenu: new Ext.menu.Menu({
    items: [
      {
        iconCls: 'x-fa fa-plus',
        text: appMessages.context_menu.new_case,
        handler: function (): void {
          caseComp = Ext.getCmp('cases');
          promptWindow(
            true,
            appMessages.context_menu.new_case,
            'new-case',
            Ext.Msg.OK,
            function (btn, value) {
              if (btn === 'ok') {
                showHideLoadingMask(caseComp);
                ApiService.createCase({
                  name: value,
                })
                  .then((result) => {
                    loadExplorer();
                    setStatusBar(`${appMessages.case.saved} ${result.name}`);
                  })
                  .catch(() => {
                    errorAlert(
                      appMessages.app_general_messages.error,
                      appMessages.serverError,
                    );
                  });
              }
            },
          );
        },
      },
      {
        iconCls: 'x-fa fa-sitemap',
        text: appMessages.context_menu.new_tree,
        handler: function (): void {
          caseComp = Ext.getCmp('cases');
          items = Ext.getCmp('cases').getSelectionModel().selected.items;
          items.forEach((key) => {
            if (key.data.caseDataID) {
              caseId = key.data.caseDataID;
            }
            treeParentId = key.parentNode.data.caseDataID;
          });

          if (caseId) {
            promptWindow(
              true,
              appMessages.context_menu.new_tree,
              'new-tree',
              Ext.Msg.OK,
              function (btn, value) {
                if (btn === 'ok') {
                  showHideLoadingMask(caseComp);
                  ApiService.createTree(caseId, {
                    name: value,
                    xml: ApiService.saveXML(value, false),
                  })
                    .then(() => {
                      loadExplorer(caseId);
                      setStatusBar(`${appMessages.tree.saved} ${value}`);
                    })
                    .catch(() => {
                      errorAlert(
                        appMessages.app_general_messages.error,
                        appMessages.serverError,
                      );
                    });
                }
              },
            );
          }

          if (treeParentId) {
            promptWindow(
              true,
              appMessages.context_menu.new_tree,
              'new-case-tree',
              Ext.Msg.OK,
              function (btn, value) {
                if (btn === 'ok') {
                  showHideLoadingMask(caseComp);
                  ApiService.createTree(treeParentId, {
                    name: value,
                    xml: ApiService.saveXML(value, false),
                  })
                    .then(() => {
                      loadExplorer(treeParentId);
                      setStatusBar(`${appMessages.tree.saved} ${value}`);
                    })
                    .catch(() => {
                      errorAlert(
                        appMessages.app_general_messages.error,
                        appMessages.serverError,
                      );
                    });
                }
              },
            );
          }
        },
      },
      {
        icon: `${__BASE_URL}/ext/appimages/bowtie_add.png`,
        text: appMessages.context_menu.new_bowtie,
        handler: function (): void {
          caseComp = Ext.getCmp('cases');
          let projectId, parentId;
          const items = Ext.getCmp('cases').getSelectionModel().selected.items;
          items.forEach((key) => {
            projectId = key.data.caseDataID;
            parentId = key.parentNode.data.caseDataID;
          });

          if (projectId) {
            promptWindow(
              true,
              appMessages.context_menu.new_bowtie,
              'new-bowtie',
              Ext.Msg.OK,
              function (btn: string, value: string): void {
                if (btn === 'ok') {
                  showHideLoadingMask(caseComp);
                  ApiService.createTree(projectId, {
                    name: value,
                    bowtie: true,
                    xml: ApiService.saveXML(value, true),
                  })
                    .then(() => {
                      loadExplorer(projectId);
                      setStatusBar(`${appMessages.tree.saved} ${value}`);
                    })
                    .catch(() => {
                      errorAlert(
                        appMessages.app_general_messages.error,
                        appMessages.serverError,
                      );
                    });
                }
              },
            );
            return;
          }

          if (parentId) {
            promptWindow(
              true,
              'New bowtie',
              'new-bowtie',
              Ext.Msg.OK,
              function (btn: string, value: string): void {
                if (btn === 'ok') {
                  showHideLoadingMask(caseComp);
                  ApiService.createTree(parentId, {
                    name: value,
                    bowtie: true,
                    xml: ApiService.saveXML(value, true),
                  })
                    .then(() => {
                      loadExplorer(parentId);
                      setStatusBar(`${appMessages.tree.saved} ${value}`);
                    })
                    .catch(() => {
                      errorAlert(
                        appMessages.app_general_messages.error,
                        appMessages.serverError,
                      );
                    });
                }
              },
            );
          }
        },
      },
      {
        iconCls: 'x-fa fa-copy',
        text: appMessages.context_menu.duplicate,
        handler: function (): void {
          caseComp = Ext.getCmp('cases');
          const items = Ext.getCmp('cases').getSelectionModel().selected.items;
          let projectId, treeId, type;
          items.forEach((key) => {
            type = key.data;
            if (!key.data.bowtie) {
              projectId = key.parentNode.data.caseDataID;
              treeId = key.data.id;
            } else {
              projectId = key.parentNode.data.caseDataID;
              treeId = key.data.id;
            }
          });
          duplicate(type.bowtie, projectId, treeId, caseComp);
        },
      },
      '-',
      {
        iconCls: 'x-fa fa-trash',
        text: appMessages.context_menu.delete,
        handler: function (): void {
          caseComp = Ext.getCmp('cases');
          let caseDataId, projectId, treeId, selectedItem;
          const items = Ext.getCmp('cases').getSelectionModel().selected.items;
          items.forEach((key) => {
            selectedItem = key.data;
            if (key.data.caseDataID) {
              caseDataId = key.data.caseDataID;
            }
            treeId = key.data.id;
            projectId = key.parentNode.data.caseDataID;
          });
          messageConfirmBox(
            appMessages.app_general_messages.delete,
            `${appMessages.app_general_messages.delete_question} ${selectedItem.name}?`,
            (btn: string) => {
              if (btn == 'yes') {
                if (selectedItem.type === 'case') {
                  deleteCase(caseComp, caseDataId);
                } else if (selectedItem.type === 'tree') {
                  deleteTree(caseComp, projectId, treeId);
                }
              }
            },
          );
        },
      },
      {
        iconCls: 'x-fa fa-pencil-alt',
        text: appMessages.context_menu.rename,
        handler: function (): void {
          let id;
          const caseComponent = Ext.getCmp('cases');
          const items = Ext.getCmp('cases').getSelectionModel().selected.items;
          const caseComponentEditor = Ext.getCmp('cases').findPlugin(
            'cellediting',
          );
          items.forEach((key) => (id = key.id));
          caseComponentEditor.startEdit(
            caseComponent.getStore().getNodeById(id),
            caseComponent.down('treecolumn'),
          );
        },
      },
    ],
  }),
  plugins: {
    pluginId: 'cellediting',
    ptype: 'cellediting',
  },
  listeners: {
    render: function (): void {
      loadExplorer();
      Ext.getBody().on('contextmenu', Ext.emptyFn, null, { stopEvent: true });
    },
    celldblclick: function (
      _x: any,
      _td: any,
      _cellIndex: any,
      _record: any,
      _tr: any,
      _rowIndex: any,
      _e: any,
      _eOpts: any,
    ): void {
      if (_record.data.type != 'tree') return;
      if (window.canvas.isDirty()) {
        messageConfirmBox(
          appMessages.app_general_messages.save,
          appMessages.app_general_messages.save_question,
          function (btn: string) {
            if (btn == 'yes') {
              window.canvas.save().then(() => {
                loadCasesInExplorer(
                  _record.parentNode.data.caseDataID,
                  _record.data.id,
                );
              });
            } else {
              loadCasesInExplorer(
                _record.parentNode.data.caseDataID,
                _record.data.id,
              );
            }
          },
        );
      } else {
        loadCasesInExplorer(
          _record.parentNode.data.caseDataID,
          _record.data.id,
        );
      }
    },
    select(): void {
      Ext.getCmp('new_tree').setDisabled(false);
      Ext.getCmp('new_bowtie').setDisabled(false);
    },
    deselect(): void {
      Ext.getCmp('new_tree').setDisabled(true);
      Ext.getCmp('new_bowtie').setDisabled(true);
    },
    itemContextMenu: function (
      _tree: any,
      record: {
        getOwnerTree: () => { (): any; new (): any; contextMenu: any };
        data: { type: any };
      },
      _item: any,
      _index: any,
      e: { getXY: () => any; stopEvent: () => void },
    ): void {
      const contextMenu = record.getOwnerTree().contextMenu;
      const position = e.getXY();
      e.stopEvent();
      contextMenu.showAt(position);
      switch (record.data.type) {
        case 'case':
          contextMenu.items.get(1).enable();
          contextMenu.items.get(2).enable();
          contextMenu.items.get(3).disable();
          contextMenu.items.get(4).enable();
          contextMenu.items.get(5).enable();
          contextMenu.items.get(6).enable();
          break;
        case 'tree':
          contextMenu.items.get(1).enable();
          contextMenu.items.get(2).enable();
          contextMenu.items.get(3).enable();
          contextMenu.items.get(4).enable();
          contextMenu.items.get(5).enable();
          contextMenu.items.get(6).enable();
          break;
        default:
          contextMenu.items.get(1).disable();
          contextMenu.items.get(2).disable();
          contextMenu.items.get(3).disable();
          contextMenu.items.get(4).disable();
          contextMenu.items.get(5).disable();
          contextMenu.items.get(6).disable();
          break;
      }
    },
  },
};

Ext.define('tr2-cases', TR2.Cases);
