class DeleteIcon {
  deleteIcon: JQuery<HTMLElement>;

  constructor() {
    this.deleteIcon = this.createDeleteButton();
  }

  createDeleteButton(): JQuery<HTMLElement> {
    const overlayMenu = $(
      `<div class='overlayMenu'>
                <span class='deleteIcon'>
                    <i class="fas fa-trash"></i>
                </span>
                <span id='count'>
                    <span id='result'></span>
                </span>
            </div>`,
    );
    $('body').append(overlayMenu);
    return overlayMenu;
  }

  show(): void {
    this.deleteIcon.addClass('active');
  }

  hide(): void {
    this.deleteIcon.removeClass('active');
  }
}

export default new DeleteIcon();
