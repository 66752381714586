const options = [
  '- None -',
  'Design (DE)',
  'Hardware (HW)',
  'Maintenance (MM)',
  'Housekeeping (HK)',
  'Err Enf Conditions (EC)',
  'Procedures (PR)',
  'Training (TR)',
  'Communications (CO)',
  'Incompatible Goals (IG)',
  'Organisation (OR)',
  'Defences (DF)',
];

class LatentFailureLabelSelect {
  modal: HTMLDivElement;
  items: HTMLLIElement[] = [];

  constructor() {
    this.modal = this.createModal();
  }

  createModal(): HTMLDivElement {
    const wrapper = document.createElement('div');
    wrapper.classList.add('lfs-modal-wrapper');
    document.body.append(wrapper);

    const backdrop = document.createElement('div');
    backdrop.classList.add('lfs-modal-backdrop');
    wrapper.append(backdrop);

    const content = document.createElement('div');
    content.classList.add('lfs-modal-content');
    wrapper.append(content);

    const list = document.createElement('ul');
    list.classList.add('lfs-modal-list');
    content.append(list);

    for (let key in options) {
      const option = options[key];
      const item = document.createElement('li');
      item.appendChild(document.createTextNode(option));
      item.classList.add('lfs-modal-item');
      list.append(item);
      item.setAttribute('data-value', key);
      item.addEventListener('click', (event: MouseEvent) => {
        this.onItemClick(event.target as HTMLLIElement);
      });
      this.items.push(item);
    }

    return wrapper;
  }

  onItemClick(item: HTMLLIElement): void {
    this.modal.dispatchEvent(
      new CustomEvent('select', { detail: item.getAttribute('data-value') }),
    );
  }

  hide(): void {
    this.modal.classList.remove('active');
  }

  setSelected(label: number): void {
    for (const item of this.items) {
      if (parseInt(item.getAttribute('data-value') as string) === label) {
        item.classList.add('selected');
      } else {
        item.classList.remove('selected');
      }
    }
  }

  getTextByKey(key: number): string {
    return options[key];
  }

  show(label: number): Promise<number> {
    this.modal.classList.add('active');
    this.setSelected(label);
    return new Promise((resolve) => {
      this.modal.addEventListener(
        'select',
        (event: CustomEventInit) => {
          this.hide();
          resolve(parseInt(event.detail));
        },
        {
          once: true,
        },
      );
    });
  }
}

export default new LatentFailureLabelSelect();
