TR2.Explorer = {
  extend: 'Ext.Panel',
  xtype: 'tr2-explorer',
  id: 'explorer',
  title: 'Explorer',
  layout: 'border',
  items: [
    {
      xtype: 'tr2-cases',
      split: true,
      region: 'north',
      style: 'border-top: 0 none;',
      height: 200,
      layout: 'fit',
    },
    {
      title: 'Case actions',
      region: 'center',
      items: [
        {
          id: 'actions',
          xtype: 'tr2-actions',
          height: 250,
        },
        {
          id: 'figure',
          xtype: 'tr2-figurebar',
          title: 'Actions',
        },
      ],
    },
  ],
};

Ext.define('tr2-explorer', TR2.Explorer);
