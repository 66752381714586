import BarrierElement from './barrierElement';
import ContextMenu from "../ui/ContextMenu";

export default class InadequateBarrier extends BarrierElement {
  static get _className(): string {
    return 'InadequateBarrier';
  }
  public _className = 'InadequateBarrier';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(
      x,
      y,
      guid,
      w,
      h,
      `<svg width="${w}" height="${h}" xmlns="http://www.w3.org/2000/svg"><path stroke="#000000" d="M161.75 40C165.2 40 168 42.8 168 46.25C168 56.2 168 79.8 168 89.75C168 93.2 165.2 96 161.75 96C129.4 96 38.6 96 6.25 96C2.8 96 0 93.2 0 89.75C0 79.8 0 56.2 0 46.25C0 42.8 2.8 40 6.25 40C38.6 40 129.4 40 161.75 40Z"></path><path stroke="#000000" d="M72.27 0L96.27 0L96.27 16L72.27 16L72.27 0Z"></path><path stroke="#000000" d="M72.27 26L96.27 26L96.27 40L72.27 40L72.27 24Z"></path></svg>`,
    );
    this.setFillColor('#9c9c9c', 2);
  }

  showContextMenu(x: number, y: number) {
    const hasEvent = !!window.canvas.getFirstEvent();
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);
    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    if (window.canvas.isBowtie) {
      this.elementContextMenu.titleFactory(
        contextMenuTitle.element,
        contextMenuTitle.id,
      );
      if (!hasEvent) {
        this.elementContextMenu.addElementFactory('event');
      }
      this.elementContextMenu.addElementFactory('target');
      this.elementContextMenu.addElementFactory('hazard');
      this.elementContextMenu.addElementFactory('active failure');
      this.elementContextMenu.convertElement(this, 'effective barrier');
      this.elementContextMenu.convertElement(this, 'failed barrier');
      this.elementContextMenu.convertElement(this, 'missing barrier');
      this.elementContextMenu.defaultElementFactory(this);
      this.elementContextMenu.show(x, y);
      return;
    }
    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.addElementFactory('event');
    this.elementContextMenu.addElementFactory('target');
    this.elementContextMenu.addElementFactory('hazard');
    this.elementContextMenu.convertElement(this, 'effective barrier');
    this.elementContextMenu.convertElement(this, 'failed barrier');
    this.elementContextMenu.convertElement(this, 'missing barrier');
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
