import BarrierElement from './barrierElement';
import ContextMenu from '../ui/ContextMenu';

export default class MissingBarrier extends BarrierElement {
  static get _className(): string {
    return 'MissingBarrier';
  }
  public _className = 'MissingBarrier';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(x, y, guid, w, h);

    setTimeout(() => {
      this.d2dElement.getTopLevelShapeElement().items[1].node.style.strokeDasharray = 5;
    }, 50);
  }

  showContextMenu(x: number, y: number): void {
    const hasEvent = !!window.canvas.getFirstEvent();
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);
    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    if (window.canvas.isBowtie) {
      this.elementContextMenu.titleFactory(
        contextMenuTitle.element,
        contextMenuTitle.id,
      );
      if (!hasEvent) {
        this.elementContextMenu.addElementFactory('event');
      }
      this.elementContextMenu.addElementFactory('target');
      this.elementContextMenu.addElementFactory('hazard');
      this.elementContextMenu.convertElement(this, 'effective barrier');
      this.elementContextMenu.convertElement(this, 'failed barrier');
      this.elementContextMenu.convertElement(this, 'inadequate barrier');
      this.elementContextMenu.defaultElementFactory(this);
      this.elementContextMenu.show(x, y);
      return;
    }
    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.addElementFactory('event');
    this.elementContextMenu.addElementFactory('target');
    this.elementContextMenu.addElementFactory('hazard');
    this.elementContextMenu.convertElement(this, 'effective barrier');
    this.elementContextMenu.convertElement(this, 'failed barrier');
    this.elementContextMenu.convertElement(this, 'inadequate barrier');
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
