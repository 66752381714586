import Canvas from '../../canvas';
import { LoaderLinkInterface } from './LoaderLinkInterface';
import { LoaderElementInterface } from './LoaderElementInterface';
import ElementFactory from '../utils/ElementFactory';

export default class CanvasLoader {
  elements: LoaderElementInterface[];
  links: LoaderLinkInterface[];

  constructor(
    elements: LoaderElementInterface[],
    links: LoaderLinkInterface[],
  ) {
    this.elements = elements;
    this.links = links;
  }

  process(canvas: Canvas): void {
    // Create events first to handle bowties
    for (const element of this.elements) {
      if (element.type === 'event') {
        canvas.add(ElementFactory.createFromLoader(element));
      }
    }
    for (const element of this.elements) {
      if (element.type !== 'event') {
        canvas.add(ElementFactory.createFromLoader(element));
      }
    }

    for (const link of this.links) {
      this.handleLink(canvas, link);
    }
  }

  handleLink(canvas: Canvas, linkData: LoaderLinkInterface): void {
    const fromElement = canvas.getByGuid(linkData.from);
    const toElement = canvas.getByGuid(linkData.to);
    if (!fromElement || !toElement) {
      return;
    }
    canvas.connectElements(fromElement, toElement);
  }
}
