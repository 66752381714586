import ElementFactory from '../utils/ElementFactory';
import draw2d from 'draw2d';

export default class ContextMenu {
  contextMenu: HTMLDivElement;
  target: HTMLElement;
  contextMenuItems: { [key: string]: HTMLDivElement } = {};
  contextMenuID: string;
  hideListener: any;

  constructor(target) {
    this.target = target;
    this.contextMenuID = 'contextMenuWrapper';
    this.hideListener = this.hide.bind(this);

    this.contextMenu = document.createElement('div');
    this.contextMenu.id = this.contextMenuID;

    target.addEventListener('click', this.hideListener);
  }

  reset(): void {
    this.contextMenu.innerHTML = '';
  }

  // Create context menu
  render(): void {
    const currentMenu = document.getElementById(this.contextMenuID);

    if (currentMenu) {
      currentMenu.remove();
    }

    this.target.appendChild(this.contextMenu);
  }

  // Add item to context menu
  addItem(label: string, callback: Function, id: string): void {
    const item = document.createElement('div');
    const menuLabel = document.createTextNode(label);
    item.id = `context-${id}`;
    item.classList.add('context-item');
    item.appendChild(menuLabel);
    this.contextMenu.appendChild(item);
    item.addEventListener('click', (e) => {
      this.hide();
      this.removeItems();
      const rect = window.canvas.wrapper.getBoundingClientRect();
      callback(e.clientX - rect.left, e.clientY - rect.top, this);
    });
    this.contextMenuItems[id] = item;
  }

  setItemActiveState(id: string, isActive: boolean): void {
    if (!this.contextMenuItems[id]) {
      return;
    }

    if (isActive) {
      this.contextMenuItems[id].classList.add('active');
      this.contextMenuItems[id].style.background = '#dadbe0';
    } else {
      this.contextMenuItems[id].classList.remove('active');
      this.contextMenuItems[id].style.background = 'none';
    }
  }

  // remove contextMenuItems
  removeItems(): void {
    for (const item in this.contextMenuItems) {
      this.contextMenuItems[item].remove();
    }
  }

  replaceItems(contextMenuItems): void {
    this.contextMenuItems = contextMenuItems;
  }

  hide(): void {
    this.contextMenu.style.display = 'none';
  }

  addDivider(): void {
    const divider = document.createElement('hr');
    divider.style.opacity = '0.3';
    divider.style.padding = '0';
    divider.style.margin = '2px 0';
    this.contextMenu.appendChild(divider);
  }

  handleMouseEvent(event: any): any {
    event.preventDefault();
    return event;
  }

  // add element
  addElementFactory(elementType: string, portIndex?: number): void {
    const selectedElement = window.canvas.getSingleSelectedElement()!;

    if (!selectedElement) {
      return;
    }

    const offset = 50;
    const elementHeight = selectedElement.height + offset;

    return this.addItem(
      `Add ${elementType}`,
      (x: number, y: number) => {
        const event = window.canvas.getFirstEvent()!;
        x = x / window.canvas.getZoom();
        y = y / window.canvas.getZoom();

        if (!selectedElement) {
          return;
        }

        if (
          window.canvas.isBowtie &&
          event &&
          event.x === selectedElement.x &&
          elementType === 'target'
        ) {
          x = selectedElement.x + selectedElement.width + offset;
        } else {
          x = selectedElement.x - selectedElement.width - offset;
        }

        if (elementType === 'hazard') {
          const lowestHazard = window.canvas.getLowestPositionedElementOfType(
            elementType,
          );

          if (!lowestHazard) {
            y = selectedElement.y - elementHeight;
          } else {
            y = lowestHazard.y + elementHeight;
            x = lowestHazard.x;
          }
        }

        if (elementType === 'target') {
          const lowestTarget = window.canvas.getLowestPositionedElementOfType(
            elementType,
          );

          if (!lowestTarget) {
            y = selectedElement.y - elementHeight;
          } else {
            y = lowestTarget.y + elementHeight;
            x = lowestTarget.x;
          }
        }

        const element = ElementFactory.create(elementType, x, y);
        window.canvas.add(element, true, true);
        if (selectedElement) {
          window.canvas.connectElements(selectedElement, element, portIndex);
        }
      },
      elementType,
    );
  }

  addBarrierFactory(barrierType: string) {
    // Pak hier het geselecteerde element
    const selectedElement = window.canvas.getSingleSelectedElement();
    if (!selectedElement) return;
    this.addItem(
      `Add ${barrierType}`,
      () => {
        // Heeft de element een parent en zit er een connection tussen
        const validTypes = [
          'event',
          'event hazard',
          'event target',
          'failed barrier',
          'missing barrier',
          'effective barrier',
        ];
        const parent = selectedElement.parents.find((p) => {
          return validTypes.includes(
            ElementFactory.findTypeOfElement(p.element),
          );
        });

        if (!parent) {
          return;
        }

        // Voeg barrier toe halverwege in de connection
        const lerp = parent.connection.lerp(0.5);
        const barrier = ElementFactory.create(barrierType, lerp.x - 80, lerp.y);
        const command = new draw2d.command.CommandInsert(
          window.canvas,
          parent.connection,
          barrier,
          parent.element,
          selectedElement,
        );
        window.canvas.d2dCanvas.getCommandStack().execute(command);
      },
      barrierType,
    );
  }

  convertElement(element: any, type) {
    return this.addItem(
      `Convert to ${type}`,
      () => {
        if (!element.name && !element.description) {
          return element.convertTo(type, false);
        }

        const keepElement = (btn) => {
          const keepElementData = btn === 'yes';
          element.convertTo(type, keepElementData);
        };

        Ext.MessageBox.confirm(
          `Convert to ${type}`,
          `Keep label & description from ${element._className}`,
          keepElement,
        );
      },
      type,
    );
  }

  titleFactory(title: string, id: string) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.addItem(title, () => {}, id);
  }

  capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  // default menu items
  defaultElementFactory(element?) {
    if (element) {
      this.addItem(
        'Delete',
        () => {
          window.canvas.d2dCanvas
            .getCommandStack()
            .execute(new draw2d.command.CommandDelete(element._d2dElement));
        },
        'delete',
      );
      this.addItem(
        'Edit description',
        () => {
          Ext.MessageBox.show({
            prompt: true,
            title: `${this.capitalize(element)} descriptions`,
            id: 'comment',
            buttons: Ext.Msg.OK,
            value: element.description !== '' ? element.description : '',
            fn: function (btn, value) {
              if (btn === 'ok') {
                element.description = value;
              }
            },
            scope: this,
          });
        },
        'edit',
      );
      return;
    }
    this.addDivider();
    this.addItem(
      'Drag subtree',
      () => {
        window.canvas.setDragSubtree(!window.canvas.dragSubtree);
      },
      'drag-subtree',
    );
    this.addItem(
      'Zoom fit',
      () => {
        window.canvas.zoomFit();
      },
      'zoom-fit',
    );
    this.addItem(
      'Zoom in',
      () => {
        window.canvas.setZoom(window.canvas.getZoom() + 0.1);
      },
      'zoom-in',
    );
    this.addItem(
      'Zoom out',
      () => {
        window.canvas.setZoom(window.canvas.getZoom() - 0.1);
      },
      'zoom-out',
    );
    this.addItem(
      'Reset zoom',
      () => {
        window.canvas.setZoom(1);
      },
      'reset-zoom',
    );
    this.addDivider();
    this.addItem(
      'Align selection vertical',
      () => {
        window.canvas.alignSelectionVertical();
      },
      'align-selection-vertical',
    );
    this.addItem(
      'Align selection horizontal',
      () => {
        window.canvas.alignSelectionHorizontal();
      },
      'align-selection-horizontal',
    );
    this.addDivider();
    this.addItem(
      'Undo',
      () => {
        window.canvas.undo();
      },
      'undo',
    );
    this.addItem(
      'Redo',
      () => {
        window.canvas.redo();
      },
      'redo',
    );
  }

  // removeListeners
  removeListenersHandler(): void {
    this.target.removeEventListener('click', this.hideListener);
  }

  // show
  show(x: number, y: number): void {
    this.render();

    this.contextMenu.style.display = 'block';
    this.contextMenu.style.left = x + 'px';
    this.contextMenu.style.top = y + 'px';
  }

  // Return main div element
  contextElement(): HTMLElement {
    return this.contextMenu;
  }
}
