import draw2d from 'draw2d';

draw2d.command.CommandDelete = draw2d.command.Command.extend({
  NAME: 'draw2d.command.CommandDelete',

  init: function (figure) {
    this._super(draw2d.Configuration.i18n.command.deleteShape);

    this.parent = figure.getParent();
    this.figure = figure;
    this.canvas = figure.getCanvas();
    this.connections = null;
    this.removedParentEntry = null;
    this.indexOfChild = -1;
  },

  canExecute: function () {
    return this.figure.getCanvas() !== null;
  },

  execute: function () {
    this.redo();
  },

  undo: function () {
    if (this.parent !== null) {
      this.parent.add(
        this.removedParentEntry.figure,
        this.removedParentEntry.locator,
        this.indexOfChild,
      );
      this.canvas.setCurrentSelection(this.parent);
    } else {
      this.canvas.add(this.figure);
      this.canvas.setCurrentSelection(this.figure);
    }

    if (this.figure instanceof draw2d.Connection) {
      this.figure.reconnect();
    }

    for (let i = 0; i < this.connections.getSize(); ++i) {
      this.canvas.add(this.connections.get(i));
      this.connections.get(i).reconnect();
    }

    if (this.figure.userData && this.figure.userData.element) {
      this.figure.userData.element.redoFillColors();
    }
  },

  redo: function () {
    this.canvas.setCurrentSelection(null);

    if (this.connections === null) {
      if (this.figure instanceof draw2d.shape.node.Node) {
        this.connections = this.figure.getConnections();
      } else {
        this.connections = new draw2d.util.ArrayList();
      }
    }

    for (let i = 0; i < this.connections.getSize(); ++i) {
      this.canvas.remove(this.connections.get(i));
    }

    if (this.parent !== null) {
      this.indexOfChild = this.parent.getChildren().indexOf(this.figure);
      this.removedParentEntry = this.parent.remove(this.figure);
    } else {
      this.canvas.remove(this.figure);
    }
  },
});
