TR2.About = Ext.extend(Ext.Window, {
  id: 'about',
  title: 'SSC TR2 Tripod version 2.3.0',
  width: 350,
  height: 300,
  plain: true,
  modal: true,
  border: false,
  closeAction: 'hide',
  bodyPadding: 10,
  html: `
<span class="about-title">SSC TR2 Tripod v2.3</span>
<div class="img-block"><img style="width: 100%; margin-top: 10px;" src="${__BASE_URL}/assets/logo-tripod.png"></div>
  <table id="about-table">
<tbody>
<tr>
<td>Version:</td>
<td>2.3</td>
</tr>
<tr>
<td>Copyright ©</td>
<td>2020</td>
</tr>
<tr>
<td>Developed by:</td>
<td>Webmen</td>
</tr>
</tbody>
</table>`,
  initComponent: function () {
    TR2.About.superclass.initComponent.apply(this, arguments);
  },
});
Ext.define('tr2-about', TR2.About);
