import draw2d from 'draw2d';
import Element from '../elements/element';
import Canvas from '../../canvas';

draw2d.command.CommandInsert = draw2d.command.Command.extend({
  NAME: 'draw2d.command.CommandInsert',

  init: function (
    canvas: Canvas,
    connection: draw2d.connection,
    insertElement: Element,
    fromElement: Element,
    toElement: Element,
  ) {
    this._super('Insert element');

    this.canvas = canvas;
    this.insertElement = insertElement;
    this.connection = connection;
    this.fromElement = fromElement;
    this.toElement = toElement;
  },

  canExecute: function () {
    return this.insertElement.d2dElement.getCanvas() === null;
  },

  execute: function () {
    this.redo();
  },

  redo: function () {
    let override = 0;
    if (window.canvas.isBowtie) {
      if (this.fromElement._className === 'Event' && this.toElement._className === 'Target') {
        override = 1;
      }
    }

    this.canvas.d2dCanvas.add(this.insertElement.d2dElement);
    this.canvas.d2dCanvas.remove(this.connection);
    this.canvas.connectElements(this.fromElement, this.insertElement, override);
    this.canvas.connectElements(this.insertElement, this.toElement);
    this.canvas.selectElement(this.insertElement);
    this.insertElement.redoFillColors();
  },

  undo: function () {
    const connections = this.insertElement.d2dElement.getConnections();
    for (let i = 0; i < connections.getSize(); ++i) {
      this.canvas.d2dCanvas.remove(connections.get(i));
    }
    this.canvas.d2dCanvas.remove(this.insertElement.d2dElement);
    this.canvas.d2dCanvas.add(this.connection);
    this.connection.reconnect();
    this.canvas.selectElement(this.toElement);
  },
});
