TR2.Properties = {
  extend: 'Ext.FormPanel',
  layout: 'form',
  xtype: 'tr2-properties',
  items: [
    {
      region: 'north',
      baseCls: 'x-mobile',
      padding: '10px 10px 10px 10px',
      items: [
        {
          id: 'error',
          xtype: 'component',
          html: '',
        },
      ],
    },
    {
      region: 'west',
      baseCls: 'x-mobile',
      padding: '0 0 0 10px',
      layout: 'form',
      items: [
        {
          id: 'font-size',
          xtype: 'combobox',
          disabled: true,
          fieldLabel: 'Font size',
          width: 55,
          forceSelection: true,
          triggerAction: 'all',
          lazyRender: true,
          mode: 'local',
          value: 12,
          editable: false,
          displayField: 'label',
          valueField: 'value',
          store: new Ext.data.ArrayStore({
            fields: ['label', 'value'],
            data: [
              // When adding another font size be sure to update the fontSizeLineFactorMap in the TextBreaker
              ['8px', 8],
              ['9px', 9],
              ['10px', 10],
              ['12px', 12],
              ['14px', 14],
              ['16px', 16],
              ['18px', 18],
              ['24px', 24],
            ],
          }),
          listeners: {
            select: function (_field, rec): void {
              const s = rec.get('value');
              const element = window.canvas.getSingleSelectedElement();
              if (element) {
                element.style = {
                  fontSize: s,
                };

                // This forces a recalculation of the text content using the TextBreaker
                element.name = element.name;
              }
            },
          },
        },
      ],
    },
    {
      region: 'center',
      baseCls: 'x-plain',
      padding: '10px 10px 10px 10px',
      items: [
        {
          id: 'description',
          xtype: 'textarea',
          disabled: true,
          fieldLabel: 'Description',
          enableKeyEvents: true,
          width: '100%',
          grow: true,
        },
      ],
    },
    {
      region: 'south',
      baseCls: 'x-plain',
      padding: '0 0 20px 20px',
      margin: '10px 0 0 0',
      items: [
        {
          id: 'submit',
          disabled: true,
          xtype: 'button',
          text: 'Submit',
          formbind: true,
          listeners: {
            click: function (btn): void {
              const form = btn.up('form').getForm();
              Ext.getCmp('error').setHtml('');
              const elements = window.canvas.d2dCanvas.figures.data;
              const selectedElement = window.canvas.getSingleSelectedElement();
              elements.forEach(function (element) {
                if (selectedElement === element.userData.element) {
                  element.userData.element.description = form.getValues()[
                    'description-inputEl'
                  ];
                }
              });
            },
          },
        },
      ],
    },
  ],
};

Ext.define('tr2-properties', TR2.Properties);
