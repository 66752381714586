class TextBreaker {
  private fontSizeLineFactorMap = {
    8: 1.9,
    9: 1.85,
    10: 1.8,
    12: 1.7,
    14: 1.5,
    16: 1.55,
    18: 1.6,
    24: 1.65,
  };

  breakText(
    text: string,
    maxWidth: number,
    maxHeight: number,
    fontSize: number,
  ): string {
    const lineSize = this.getLineSize(maxWidth, fontSize);
    const maxLines = this.getMaxLines(maxHeight, fontSize);
    const paragraphs = text.split('\n ');
    const brokenParagraphs: Array<string> = [];
    let currentLineCount = 0;

    paragraphs
      .map((paragraph) => {
        return paragraph.replace('\n', '');
      })
      .forEach((paragraph) => {
        if (currentLineCount >= maxLines) {
          return;
        }

        const wordsArr = paragraph.split(' ').map((word) => {
          return word.replace('\n', '');
        });

        let lines = this.breakLines(wordsArr, lineSize);

        if (lines.length && currentLineCount + lines.length > maxLines) {
          lines = lines.slice(0, maxLines - currentLineCount);

          let lastLine = lines[lines.length - 1];

          if (lastLine) {
            lastLine = lastLine.substr(0, lastLine.length - 3) + '...';

            lines[lines.length - 1] = lastLine;
          }
        }

        // We add one here for the empty line between paragraphs
        currentLineCount += lines.length + 1;

        brokenParagraphs.push(lines.join('\n'));
      });

    return brokenParagraphs.join('\n \n');
  }

  breakLines(wordsArr: Array<string>, lineSize: number): Array<string> {
    const lines: Array<string> = [];
    let currentLine = '';

    wordsArr.forEach((word) => {
      if (
        currentLine.length <= lineSize &&
        currentLine.length + word.length + 1 <= lineSize
      ) {
        currentLine += word + ' ';
      } else {
        lines.push(currentLine);
        currentLine = word + ' ';
      }
    });

    lines.push(currentLine.trim());

    return lines;
  }

  getLineSize(maxWidth: number, fontSize: number): number {
    return Math.ceil(maxWidth / (fontSize / 2));
  }

  getMaxLines(maxHeight: number, fontSize: number): number {
    const factor = this.fontSizeLineFactorMap[fontSize] ? this.fontSizeLineFactorMap[fontSize] : 1.5;
    return Math.ceil(maxHeight / (fontSize * factor));
  }
}

export default new TextBreaker();
