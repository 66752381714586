import ApiService from '../api.service';
import { loadExplorer, showHideLoadingMask } from './cases.service';
import { errorAlert } from './global.service';
import JSZip from 'jszip';
import appMessages from '../../i18n/en/appMessages.json';
import { loadCases } from './case-selector.service';

export const currentProjects = Ext.create('Ext.data.TreeStore', {
  id: 'datastore',
  autoSync: true,
  autoLoad: false,
});

function checkType(type): boolean {
  if (type === 'tree') {
    errorAlert(
      appMessages.app_general_messages.error,
      appMessages.import.no_selection,
    );
    return false;
  }
  return true;
}

function validateCase(arr, checked): boolean {
  if (arr.length === 0) {
    errorAlert(
      appMessages.app_general_messages.error,
      appMessages.import.no_selection,
    );
    return false;
  } else if (checked.length === 2) {
    errorAlert(
      appMessages.app_general_messages.error,
      appMessages.import.wrong_selection,
    );
    return false;
  }
  return true;
}

let allFiles: any;

const uploadStore = Ext.create('Ext.data.Store', {
  storeId: 'uploadFiles',
  fields: ['name'],
  data: allFiles,
});

export function importPanelFile(file, loadingComponent): void {
  const zip = new JSZip();
  zip.loadAsync(file).then(function (zip) {
    allFiles = Object.values(zip.files);
    uploadStore.loadData(allFiles);
  });
  const uploadPanel = Ext.create('Ext.panel.Panel', {
    layout: 'border',
    title: 'File',
    id: 'allfiles',
    width: 500,
    height: 400,
    floating: true,
    centered: true,
    modal: true,
    items: [
      {
        region: 'west',
        title: 'Uploaded files',
        width: '50%',
        layout: 'fit',
        items: [
          {
            xtype: 'gridpanel',
            store: Ext.data.StoreManager.lookup('uploadFiles'),
            id: 'uploadedfiles',
            columns: [
              {
                text: 'Naam',
                dataIndex: 'name',
                sortable: true,
                hideable: false,
                flex: 1,
              },
            ],
          },
        ],
      },
      {
        region: 'east',
        title: 'Current projects',
        width: '50%',
        layout: 'fit',
        items: [
          {
            xtype: 'treepanel',
            useArrows: true,
            autoScroll: true,
            stateful: true,
            border: false,
            containerScroll: true,
            autoLoad: false,
            rootVisible: false,
            waitMsg: 'Loading...',
            id: 'currentprojects',
            store: currentProjects,
            listeners: {
              render: function (): void {
                loadCases(currentProjects);
              },
              select: function (): void {
                const t = (this as any).items.get(0);
                const checked = t.getChecked();
                const selectedIds: any = [];
                for (let i = 0; i < checked.length; i++) {
                  if (checked[i].data.type === 'case') {
                    selectedIds.push(checked[i].data.caseDataID);
                  }
                }
              },
            },
            columns: [
              {
                xtype: 'treecolumn',
                itemId: 'header',
                flex: 2,
                sortable: true,
                dataIndex: 'name',
                editable: true,
              },
            ],
          },
        ],
      },
    ],
    buttons: [
      {
        text: 'Save & Load',
        iconCls: 'x-fa fa-check',
        handler: function (): void {
          try {
            let type;
            const uploadedFiles = Ext.getCmp(
              'uploadedfiles',
            ).getSelectionModel().selected.items;
            const tx = Ext.getCmp('currentprojects').items.get(0);
            const checked = tx.getChecked();
            if (!validateCase(uploadedFiles, checked)) return;
            checked.forEach((item) => {
              type = item.data;
            });
            if (!checkType(type.type)) return;
            uploadedFiles.forEach((item) => {
              zip.files[item.data.name]
                .async('string')
                .then(function (_fileData) {
                  window.canvas.loadFromFile(_fileData);
                  ApiService.createTree(type.caseDataID, {
                    name: item.data.name,
                    xml: _fileData,
                  })
                    .then(() => {
                      Ext.getCmp('allfiles').destroy();
                      Ext.getCmp('importpanel').destroy();
                      showHideLoadingMask(loadingComponent);
                      loadExplorer();
                      loadCases(currentProjects);
                    })
                    .catch(() => {
                      errorAlert(
                        appMessages.app_general_messages.error,
                        appMessages.serverError,
                      );
                    });
                });
            });
          } catch (e) {
            errorAlert(
              appMessages.app_general_messages.error,
              appMessages.import.no_selection,
            );
          }
        },
      },
      {
        text: 'Cancel',
        iconCls: 'x-fa fa-times',
        handler: function (): void {
          allFiles = [];
          Ext.getCmp('allfiles').destroy();
        },
      },
    ],
  });
  uploadPanel.show();
}
