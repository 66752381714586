/* eslint-disable @typescript-eslint/explicit-function-return-type */
import draw2d from 'draw2d';
import Uuidv4 from '../utils/Uuidv4';
import { StyleInterface } from './StyleInterface';
import TextBreaker from '../utils/TextBreaker';
import SelectionMenuPolicy from '../ui/SelectionMenu';
import ContextMenu from '../ui/ContextMenu';
import { TextAreaInPlaceLabelEditor } from '../editors/textAreaInPlaceLabelEditor';
import { ResizeEventsSelectionFeedbackPolicy } from '../policies/resizeEventsSelectionFeedbackPolicy';

interface ElementAndConnection {
  element: Element;
  connection: draw2d.connection;
}

let i = 0;

const nameLabelExtraTextWidth = 20;
const labelGutter = 10;

export default class Element {
  elementContextMenu!: ContextMenu;
  readonly _d2dElement: any;
  private readonly _guid: string;
  private readonly _fillColors = {};
  private _name = '';
  public descriptionTooltip: draw2d.shape.note.PostIt;
  public exportIdTooltip: draw2d.shape.note.PostIt;
  public _nameLabel: draw2d.shape.basic.Label;
  public _labelRectangle: draw2d.geo.Rectangle;
  public _selectElement: draw2d.shape.basic.Rectangle;
  public _labelElement: draw2d.shape.basic.Label;
  static get _className(): string {
    return 'Element';
  }
  public _className = 'Element';
  public _description = '';
  private _style: StyleInterface = {
    fontSize: 14,
  };
  public sequence = ++i;

  constructor(x: number, y: number, guid = '', w = 160, h = 100) {
    this._guid = guid === '' ? Uuidv4.generate() : guid;

    this._d2dElement = new draw2d.SVGFigure({
      x,
      y,
      resizeable: true,
      minWidth: 160,
      minHeight: 100,
      keepAspectRatio: false,
    });

    this._d2dElement.resetPorts();
    this._d2dElement.setUserData({
      element: this,
      isElement: true,
    });

    this._d2dElement.setCssClass('element-' + this._className);

    const moveSubtreeDelegate = (_element, event): void => {
      this.moveSubtree(event.dx, event.dy, []);
    };
    this._d2dElement.on('mousedown', () => {
      this._d2dElement.toFront();
    });

    this._d2dElement.on('dragstart', () => {
      if (window.canvas.dragSubtree && window.canvas.selectionLength === 1) {
        this._d2dElement.on('move', moveSubtreeDelegate);
      }
      window.canvas.setDirty();
    });
    this._d2dElement.on('dragend', () => {
      this._d2dElement.off(moveSubtreeDelegate);
    });

    this.elementContextMenu = new ContextMenu(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById('display')! as HTMLElement,
    );

    const resizePolicy = new ResizeEventsSelectionFeedbackPolicy();

    resizePolicy.onResizeStart(() => {
      if (this._selectElement) {
        this._selectElement.setVisible(false);
      }
      if (this._labelElement) {
        this._labelElement.setVisible(false);
      }
      this._nameLabel.setVisible(false);
      this._labelRectangle.setVisible(false);

      window.canvas.isResizing = true;
    });

    resizePolicy.onResizeEnd(() => {
      setTimeout(() => {
        if (this._selectElement) {
          this._selectElement.setVisible(true);
        }
        if (this._labelElement) {
          this._labelElement.setVisible(true);
        }
        this._nameLabel.setVisible(true);
        this._labelRectangle.setVisible(true);

        window.canvas.isResizing = false;
      }, 150);
    });

    this._d2dElement.installEditPolicy(new SelectionMenuPolicy());
    this._d2dElement.installEditPolicy(resizePolicy);

    this._d2dElement.on('resize', () => {
      if (!this._nameLabel || !this._labelRectangle) {
        return;
      }

      const nameLabelWidth = this.widthWithGutter - this.width * 0.05;
      const nameLabelHeight = this.heightWithGutter - this.height * 0.05;

      this._nameLabel.setWidth(nameLabelWidth);
      this._nameLabel.userData.width = nameLabelWidth;
      this._nameLabel.setHeight(nameLabelHeight);
      this._labelRectangle.setWidth(this.widthWithGutter);
      this._labelRectangle.userData.width = this.widthWithGutter;
      this._labelRectangle.setHeight(this.heightWithGutter);

      this._labelRectangle.setRadius(this.radius);
      this._nameLabel.setRadius(this.radius);

      // This causes the TextBreaker to recalculate the text it can display
      setTimeout(() => {
        this.name = this._name;
      }, 0);
    });

    setTimeout(() => {
      this._d2dElement.setWidth(w);
      this._d2dElement.setHeight(h);
      this._d2dElement.fireEvent('resize');
    }, 0);
  }

  isRightElement(): boolean {
    let event = window.canvas.getFirstEvent();
    const selectedElement = window.canvas.getSingleSelectedElement();
    if (selectedElement && selectedElement._className === 'Event') {
      event = selectedElement;
    }
    if (!event) {
      return false;
    }
    return event.x < this.x;
  }

  convertTo(type: string, keepElementData: boolean): void {
    const command = new draw2d.command.CommandConvert(
      window.canvas,
      type,
      this,
    );

    if (!keepElementData) {
      command.newElement.name = '';
      command.newElement.description = '';
    } else {
      command.newElement.name = command.element.name;
      command.newElement.description = command.element.description;
    }

    window.canvas.d2dCanvas.getCommandStack().execute(command);
  }

  setFillColor(color: string, nodeIndex = 0): void {
    setTimeout(() => {
      this.d2dElement.getTopLevelShapeElement().items[
        nodeIndex
      ].node.style.fill = color;
    }, 50);
    this._fillColors[nodeIndex] = color;
  }

  redoFillColors(): void {
    for (const nodeIndex in this._fillColors) {
      this.setFillColor(this._fillColors[nodeIndex], parseInt(nodeIndex));
    }
  }

  addExportTooltip() {
    this.exportIdTooltip = new draw2d.shape.note.PostIt({
      text: '',
      cssClass: 'tooltip',
      id: 'tooltip',
      element: this,
      userData: {
        width: '100%',
      },
    });
  }

  updateExportIdTooltip() {
    const exportId = this.exportId === '' ? '' : this.exportId;

    if (this.exportIdTooltip) {
      this.d2dElement.remove(this.exportIdTooltip);
    }

    this.exportIdTooltip = new draw2d.shape.note.PostIt({
      text: exportId,
      cssClass: 'tooltip',
      id: 'tooltip',
      userData: {
        width: '100%',
      },
    });
  }

  showExportTooltip() {
    this.updateExportIdTooltip();
    this.exportIdTooltip.setUserData({
      element: this,
    });
    const yLocation = 0;
    const xLocation = 71.5;
    const locator = new draw2d.layout.locator.XYRelPortLocator(
      yLocation,
      xLocation,
    );
    this.d2dElement.add(this.exportIdTooltip, locator);
  }

  addDescriptionField() {
    this.descriptionTooltip = new draw2d.shape.note.PostIt({
      text: '',
      cssClass: 'tooltip',
      id: 'tooltip',
      element: this,
      userData: {
        width: 140,
      },
    });
  }

  disableLabelBackground() {
    if (!this._labelRectangle) {
      return;
    }

    this._labelRectangle.setBackgroundColor(null);
  }

  enableLabelBackground() {
    if (!this._labelRectangle) {
      return;
    }

    this._labelRectangle.setBackgroundColor('#FFFFFF');
  }

  addNameField(
    width = 140,
    height = 80,
    x = 10,
    y = 10,
    radius = 10,
    asPercentage = false,
  ): void {
    if (this._labelRectangle) {
      this.d2dElement.remove(this._labelRectangle);
    }

    const labelRectangle = new draw2d.shape.basic.Rectangle({
      bgColor: '#FFFFFF',
      alpha: 0.9,
      width: width,
      height: height,
      radius: radius,
      stroke: 0,
      resizeable: true,
    });

    labelRectangle.setUserData({
      element: this,
    });

    let locator;
    if (asPercentage) {
      locator = new draw2d.layout.locator.XYRelPortLocator(x, y);
    } else {
      locator = new draw2d.layout.locator.XYAbsPortLocator(x, y);
    }

    this.d2dElement.add(labelRectangle, locator);

    this._labelRectangle = labelRectangle;
    this._nameLabel = new draw2d.shape.basic.Label({
      text: '',
      stroke: 0,
      fontColor: '#0d0d0d',
      userData: {
        width: width + nameLabelExtraTextWidth,
        element: this,
      },
      fontSize: this.style.fontSize,
      resizeable: true,
    });
    labelRectangle.add(
      this._nameLabel,
      new draw2d.layout.locator.XYRelPortLocator(2.5, 2.5),
    );

    this._nameLabel.installEditor(
      new TextAreaInPlaceLabelEditor({
        originalValue: () => {
          return this.name;
        },
        onResize: (newLabelWidth, newLabelHeight) => {
          const currentLabelWidth = this._nameLabel.getWidth();
          const currentLabelHeight = this._nameLabel.getHeight();

          const widthRatio = currentLabelWidth / newLabelWidth;
          const heightRatio = currentLabelHeight / newLabelHeight;

          this._d2dElement.setWidth(this._d2dElement.getWidth() / widthRatio);
          this._d2dElement.setHeight(
            this._d2dElement.getHeight() / heightRatio,
          );
        },
        onCommit: (value) => {
          this.name = value;
        },
      }),
    );
  }

  parentChainHasClass(className: string): boolean {
    const parentsHaveClass = (
      elementAndConnections: ElementAndConnection[],
    ) => {
      return elementAndConnections.some((elAndCon) => {
        return elAndCon.element._className === className;
      });
    };

    if (parentsHaveClass(this.parents)) {
      return true;
    }

    return this.parents.some((parent) => {
      return parent.element.parentChainHasClass(className);
    });
  }

  moveSubtree(xDelta: number, yDelta: number, movedElements: string[]): void {
    this._d2dElement.getOutputPorts().each((_index, d2dOutputPort) => {
      d2dOutputPort.getConnections().each((_index, d2dConnection) => {
        const element: Element = d2dConnection.getTarget().getParent().userData
          .element;

        if (!movedElements.includes(element.guid)) {
          element.x = element.x + xDelta;
          element.y = element.y + yDelta;
          movedElements.push(element.guid);
          element.moveSubtree(xDelta, yDelta, movedElements);
        }
      });
    });
  }

  get numberOfChildren(): number {
    const outputPort = this.d2dElement.getOutputPort(0);
    if (outputPort) {
      return outputPort.getConnections().asArray().length;
    }
    return 0;
  }

  get numberOfParents(): number {
    const inputPort = this.d2dElement.getInputPort(0);
    if (inputPort) {
      return inputPort.getConnections().asArray().length;
    }
    return 0;
  }

  get parents(): ElementAndConnection[] {
    const out: ElementAndConnection[] = [];
    const inputPort = this.d2dElement.getInputPort(0);
    if (inputPort) {
      for (const connection of inputPort.getConnections().asArray()) {
        out.push({
          connection,
          element: connection.sourcePort.parent.userData.element,
        } as ElementAndConnection);
      }
    }
    return out;
  }

  get name(): string {
    if (this._name) {
      return this._name;
    }

    return this._nameLabel ? this._nameLabel.getText() : '';
  }

  set name(value: string) {
    this._name = value;

    if (this._nameLabel) {
      this._nameLabel.setText(
        TextBreaker.breakText(
          this._name,
          this._labelRectangle.getWidth(),
          this._labelRectangle.getHeight(),
          this._nameLabel.getFontSize(),
        ),
      );
    }
  }

  get description(): string {
    return this.descriptionTooltip
      ? this.descriptionTooltip.getText().replace(/(\r\n|\n|\r)/gm, '')
      : '';
  }

  get exportId(): string {
    return this.exportIdTooltip
      ? this.exportIdTooltip.getText().replace(/(\r\n|\n|\r)/gm, '')
      : '';
  }

  set exportId(value) {
    if (this.exportIdTooltip) {
      this.exportIdTooltip.setText(
        TextBreaker.breakText(
          value,
          this.exportIdTooltip.userData.width,
          this.exportIdTooltip.userData.height,
          this.exportIdTooltip.getFontSize(),
        ),
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set description(value) {
    if (this.descriptionTooltip) {
      this.descriptionTooltip.setText(
        TextBreaker.breakText(
          value,
          this.descriptionTooltip.userData.width,
          this.descriptionTooltip.userData.height,
          this.descriptionTooltip.getFontSize(),
        ),
      );
    }
  }

  get style(): StyleInterface {
    return this._style;
  }

  set style(value: StyleInterface) {
    this._style = value;
    if (this._nameLabel) {
      this._nameLabel.setFontSize(value.fontSize);
    }
  }

  get guid(): string {
    return this._guid;
  }

  get d2dElement(): any {
    return this._d2dElement;
  }

  get x(): number {
    return this._d2dElement.getX();
  }

  set x(x: number) {
    this._d2dElement.setX(x);
  }

  get y(): number {
    return this._d2dElement.getY();
  }

  set y(y: number) {
    this._d2dElement.setY(y);
  }

  get radius(): number {
    return 10 * (this.width / 160);
  }

  get width(): number {
    return this._d2dElement.getWidth();
  }

  set width(value) {
    this._d2dElement.setWidth(value);
  }

  get widthWithGutter(): number {
    return this.width - labelGutter * 2;
  }

  get height(): number {
    return this._d2dElement.getHeight();
  }

  set height(value) {
    this._d2dElement.setHeight(value);
  }

  get heightWithGutter(): number {
    return this.height - labelGutter * 2;
  }
}
