import Element from '../elements/element';

export default class ConnectionConstraints {
  private readonly forbiddenPairs: Array<Array<string>> = [
    ['ActiveFailure', 'ActiveFailure'],
    ['ActiveFailure', 'EffectiveBarrier'],
    ['ActiveFailure', 'Event'],
    ['ActiveFailure', 'EventHazard'],
    ['ActiveFailure', 'EventTarget'],
    ['ActiveFailure', 'Hazard'],
    ['ActiveFailure', 'LatentFailure'],
    ['ActiveFailure', 'MissingBarrier'],
    ['ActiveFailure', 'Target'],
    ['EffectiveBarrier', 'LatentFailure'],
    ['EffectiveBarrier', 'Precondition'],
    ['Event', 'Event'],
    ['Event', 'LatentFailure'],
    ['Event', 'Precondition'],
    ['EventHazard', 'LatentFailure'],
    ['EventHazard', 'Precondition'],
    ['EventTarget', 'LatentFailure'],
    ['EventTarget', 'Precondition'],
    ['FailedBarrier', 'LatentFailure'],
    ['FailedBarrier', 'Precondition'],
    ['Hazard', 'Hazard'],
    ['Hazard', 'LatentFailure'],
    ['Hazard', 'Precondition'],
    ['Hazard', 'Target'],
    ['InadequateBarrier', 'LatentFailure'],
    ['InadequateBarrier', 'Precondition'],
    ['LatentFailure', 'LatentFailure'],
    ['LatentFailure', 'Target'],
    ['MissingBarrier', 'LatentFailure'],
    ['MissingBarrier', 'Precondition'],
    ['Precondition', 'Precondition'],
    ['Precondition', 'Target'],
    ['Target', 'Target'],
  ];

  isAllowed(a: Element, b: Element): boolean {
    return !this.forbiddenPairs.some((pair) => {
      return (
        (pair[0] === a._className && pair[1] === b._className) ||
        (pair[0] === b._className && pair[1] === a._className)
      );
    });
  }
}
