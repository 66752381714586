import draw2d from 'draw2d';
import { debounce } from 'lodash-es';

draw2d.ui.TextAreaInPlaceLabelEditor = draw2d.ui.LabelInplaceEditor.extend({
  NAME: 'draw2d.ui.TextAreaInPlaceLabelEditor',

  /**
   * @constructor
   * @private
   */
  init: function (listener) {
    this._super(listener);
  },

  /**
   * @method
   * Trigger the edit of the label text.
   *
   * @param {draw2d.shape.basic.Label} label the label to edit
   */
  start: function (label) {
    this.label = label;

    this.commitCallback = $.proxy(this.commit, this);

    // commit the editor if the user clicks anywhere in the document
    //
    $('body').bind('click', this.commitCallback);

    // append the input field to the document and register
    // the ENTER and ESC key to commit /cancel the operation
    //
    this.html = $('<textarea id="textarea-inplaceeditor"></textarea>');
    this.html.val(this.listener.originalValue());
    this.html.hide();

    $('body').append(this.html);

    this.html.autoResize();

    this.html.bind(
      'keydown',
      $.proxy(function (e) {
        switch (e.which) {
          case 13:
            if (!this._shiftDown) {
              e.preventDefault();
              e.stopPropagation();
              this.commit();
            }
            break;
          case 16:
            this._shiftDown = true;
            break;
        }
      }, this),
    );

    this.html.bind(
      'keyup',
      $.proxy(function (e) {
        switch (e.which) {
          case 16:
            this._shiftDown = false;
            break;
          case 13:
            break;
          case 27:
            this.cancel();
            break;
        }
      }, this),
    );

    this.html.bind('blur', this.commitCallback);

    // avoid commit of the operation if we click inside the editor
    //
    this.html.bind('click', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });

    // Position the INPUT and init the autoresize of the element
    //
    const canvas = this.label.getCanvas();
    const bb = this.label.getBoundingBox();

    bb.setPosition(canvas.fromCanvasToDocumentCoordinate(bb.x, bb.y));

    // remove the scroll from the body if we add the canvas directly into the body
    const scrollDiv = canvas.getScrollArea();
    if (scrollDiv.is($('body'))) {
      bb.translate(canvas.getScrollLeft(), canvas.getScrollTop());
    }

    bb.translate(-1, -1);
    bb.resize(2, 2);

    let firstRender = true;

    // Watch for changes to the size of the textarea to properly
    // change the size of the underlying figure
    this.resizeObserver = new (window as any).ResizeObserver(
      debounce((entries) => {
        if (firstRender) {
          firstRender = false;
          return;
        }
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            // Firefox implements `contentBoxSize` as a single content rect, rather than an array
            const contentBoxSize = Array.isArray(entry.contentBoxSize)
              ? entry.contentBoxSize[0]
              : entry.contentBoxSize;

            const width = Math.round(contentBoxSize.inlineSize * canvas.getZoom() * 10) / 10;
            const height = Math.round(contentBoxSize.blockSize * canvas.getZoom() * 10) / 10;

            this.listener.onResize(width + 4, height + 4);
          }
        }
      }, 100),
    );

    this.html.css({
      position: 'absolute',
      'font-size': this.label.fontSize * (1 / canvas.getZoom()) + 'px',
      'font-family':
        '"Myriad Pro", "Myriad Web", "Tahoma", "Helvetica", "Arial", sans-serif',
      top: bb.y,
      left: bb.x,
      'min-width': bb.w * (1 / canvas.getZoom()),
      width: this.label.getWidth() * (1 / canvas.getZoom()),
      'min-height': Math.max(25, bb.h * (1 / canvas.getZoom())),
      height: Math.max(25, this.label.getHeight() * (1 / canvas.getZoom())),
    });

    this.html.fadeIn(
      $.proxy(() => {
        this.html.focus();
        setTimeout(() => {
          this.resizeObserver.observe(this.html[0], { box: 'border-box' });
        });
      }, this),
    );
  },

  /**
   * @method
   * Transfer the data from the editor into the label.<br>
   * Remove the editor.<br>
   *
   * @private
   */
  commit: function () {
    if (!this.html) {
      this.html = $('#textarea-inplaceeditor');
    }

    const value = this.html.val();

    this.resizeObserver.unobserve(this.html[0]);
    this.html.off('blur', this.commitCallback);
    $('body').off('click', this.commitCallback);

    this.html.fadeOut(
      $.proxy(function () {
        this.html.remove();
        this.html = null;
        this.listener.onCommit(value);
      }, this),
    );
  },

  /**
   * @method
   * Transfer the data from the editor into the label.<br>
   * Remove the editor.<br>
   * @private
   */
  cancel: function () {
    this.html.off('blur', this.commitCallback);
    this.resizeObserver.unobserve(this.html[0]);
    $('body').off('click', this.commitCallback);
    this.html.fadeOut(
      $.proxy(function () {
        this.html.remove();
        this.html = null;
        this.listener.onCancel();
      }, this),
    );
  },
});

export const TextAreaInPlaceLabelEditor = draw2d.ui.TextAreaInPlaceLabelEditor;
