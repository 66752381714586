/* eslint-disable @typescript-eslint/ban-ts-ignore */
import draw2d from 'draw2d';
import DeleteIcon from './DeleteIcon';

const SelectionMenuPolicy = draw2d.policy.figure.AntSelectionFeedbackPolicy.extend(
  {
    NAME: 'SelectionMenuPolicy',

    init: function (attr, setter, getter) {
      this.overlay = DeleteIcon; // div DOM node
      this._super(attr, setter, getter);
    },

    onSelect: function (canvas, figure, isPrimarySelection) {
      this._super(canvas, figure, isPrimarySelection);

      // this.overlay.deleteIcon.show();
      this.overlay.deleteIcon.off('click');
      const elementsSelected =
        window.canvas.getAllSelectedElements().length + 1;
      $('#result').text(elementsSelected.toString());
      this.overlay.deleteIcon.on('click', function () {
        window.canvas.getAllSelectedElements().forEach(function (element) {
          canvas
            .getCommandStack()
            .execute(new draw2d.command.CommandDelete(element.d2dElement));
        });
      });
      this.posOverlay(figure);
    },

    onUnselect: function (canvas, figure) {
      this._super(canvas, figure);
      this.overlay.deleteIcon.hide();
    },

    onDrag: function (canvas, figure) {
      this._super(canvas, figure);
      this.posOverlay(figure);
      this.overlay.deleteIcon.css({
        top: figure.getAbsoluteY() + 50,
        left: figure.getAbsoluteX() + figure.getWidth() + 200,
      });
    },

    posOverlay: function (figure) {
      this.overlay.deleteIcon.css({
        top: figure.getAbsoluteY() + 50,
        left: figure.getAbsoluteX() + figure.getWidth() + 200,
      });
    },
  },
);

export default SelectionMenuPolicy;
