import draw2d from 'draw2d';

import BlockElement from './blockElement';
import ContextMenu from '../ui/ContextMenu';
import { addElement } from '../services/app/actions.service';

export default class Event extends BlockElement {
  static get _className(): string {
    return 'Event';
  }
  public _className = 'Event';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(x, y, guid, w, h);
    this.d2dElement.addPort(
      new draw2d.OutputPort(),
      new draw2d.layout.locator.LeftLocator(),
    );
    if (window.canvas.isBowtie) {
      this.d2dElement.addPort(
        new draw2d.OutputPort(),
        new draw2d.layout.locator.RightLocator(),
      );
    }
    this.d2dElement.id = 'event';

    this.setFillColor('#D33931');
  }

  showContextMenu(x: number, y: number) {
    const canvasElement = document.getElementById(
      'scrollcontainer',
    )! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);
    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    if (window.canvas.isBowtie) {
      this.elementContextMenu.titleFactory(
        contextMenuTitle.element,
        contextMenuTitle.id,
      );
      this.elementContextMenu.addElementFactory('target');
      this.elementContextMenu.addElementFactory('hazard');
      this.elementContextMenu.defaultElementFactory(this);
      this.elementContextMenu.show(x, y);
      return;
    }
    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.addElementFactory('event');
    this.elementContextMenu.addElementFactory('target');
    this.elementContextMenu.addItem(
      'Add hazard',
      () => {
        const selectedElement = window.canvas.getSingleSelectedElement();
        addElement('hazard', selectedElement, true);
      },
      'hazard',
    );
    this.elementContextMenu.convertElement(this, 'event hazard');
    this.elementContextMenu.convertElement(this, 'event target');
    this.elementContextMenu.addDivider();
    this.elementContextMenu.addElementFactory('comment');
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
