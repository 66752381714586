import TokenService from './services/token.service';
import {
  currentProjects,
  importPanelFile,
} from './services/app/import.service';
import { loadCases } from './services/app/case-selector.service';

if (TokenService.hasToken()) {
  loadCases(currentProjects);
}

TR2.Import = Ext.extend(Ext.Window, {
  initComponent: function () {
    Ext.apply(this, {
      id: 'importpanel',
      title: 'Import case',
      modal: true,
      closeAction: 'hide',
      layout: 'form',
      plain: true,
      border: false,
      frame: false,
      items: [
        {
          xtype: 'form',
          reference: 'form',
          autoEl: 'form',
          id: 'uploadform',
          baseCls: 'x-plain',
          fileUpload: true,
          padding: '5px',
          items: [
            {
              xtype: 'filefield',
              id: 'form-file',
              emptyText: 'Select an file',
              fieldLabel: 'Upload file to a project',
              name: 'import',
              allowBlank: false,
              buttonText: 'Browse',
            },
          ],
        },
      ],
      buttons: [
        {
          text: 'Ok',
          handler: function (): void {
            const file = Ext.getCmp('uploadform')
              .up()
              .down('filefield')
              .el.down('input[type=file]').dom.files[0];
            const caseComp = Ext.getCmp('cases');
            importPanelFile(file, caseComp);
          },
          scope: this,
        },
        {
          text: 'Cancel',
          handler: function (): void {
            (this as any).hide();
          },
          scope: this,
        },
      ],
    });
    TR2.Import.superclass.initComponent.apply(this, arguments);
  },
});

Ext.define('tr2-import', TR2.Import);
