/* eslint-disable @typescript-eslint/triple-slash-reference */
/// <reference path="./types/jsPDF.d.ts"/>
/// <reference path="./types/draw2d.d.ts"/>
import draw2d from 'draw2d';
import { meanBy, debounce } from 'lodash-es';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Element from './app/elements/element';
import connectionLayout from './app/layout/connectionLayout';
import CanvasLoader from './app/loaders/CanvasLoader';
import XMLExporter from './app/exporters/XMLExporter';
import ContextMenu from './app/ui/ContextMenu';
import { TreeDataInterface } from './app/loaders/TreeDataInterface';
import { DropInterceptorPolicy } from './app/ui/DropInterceptorPolicy';
import { DragConnectionCreatePolicy } from './app/utils/DragConnectionCreatePolicy';
import { CoronaDecorationPolicy } from './app/utils/CoronaDecorationPolicy';
import ApiService from './app/services/api.service';
import XMLLoader from './app/loaders/XMLLoader';
import { errorAlert } from './app/services/app/global.service';
import appMessages from './app/i18n/en/appMessages.json';
import {
  hideLoadingMask,
  showLoadingMask,
} from './app/services/app/cases.service';
import ElementDescription from './app/utils/ElementDescription';
import OutputPort = draw2d.OutputPort;
import { elementTypes } from './app/utils/ElementFactory';

class Canvas {
  private _isDirty = false;
  private _treeData: TreeDataInterface | null = null;
  private width = 0;
  private height = 0;

  public isResizing: boolean = true;

  _isBowtie = false;
  d2dCanvas: draw2d.Canvas;
  dragSubtree = false;
  contextMenu: ContextMenu;
  wrapper: HTMLCanvasElement;
  element: any;
  appLayout!: JQuery<HTMLDivElement>;
  currentProjectId: any;
  currentTreeId: any;

  constructor(width: number, height: number) {
    const canvasElement = document.getElementById(
      'display',
    ) as HTMLCanvasElement;
    canvasElement.style.width = width + 'px';
    canvasElement.style.height = height + 'px';
    this.wrapper = canvasElement;
    this.width = width;
    this.height = height;

    const scrollContainerElement = document.getElementById(
      'scrollcontainer',
    ) as HTMLElement;
    this.contextMenu = new ContextMenu(scrollContainerElement);

    const CustomCanvas = draw2d.Canvas.extend({
      init: function () {
        this._super('display', width, height);
      },

      fromDocumentToCanvasCoordinate: function (x, y): draw2d.geo.Point {
        return new draw2d.geo.Point(
          (x - this.getAbsoluteX() + window.scrollX) * this.zoomFactor,
          (y - this.getAbsoluteY() + window.scrollY) * this.zoomFactor,
        );
      },

      fromCanvasToDocumentCoordinate: function (x, y): draw2d.geo.Point {
        return new draw2d.geo.Point(
          x * (1 / this.zoomFactor) + this.getAbsoluteX() + window.scrollX,
          y * (1 / this.zoomFactor) + this.getAbsoluteY() + window.scrollY,
        );
      },
    });
    this.d2dCanvas = new CustomCanvas();
    this.d2dCanvas.html
      .find('> svg')
      .css('pointer-events', 'none')
      .css('background', '#FFFFFF')
      .css('border', '1px solid #CCCCCC');

    this.d2dCanvas.installEditPolicy(
      new DragConnectionCreatePolicy({
        createConnection: connectionLayout,
      }),
    );

    this.d2dCanvas.uninstallEditPolicy(
      new draw2d.policy.canvas.CoronaDecorationPolicy(),
    );
    this.d2dCanvas.uninstallEditPolicy(
      new draw2d.policy.canvas.DropInterceptorPolicy(),
    );
    this.d2dCanvas.installEditPolicy(new DropInterceptorPolicy());
    this.d2dCanvas.installEditPolicy(new CoronaDecorationPolicy());
    // this.d2dCanvas.installEditPolicy(new draw2d.policy.canvas.PanningSelectionPolicy);

    const onSelectionChange = debounce(() => {
      const allSelected = this.getAllSelectedElements();
      if (allSelected.length > 1) {
        window.onMultipleElementsSelected();
      } else if (allSelected.length === 1) {
        window.onOneElementSelected();
      } else {
        window.onNoElementsSelected();
      }
    });
    this.d2dCanvas.setScrollArea('#scrollcontainer');
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.appLayout = $('#scrollcontainer').layout({
      center: {
        resizable: false,
        closable: false,
        paneSelector: '#display',
      },
    });

    this.d2dCanvas.on('select', onSelectionChange);
    this.d2dCanvas.on('unselect', onSelectionChange);
    canvasElement.addEventListener(
      'contextmenu',
      this.showContextMenu.bind(this),
    );

    this.disable();
  }

  enable(): void {
    this.wrapper.style.display = 'block';
  }

  disable(): void {
    this.wrapper.style.display = 'none';
  }

  add(element: Element, addCommand = false, select = false): void {
    if (addCommand) {
      const command = new draw2d.command.CommandAdd(
        this.d2dCanvas,
        element.d2dElement,
        element.x,
        element.y,
      );
      this.d2dCanvas.getCommandStack().execute(command);
    } else {
      this.d2dCanvas.add(element.d2dElement);
    }

    // We add it to the canvas first because otherwise the element width/height are calculated incorrectly,
    // after that we perform the bounds checks to make sure it's actually trying to add this in bounds, if not
    // we correct it to be properly in bounds.
    const boundingBox = this.getBoundingBox();

    if (element.x < boundingBox.getLeft()) {
      element.x = boundingBox.getLeft();
    }

    if (element.y < boundingBox.getTop()) {
      element.y = boundingBox.getTop();
    }

    if (element.x > boundingBox.getWidth()) {
      element.x = boundingBox.getWidth() - element.width;
    }

    if (element.y > boundingBox.getHeight()) {
      element.y = boundingBox.getHeight() - element.height;
    }

    if (select) {
      this.selectElement(element);
    }
    this.setDirty();
  }

  setDragSubtree(value: boolean): void {
    Ext.getCmp('toolbar-drag-subtree').toggle(value);
    this.dragSubtree = value;
    this.contextMenu.setItemActiveState('drag-subtree', this.dragSubtree);
  }

  getByGuid(guid: string): Element | null {
    for (const element of this.getAllElements()) {
      if (element.guid === guid) {
        return element;
      }
    }
    return null;
  }

  getBoundingBox(): draw2d.geo.Rectangle {
    return new draw2d.geo.Rectangle(0, 0, this.width, this.height);
  }

  getAllElementsBoundingBox(): draw2d.geo.Rectangle {
    const allLeftXPositions: number[] = this.getAllElements().map(
      (element) => element.x,
    );
    const allTopYPositions: number[] = this.getAllElements().map(
      (element) => element.y,
    );
    const allRightXPositions: number[] = this.getAllElements().map(
      (element) => element.x + element.width,
    );
    const allBottomYPositions: number[] = this.getAllElements().map(
      (element) => element.y + element.height,
    );
    return new draw2d.geo.Rectangle(
      Math.min(...allLeftXPositions),
      Math.min(...allTopYPositions),
      Math.max(...allRightXPositions) - Math.min(...allLeftXPositions),
      Math.max(...allBottomYPositions) - Math.min(...allTopYPositions),
    );
  }

  getFirstEvent(): Element | undefined {
    return this.getAllElements().find(
      (element) => element._className === 'Event',
    );
  }

  getAllElements(): Element[] {
    const elements: Element[] = [];
    this.d2dCanvas.getFigures().each((_index, d2dElement) => {
      if (!d2dElement.userData || !d2dElement.userData.isElement) {
        return;
      }
      d2dElement.id = d2dElement;
      elements.push(d2dElement.userData['element']);
    });
    return elements;
  }

  getElementsOfType(type: string): Element[] {
    const elementClass = elementTypes[type];

    if (!elementClass) {
      throw new Error('Illegal type supplied');
    }

    return this.getAllElements().filter((element: Element) => {
      return element instanceof elementClass;
    });
  }

  getLowestPositionedElementOfType(type: string): Element | null {
    const elements = this.getElementsOfType(type);

    if (!elements.length) {
      return null;
    }

    let lowestPositionedElement = elements[0];

    for (let i = 1, l = elements.length; i < l; i++) {
      const currentElement = elements[i];

      if (currentElement.y > lowestPositionedElement.y) {
        lowestPositionedElement = currentElement;
      }
    }

    return lowestPositionedElement;
  }

  getAllConnections(): draw2d.connection[] {
    let connections: draw2d.connection[] = [];
    for (const element of this.getAllElements()) {
      const outputPorts = element.d2dElement.getOutputPorts();
      outputPorts.asArray().forEach((output: OutputPort) => {
        connections = [...connections, ...output.getConnections().asArray()];
      });
    }
    return connections;
  }

  getSingleSelectedElement(): Element | null {
    const allElements = this.getAllSelectedElements();
    return allElements.length ? allElements[0] : null;
  }

  getAllSelectedElements(): Element[] {
    const elements: Element[] = [];
    this.d2dCanvas.getSelection().each((_index, d2dElement) => {
      if (!d2dElement.userData || !d2dElement.userData.isElement) {
        return;
      }
      elements.push(d2dElement.userData['element']);
    });
    return elements;
  }

  get treeData(): TreeDataInterface | null {
    return this._treeData;
  }

  set treeData(value: TreeDataInterface | null) {
    this._treeData = value;
  }

  set isBowtie(bool: boolean) {
    this._isBowtie = bool;
  }

  get isBowtie(): boolean {
    if (!this._treeData) {
      return false;
    }
    return this._treeData.bowtie === 1;
  }

  get selectionLength(): number {
    return this.getAllSelectedElements().length;
  }

  get centerX(): number {
    const displaySettings = Ext.getCmp('tr2-content');
    const displaySettingsWidth = displaySettings.width;

    return displaySettingsWidth / 2;
  }

  get centerY(): number {
    const displaySettings = Ext.getCmp('tr2-content');
    const displaySettingsHeight = displaySettings.height;

    return displaySettingsHeight / 2;
  }

  getZoom(): number {
    return 1 / this.d2dCanvas.getZoom();
  }

  getViewportBoundingBox(): draw2d.geo.Rectangle {
    return new draw2d.geo.Rectangle(
      this.d2dCanvas.getScrollLeft(),
      this.d2dCanvas.getScrollTop(),
      this.wrapper.parentElement!.clientWidth,
      this.wrapper.parentElement!.clientHeight,
    );
  }

  get viewportCenterX(): number {
    const boundingbox = this.getViewportBoundingBox();

    return boundingbox.getCenter().getX();
  }

  get viewportCenterY(): number {
    const boundingbox = this.getViewportBoundingBox();

    return boundingbox.getCenter().getY();
  }

  setZoom(zoom: number): void {
    const addedToZoom = this.getZoom() < zoom;
    const viewport = this.getViewportBoundingBox();

    // Get the old center point of the element bounding box
    const elementCenterPoint = this.getAllElementsBoundingBox().getCenter();
    const oldTranslated = this.d2dCanvas.fromCanvasToDocumentCoordinate(
      elementCenterPoint.getX(),
      elementCenterPoint.getY(),
    );

    this.d2dCanvas.setZoom(1 / zoom, false);
    Ext.getCmp('zoom').setValue(Math.round(zoom * 100) + '%');

    // Get the new center point of the element bounding box (after the zoom!)
    const newElementCenterPoint = this.getAllElementsBoundingBox().getCenter();

    // Exception clause for when there are no elements on the canvas
    if (
      Number.isNaN(newElementCenterPoint.getX()) ||
      Number.isNaN(newElementCenterPoint.getY())
    ) {
      const display = document.getElementById('display')! as HTMLElement;

      display.style.width = 2880 + 'px';
      display.style.height = 2880 + 'px';

      this.d2dCanvas.setDimension(2880, 2880);

      const left = this.centerX - viewport.getWidth() / 2;
      const top = this.centerY - viewport.getHeight() / 2;

      return this.d2dCanvas.scrollTo(top, left);
    }
    const newTranslated = this.d2dCanvas.fromCanvasToDocumentCoordinate(
      newElementCenterPoint.getX(),
      newElementCenterPoint.getY(),
    );

    const translatePoint = new draw2d.geo.Point(
      Math.abs(oldTranslated.getX() - newTranslated.getX()),
      Math.abs(oldTranslated.getY() - newTranslated.getY()),
    );

    // If we didn't add to the zoom we'll need to reverse the x and y of the point since
    // we'll use it to calculate a vector
    if (!addedToZoom) {
      translatePoint.setX(0 - translatePoint.getX());
      translatePoint.setY(0 - translatePoint.getY());
    }

    // Apply the calculated translatePoint
    const newViewport = viewport.translated(translatePoint);

    // Set the proper size on the display element
    const svg = document.getElementsByTagName('svg')[0];
    const display = document.getElementById('display')! as HTMLElement;

    if (svg.height.baseVal.value > 2880) {
      display.style.width = svg.width.baseVal.value + 'px';
      display.style.height = svg.height.baseVal.value + 'px';
    } else {
      display.style.width = 2880 + 'px';
      display.style.height = 2880 + 'px';
    }
    this.d2dCanvas.scrollTo(newViewport.getY(), newViewport.getX());
  }

  zoomFit(): number {
    const boundingBox = this.getAllElementsBoundingBox();

    // Check to see if there are any elements present at all
    if (boundingBox.x === Infinity || boundingBox.y === Infinity) {
      this.setZoom(1);

      return 1;
    }

    boundingBox.scale(50, 50);

    const zoomContainer = this.wrapper.parentElement;
    if (!zoomContainer) {
      this.setZoom(1);

      return 1;
    }

    let zoom = Math.min(
      zoomContainer.clientWidth / boundingBox.getWidth(),
      zoomContainer.clientHeight / boundingBox.getHeight(),
    );

    if (zoom > 1) {
      zoom = 1;
    }

    this.d2dCanvas.scrollTo(
      boundingBox.getCenter().y - this.getViewportBoundingBox().getHeight() / 2,
      boundingBox.getCenter().x - this.getViewportBoundingBox().getWidth() / 2,
    );
    this.setZoom(zoom);
    return this.getZoom();
  }

  alignSelection(axis: string): void {
    const commandCollection = new draw2d.command.CommandCollection();
    const averagePosition: number = meanBy(
      this.getAllSelectedElements().map((element) => element[axis]),
    );
    this.getAllSelectedElements().forEach((element) => {
      const moveCommand = element.d2dElement.createCommand(
        new draw2d.command.CommandType(draw2d.command.CommandType.MOVE),
      );
      if (moveCommand !== null) {
        moveCommand.setStartPosition(element.x, element.y);
      }
      if (element.d2dElement.userData.isBarrier && axis === 'y') {
        element[axis] = averagePosition + 30;
      } else {
        element[axis] = averagePosition;
      }
      if (moveCommand !== null) {
        const x = element[axis] === 'x' ? averagePosition : element.x;
        const y = element[axis] === 'y' ? averagePosition : element.y;
        moveCommand.setPosition(x, y);
        commandCollection.add(moveCommand);
      }
    });
    this.d2dCanvas.getCommandStack().execute(commandCollection);
  }

  alignSelectionVertical(): void {
    this.alignSelection('y');
  }

  alignSelectionHorizontal(): void {
    this.alignSelection('x');
  }

  undo(): void {
    this.d2dCanvas.getCommandStack().undo();
  }

  redo(): void {
    this.d2dCanvas.getCommandStack().redo();
  }

  clearSelection(): void {
    this.d2dCanvas.setCurrentSelection(new draw2d.util.ArrayList());
  }

  selectElement(element: Element): void {
    this.d2dCanvas.setCurrentSelection(element.d2dElement);
  }

  exportToPng(transparent = true): void {
    const oldZoom = this.d2dCanvas.getZoom();
    this.d2dCanvas.setZoom(1);

    const dashboard = Ext.getCmp('tr2-dashboard');
    const body = ElementDescription.exportWithNoDescription();
    const boundingBox = window.canvas.getAllElementsBoundingBox();
    const zoomFactor = this.getZoom();
    const margin = 24;

    body.forEach((element, _index) => {
      element.element.d2dElement.remove(element.exportIdTooltip);
    });

    this.clearSelection();
    setTimeout(() => {
      let background;
      const writer = new draw2d.io.png.Writer();
      const boundingBoxRectangle = new draw2d.geo.Rectangle(
        (boundingBox.getLeft() - margin / 2) * zoomFactor,
        (boundingBox.getTop() - margin / 2) * zoomFactor,
        (boundingBox.getWidth() + margin) * zoomFactor,
        (boundingBox.getHeight() + margin) * zoomFactor,
      );

      if (!transparent) {
        background = new draw2d.shape.basic.Rectangle({
          stroke: 0,
          bgColor: '#ffffff',
          x: boundingBoxRectangle.x,
          y: boundingBoxRectangle.y,
          width: boundingBoxRectangle.w,
          height: boundingBoxRectangle.h,
        });

        this.d2dCanvas.add(background);

        background.toBack();
      }

      writer.marshal(
        this.d2dCanvas,
        (_png) => {
          if (background instanceof draw2d.Figure) {
            this.d2dCanvas.remove(background);
          }
          Ext.Msg.prompt(
            appMessages.app_general_messages.file_download.name,
            appMessages.app_general_messages.file_download.message,
            (btn, title) => {
              if (btn === 'ok') {
                const loadingMask = showLoadingMask(dashboard);
                hideLoadingMask(loadingMask);
                this.downloadURI(_png, `${title}.png`);
                this.d2dCanvas.setZoom(oldZoom);
              }

              if (btn === 'cancel') {
                this.d2dCanvas.setZoom(oldZoom);
              }
            },
          );
        },
        boundingBoxRectangle,
      );
    }, 50);
  }

  downloadURI(uri: string, name: string): void {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
  }

  exportToPDF(): void {
    const oldZoom = this.d2dCanvas.getZoom();
    this.d2dCanvas.setZoom(1);
    const dashboard = Ext.getCmp('tr2-dashboard');
    const boundingBox = this.getAllElementsBoundingBox();
    const zoomFactor = this.getZoom();
    const margin = 84;

    this.clearSelection();
    const formPanel = new Ext.FormPanel({
      labelWidth: 150,
      border: false,
      bodyPadding: 5,
      frame: false,
      defaultType: 'textfield',
      id: 'exportForm',
      listeners: {
        afterRender: () => {
          const elements = ElementDescription.exportWithDescription();
          elements.forEach((canvasElement, _index) => {
            if (canvasElement.element.exportIdTooltip.text !== '') {
              Ext.getCmp('descriptions').setValue(true);
            }
          });
        },
      },
      items: [
        {
          xtype: 'textfield',
          fieldLabel: `${appMessages.exportForm.exportTitleLabel}`,
          name: 'pdfTitle',
          allowBlank: false,
        },
        {
          xtype: 'checkbox',
          id: 'descriptions',
          fieldLabel: `${appMessages.exportForm.exportDescriptionsLabel}`,
          name: 'descriptions',
          checked: false,
          listeners: {
            change: () => {
              const formPanel = Ext.getCmp('exportForm').getForm().getValues();
              const elements = ElementDescription.exportWithDescription();
              if (formPanel.descriptions === 'on') {
                elements.forEach((canvasElement, _index) => {
                  canvasElement.element.exportIdTooltip.text = `${canvasElement.exportId}`;
                  canvasElement.element.showExportTooltip();
                });
              } else {
                elements.forEach((canvasElement, _index) => {
                  canvasElement.element.d2dElement.remove(
                    canvasElement.element.exportIdTooltip,
                  );
                });
              }
            },
          },
        },
      ],
      buttons: [
        {
          text: 'Export',
          formBind: true,
          disabled: true,
          handler: () => {
            const form = Ext.getCmp('exportForm').getForm().getValues();
            const descriptions = Ext.getCmp('descriptions').getValue();
            const writer = new draw2d.io.png.Writer();
            if (descriptions) {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              formWindow.close();
              const loadingMask = showLoadingMask(dashboard);
              setTimeout(() => {
                writer.marshal(
                  this.d2dCanvas,
                  (png) => {
                    const image = new Image();
                    image.addEventListener('load', () => {
                      // Elements
                      let orientation: any = 'portrait';

                      if (image.width > image.height) {
                        orientation = 'landscape';
                      }

                      const doc = new jsPDF({
                        orientation,
                        unit: 'px',
                        format: [image.width, image.height],
                        compress: true,
                      });
                      doc.addImage(png, 'PNG', 0, 0, image.width, image.height);

                      const pageHeight = doc.internal.pageSize.height;

                      // Descriptions
                      if (image.height >= pageHeight) {
                        doc.addPage('a4', 'portrait');
                      }

                      const columns = [
                        { header: '#', dataKey: 'exportId' },
                        { header: 'Label', dataKey: 'label' },
                        { header: 'Description', dataKey: 'description' },
                      ];

                      const body = ElementDescription.exportWithDescription();
                      doc.autoTable({
                        columnStyles: {
                          label: { minCellWidth: 65 },
                        },
                        columns,
                        body,
                        startY: 10,
                      });

                      doc.save(`${form.pdfTitle}.pdf`);
                      hideLoadingMask(loadingMask);

                      this.d2dCanvas.setZoom(oldZoom);
                    });

                    image.src = png;
                  },
                  new draw2d.geo.Rectangle(
                    (boundingBox.getLeft() - margin / 2) * zoomFactor,
                    (boundingBox.getTop() - margin / 2) * zoomFactor,
                    boundingBox.getWidth() + margin + zoomFactor,
                    (boundingBox.getHeight() + margin) * zoomFactor,
                  ),
                );
              }, 0);
              return;
            }
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            formWindow.close();
            const loadingMask = showLoadingMask(dashboard);
            setTimeout(() => {
              writer.marshal(
                this.d2dCanvas,
                (png) => {
                  const image = new Image();
                  image.addEventListener('load', () => {
                    // Elements
                    let orientation: any = 'portrait';

                    if (image.width > image.height) {
                      orientation = 'landscape';
                    }

                    const doc = new jsPDF({
                      orientation,
                      unit: 'px',
                      format: [image.width, image.height],
                      compress: true,
                    });
                    doc.addImage(png, 'PNG', 0, 0, image.width, image.height);
                    doc.save(`${form.pdfTitle}.pdf`);
                    hideLoadingMask(loadingMask);

                    this.d2dCanvas.setZoom(oldZoom);
                  });

                  image.src = png;
                },
                new draw2d.geo.Rectangle(
                  (boundingBox.getLeft() - margin / 2) * zoomFactor,
                  (boundingBox.getTop() - margin / 2) * zoomFactor,
                  boundingBox.getWidth() + margin + zoomFactor,
                  (boundingBox.getHeight() + margin) * zoomFactor,
                ),
              );
            }, 0);
          },
        },
      ],
    });
    const formWindow = new Ext.Window({
      closable: true,
      modal: true,
      title: appMessages.app_general_messages.file_download.name,
      layout: 'hbox',
      items: formPanel,
    });
    formWindow.show();
  }

  clear(): void {
    this.d2dCanvas.setZoom(1, false);
    this.d2dCanvas.clear();
  }

  setDirty(bool = true): void {
    this._isDirty = bool;
  }

  isDirty(): boolean {
    return this._isDirty;
  }

  loadFromUrl(projectID, treeID) {
    Ext.get('mask').show();
    this.show();
    ApiService.getTree(projectID, treeID)
      .then((result: any) => {
        const xml = result.xml;
        if (xml === null) {
          Ext.get('mask').hide();
          return;
        }
        this.currentProjectId = projectID;
        this.currentTreeId = treeID;
        this.load(new XMLLoader(xml));
      })
      .catch((e) => {
        errorAlert('Error', `${e.message}`);
      });
  }

  loadFromFile(file) {
    Ext.get('mask').show();
    this.load(new XMLLoader(file));
    this.show();
    Ext.get('mask').hide();
  }

  load(loader: CanvasLoader): void {
    Ext.get('mask').show();
    this.clear();
    loader.process(this);
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    this._isDirty = false;
    Ext.get('mask').hide();
    this.enable();
    this.zoomFit();
  }

  showContextMenu(e): void {
    e.preventDefault();

    if (this.isResizing) {
      return;
    }

    this.contextMenu.reset();

    const canvas = window.canvas.d2dCanvas;
    const hasEvent = !!window.canvas.getFirstEvent();
    const p = canvas.fromDocumentToCanvasCoordinate(e.clientX, e.clientY);
    const figure = canvas.getBestFigure(p.x, p.y);
    if (figure && figure.userData && figure.userData.element) {
      this.selectElement(figure.userData.element);
      figure.userData.element?.showContextMenu(e.clientX + 1, e.clientY + 1);
      return;
    } else {
      this.clearSelection();
    }

    if (window.canvas.isBowtie) {
      this.contextMenu.titleFactory('Bowtie', 'menu-title');
      if (!hasEvent) {
        this.contextMenu.addElementFactory('event');
      }
      this.contextMenu.addElementFactory('hazard');
      this.contextMenu.addElementFactory('target');
      this.contextMenu.defaultElementFactory();
      this.contextMenu.show(e.clientX + 1, e.clientY + 1);
      return;
    }
    this.contextMenu.titleFactory('Tree', 'menu-title');
    this.contextMenu.addElementFactory('event');
    this.contextMenu.addElementFactory('hazard');
    this.contextMenu.addElementFactory('target');
    this.contextMenu.addElementFactory('comment');
    this.contextMenu.defaultElementFactory();
    this.contextMenu.show(e.clientX + 1, e.clientY + 1);
  }

  save(): Promise<any> {
    const exporter = new XMLExporter(this);
    const xml = exporter.getXML();
    if (
      this.currentProjectId !== undefined &&
      this.currentTreeId !== undefined
    ) {
      return ApiService.saveTree(this.currentProjectId, this.currentTreeId, {
        xml: xml,
      }).catch((error) => {
        Ext.Msg.alert('Error', error.message);
      });
    } else {
      return Promise.reject();
    }
  }

  hide() {
    this.wrapper.style.visibility = 'hidden';
  }

  show() {
    this.wrapper.style.visibility = 'visible';
  }

  connectPrependEvent(fromElement: Element, toElement: Element): void {
    const outputPortIndex =
      toElement._className === 'Event' && fromElement.x > toElement.x ? 1 : 0;
    const outputPort = fromElement.d2dElement.getInputPort(0);
    const inputPort = toElement.d2dElement.getOutputPort(outputPortIndex);
    if (!outputPort || !inputPort) {
      return;
    }
    const connection = connectionLayout(inputPort, outputPort);
    this.d2dCanvas.add(connection);
  }

  connectAppendEvent(fromElement: Element, toElement: Element): void {
    const outputPort = fromElement.d2dElement.getInputPort(0);
    const inputPort = toElement.d2dElement.getOutputPort(0);
    if (!outputPort || !inputPort) {
      return;
    }
    const connection = connectionLayout(inputPort, outputPort);
    this.d2dCanvas.add(connection);
  }

  connectElements(
    fromElement: Element,
    toElement: Element,
    override?: number,
  ): void {
    let outputPortIndex = 0;

    if (
      this.isBowtie &&
      fromElement._className === 'Event' &&
      toElement._className === 'Target'
    ) {
      outputPortIndex = 1;
    }

    if (override) {
      outputPortIndex = override;
    }

    const outputPort = fromElement.d2dElement.getOutputPort(outputPortIndex);
    const inputPort = toElement.d2dElement.getInputPort(0);

    if (!outputPort || !inputPort) {
      return;
    }
    const connection = connectionLayout(outputPort, inputPort);
    this.d2dCanvas.add(connection);
  }
}

export default Canvas;
