/// <reference types="node" />
import axios, { AxiosResponse } from 'axios';
import TokenService from './token.service';
import { User } from '../interfaces/User';
import { GenerateReport } from '../interfaces/GenerateReport';
import { Trees } from '../interfaces/Trees';
import { errorAlert } from './app/global.service';
import appMessages from '../i18n/en/appMessages.json';

axios.defaults.baseURL = process.env.API_URL;

axios.interceptors.request.use(
  function (config) {
    const accessToken = TokenService.getToken();

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      errorAlert('Error', appMessages.app_general_messages.session_expired);
      Ext.getCmp('tr2-dashboard').destroy();
      Ext.widget('tr2-login');
      sessionStorage.clear();
    }

    return Promise.reject(error);
  },
);

const ApiService = {
  // save standard XML
  saveXML(name: string, bowtie: boolean): string {
    return `<?xml version="1.0"?>
                <tr2>
                    <tree>
                        <name>${name}</name>
                        <bowtie>${bowtie ? 1 : 0}</bowtie>
                        <elements>
                        </elements>
                    </tree>
                </tr2>`;
  },

  // Login user
  login: function (data: User): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .post('/login', data)
        .then((response: AxiosResponse<User>) => {
          const { trial, validUntil } = response.data.license;
          const licenseObject = {
            trial,
            validUntil,
          };
          const data = response.data;
          const { token: accessToken, member_id: loggedInUserId } = data;
          if (accessToken) {
            TokenService.setToken(accessToken);
            if (loggedInUserId != null) {
              sessionStorage.setItem('loggedInUser', loggedInUserId);
              sessionStorage.setItem('license', JSON.stringify(licenseObject));
            }
            resolve(response.data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get logged in user data
  getLoggedInUser(memberId: number): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .get(`members/${memberId}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Change password for current logged in user
  changePassword(memberId: number, data: User): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .post(`/members/${memberId}/change-password`, data)
        .then((response: AxiosResponse<User>) => resolve(response.data))
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Generate report
  generateReport(data: GenerateReport): Promise<GenerateReport> {
    return new Promise((resolve, reject) => {
      axios
        .post('/reports', data, {
          headers: {
            Authorization: `Bearer ${TokenService.getToken()}`,
          },
        })
        .then((response: AxiosResponse<GenerateReport>) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Generate Word Summary
  generateWord(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/summaries/word`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Generate summary
  generateSummary(data: GenerateReport): Promise<GenerateReport> {
    return new Promise((resolve, reject) => {
      axios
        .post('/summaries', data, {
          headers: {
            Authorization: `Bearer ${TokenService.getToken()}`,
          },
        })
        .then((response: AxiosResponse<GenerateReport>) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Update user
  updateLoggedInUser(memberId: number, data: User): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .put(`/members/${memberId}`, data)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get all trees connected to logged in user
  getTrees(): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .get('/tree')
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get single tree
  getTree(projectID: number, treeID: number): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .get(`/projects/${projectID}/trees/${treeID}`)
        .then((response: AxiosResponse<Trees>) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Update tree
  saveTree(projectID: number, treeID: number, data: Trees): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .put(`/projects/${projectID}/trees/${treeID}`, data as Trees)
        .then((response: AxiosResponse<Trees>) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Create new tree
  createTree(projectId: number, data: Trees): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .post(`/projects/${projectId}/trees`, data)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Delete tree
  deleteTree(projectId: number, treeId: number): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`projects/${projectId}/trees/${treeId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Create case
  createCase(data: Trees): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .post('/projects', data)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Get case
  getCase(projectID: number): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .get(`/projects/${projectID}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Update case
  saveCase(projectId: number, data: Trees): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .put(`/projects/${projectId}`, data as Trees)
        .then((response: AxiosResponse<Trees>) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  // Delete case
  deleteCase(projectId: number): Promise<Trees> {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/projects/${projectId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  sendFeedback(feedback) {
    return new Promise((resolve, reject) => {
      axios.post('/feedback', feedback).then(resolve).catch(reject);
    });
  },

  // Get me
  getMe(): Promise<User> {
    return new Promise((resolve, reject) => {
      axios
        .get(`me`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

export default ApiService;
