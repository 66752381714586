import Canvas from '../../canvas';
import Element from '../elements/element';
import LatentFailure from '../elements/latentFailure';
import ElementFactory from '../utils/ElementFactory';

export default class XMLExporter {
  xmlString = '';

  constructor(canvas: Canvas) {
    const treeData = canvas.treeData;
    this.xmlString = '<?xml version="1.0"?>';
    this.xmlString += '<tr2><tree>';
    if (treeData) {
      this.xmlString += `<name>${treeData.name}</name>`;
      this.xmlString += `<bowtie>${treeData.bowtie}</bowtie>`;
    }
    this.xmlString += '<elements>';
    const allElements = canvas.getAllElements();
    for (let index = 0; index < allElements.length; index++) {
      const element = allElements[index];

      const elementX = this.getBoundValue(
        0 - window.canvas.centerX,
        window.canvas.centerX - 100,
        element.x - window.canvas.centerX,
      );

      const elementY = this.getBoundValue(
        0 - window.canvas.centerY,
        window.canvas.centerY - 100,
        element.y - window.canvas.centerY,
      );

      this.xmlString += '<element>';
      this.xmlString += '<guid>' + element.guid + '</guid>';
      this.xmlString += '<name><![CDATA[' + element.name + ']]></name>';
      this.xmlString +=
        '<description><![CDATA[' + element.description + ']]></description>';
      this.xmlString +=
        '<type>' + ElementFactory.findTypeOfElement(element) + '</type>';
      this.xmlString += '<w>' + element.width + '</w>';
      this.xmlString += '<h>' + element.height + '</h>';
      this.xmlString += '<x>' + elementX + '</x>';
      this.xmlString += '<y>' + elementY + '</y>';
      this.xmlString += '<number>' + (index + 1) + '</number>';
      if (element instanceof LatentFailure) {
        this.xmlString += '<label>' + element.label + '</label>';
      }
      this.xmlString += '<style>';
      this.xmlString += '<font_size>' + element.style.fontSize + '</font_size>';
      this.xmlString += '</style>';
      this.xmlString += '</element>';
    }
    this.xmlString += '</elements>';

    this.xmlString += '<links>';
    for (const connection of canvas.getAllConnections()) {
      const fromElement = connection.getSource().getParent().getUserData()
        .element as Element;
      const toElement = connection.getTarget().getParent().getUserData()
        .element as Element;
      this.xmlString += '<link>';
      this.xmlString += '<from>' + fromElement.guid + '</from>';
      this.xmlString += '<to>' + toElement.guid + '</to>';
      this.xmlString += '</link>';
    }
    this.xmlString += '</links>';
    this.xmlString += '</tree></tr2>';
  }

  getBoundValue(min: number, max: number, value: number): number {
    if (value < min) return min;
    if (value > max) return max;
    return value;
  }

  getXML(): string {
    return this.xmlString;
  }
}
