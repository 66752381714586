import draw2d from 'draw2d';

draw2d.command.CommandAdd = draw2d.command.Command.extend({
  NAME: 'draw2d.command.CommandAdd',

  init: function (canvas, figure, x, y) {
    this._super(draw2d.Configuration.i18n.command.addShape);

    this.figure = figure;
    this.canvas = canvas;
    this.connections = null;
    this.pos = new draw2d.geo.Point(x, y);
  },

  canExecute: function () {
    return this.figure.getCanvas() === null;
  },

  execute: function () {
    this.redo();
  },

  redo: function () {
    this.canvas.add(this.figure, this.pos.x, this.pos.y);
    this.canvas.setCurrentSelection(this.figure);

    if (this.figure instanceof draw2d.Connection) {
      this.figure.reconnect();
    }

    if (this.connections) {
      for (let i = 0; i < this.connections.getSize(); ++i) {
        this.canvas.add(this.connections.get(i));
        this.connections.get(i).reconnect();
      }
    }

    if (this.figure.userData && this.figure.userData.element) {
      this.figure.userData.element.redoFillColors();
    }
  },

  undo: function () {
    this.canvas.setCurrentSelection(null);

    if (this.connections === null) {
      if (this.figure instanceof draw2d.shape.node.Node) {
        this.connections = this.figure.getConnections();
      } else {
        this.connections = new draw2d.util.ArrayList();
      }
    }

    for (let i = 0; i < this.connections.getSize(); ++i) {
      this.canvas.remove(this.connections.get(i));
    }

    this.canvas.remove(this.figure);
  },
});
