import ApiService from '../api.service';
import { Trees } from '../../interfaces/Trees';
import { errorAlert } from './global.service';
import * as XLSX from 'xlsx';
import { WorkBook } from 'xlsx';
import appMessages from '../../i18n/en/appMessages.json';

const { read, writeFile } = XLSX;
export const data = Ext.create('Ext.data.TreeStore', {
  id: 'datastore',
  autoSync: true,
  autoLoad: false,
});

export function loadCases(data: any): void {
  ApiService.getTrees()
    .then((result) => {
      const rootNode: Trees = {
        nodeType: 'async',
        name: 'TR2',
        id: 'root',
        expanded: true,
        editable: true,
        children: [],
      };
      result.forEach?.((groupData) => {
        const groupItem: Trees = {
          id: `group-${groupData.id}`,
          groupDataID: groupData.id,
          name: groupData.name,
          icon: '/ext/appimages/database_gear.png',
          expanded: true,
          children: [],
        };

        groupData.projects.forEach((caseData) => {
          const caseItem: Trees = {
            id: `extModel-${caseData.id}`,
            caseDataID: caseData.id,
            name: caseData.name,
            icon: '/ext/appimages/briefcase.png',
            type: 'case',
            children: [],
            checked: false,
          };

          caseData.trees.forEach((treeData) => {
            let treeItem: Trees;
            if (treeData.bowtie) {
              treeItem = {
                id: treeData.id,
                name: treeData.name,
                bowtie: treeData.bowtie,
                icon: '/ext/appimages/bowtie.png',
                type: 'tree',
                leaf: true,
                checked: false,
              };
              caseItem.children?.push(treeItem);
              return;
            }
            treeItem = {
              id: treeData.id,
              name: treeData.name,
              bowtie: treeData.bowtie,
              icon: '/ext/appimages/chart_organisation.png',
              type: 'tree',
              leaf: true,
              checked: false,
            };
            caseItem.children?.push(treeItem);
          });

          groupItem.children?.push(caseItem);
        });

        rootNode.children?.push(groupItem);
      });

      data.setRootNode(rootNode);
    })
    .catch(() => {
      errorAlert(
        appMessages.app_general_messages.error,
        appMessages.serverError,
      );
    });
}

export function addNumbersToArray(elementChecked, array): void {
  if (elementChecked.length === 0) return;

  for (let i = 0; i < elementChecked.length; i++) {
    if (elementChecked[i] === undefined) continue;
    if (elementChecked[i].data.type === 'case') {
      array.push(elementChecked[i].data.caseDataID);
    }
    if (elementChecked[i].data.type === 'tree') {
      array.push(elementChecked[i].data.id);
    }
  }
}

export function saveFile(file: WorkBook): Ext {
  return Ext.MessageBox.show({
    prompt: true,
    title: `Filename`,
    id: 'download',
    buttons: Ext.Msg.OK,
    fn: function (btn, value): void {
      if (btn === 'ok') {
        writeFile(file, `${value}.xlsx`);
      }
    },
  });
}

export function convertFile(base64: string): any {
  return read(base64.replace(/_/g, '/').replace(/-/g, '+'), {
    type: 'base64',
  });
}
