import draw2d from 'draw2d';

draw2d.policy.figure.ResizeEventsSelectionFeedbackPolicy = draw2d.policy.figure.RectangleSelectionFeedbackPolicy.extend(
  {
    NAME: 'draw2d.policy.figure.ResizeEventsSelectionFeedbackPolicy',

    /**
     * @constructor
     * Creates a new Router object
     */
    init: function (attr, setter, getter) {
      this._super(attr, setter, getter);
    },

    onResizeStart: function (callback) {
      this.resizeStartCallback = callback;
    },

    onResizeEnd: function (callback) {
      this.resizeEndCallback = callback;
    },

    /**
     * @method
     * Called by the framework of the Policy should show a resize handle for the given shape
     *
     * @param {draw2d.Canvas} canvas The host canvas
     * @param {draw2d.Figure} figure The related figure
     * @param {Boolean} isPrimarySelection
     */
    onSelect: function (canvas, figure, isPrimarySelection) {
      this._super(canvas, figure, isPrimarySelection);

      if (!figure.selectionHandles.isEmpty()) {
        figure.selectionHandles.each((_i, e) => {
          if (e.isResizeHandle) {
            const originalDragStart = e.onDragStart.bind(e);
            const originalDragEnd = e.onDragEnd.bind(e);

            e.onDragStart = (x, y, shiftKey, ctrlKey) => {
              if (this.resizeStartCallback) {
                this.resizeStartCallback(e, x, y, shiftKey, ctrlKey);
              }

              originalDragStart(x, y, shiftKey, ctrlKey);
            };

            e.onDragEnd = (x, y, shiftKey, ctrlKey) => {
              if (this.resizeEndCallback) {
                this.resizeEndCallback(e, x, y, shiftKey, ctrlKey);
              }

              originalDragEnd(x, y, shiftKey, ctrlKey);
            };
          }
          e.setDimension(12, 12);
          e.setRadius(4);
        });
      }
      this.moved(canvas, figure);
    },
  },
);

export const ResizeEventsSelectionFeedbackPolicy = draw2d.policy.figure.ResizeEventsSelectionFeedbackPolicy;
