import ApiService from './services/api.service';
import TokenService from './services/token.service';
import {
  automaticSave,
  errorAlert,
  initializeAutomaticSave,
  setStatusBar,
} from './services/app/global.service';
import appMessages from './i18n/en/appMessages.json';
import './services/app/dashboard.service';

automaticSave();
initializeAutomaticSave();

async function getCurrentUser(id): Promise<any> {
  const memberId = parseInt(id, 10);
  try {
    TR2.profile = await ApiService.getLoggedInUser(memberId);
    return TR2.profile;
  } catch (e) {
    errorAlert(
      appMessages.app_general_messages.member_not_found,
      appMessages.expiredLicense,
    );
    if (e.message === 'License not valid') {
      TokenService.removeToken();
      sessionStorage.removeItem('loggedInUser');
      sessionStorage.removeItem('license');
      Ext.getCmp('tr2-dashboard').destroy();
      Ext.widget('tr2-login');
    }
  }
}

async function init(this: any): Promise<void> {
  const memberId = sessionStorage.getItem('loggedInUser');
  if (memberId === null) {
    return;
  }
  try {
    const c = Ext.get('scrollcontainer');
    const wh = c.getSize();
    await getCurrentUser(memberId);
    c.scrollTo('top', 2880 / 2 - wh.height / 2);
    c.scrollTo('left', 2880 / 2 - wh.width / 2);
    setStatusBar(appMessages.app_general_messages.ready);
    window.onbeforeunload = function (): string | undefined {
      if (window.canvas.isDirty()) {
        return appMessages.app_general_messages.page_leave_message;
      }
    };
  } catch (e) {
    errorAlert(appMessages.app_general_messages.error, appMessages.serverError);
  }
}

Ext.ns('TR2');
TR2.Dashboard = {
  extend: 'Ext.Viewport',
  layout: 'border',
  xtype: 'tr2-dashboard',
  id: 'tr2-dashboard',
  items: [
    {
      xtype: 'tr2-toolbar',
      region: 'north',
    },
    {
      xtype: 'tr2-explorer',
      region: 'west',
      collapsible: true,
      split: true,
      margins: '3px 0px 3px 3px',
      border: false,
      width: 300,
    },
    {
      region: 'center',
      layout: 'border',
      border: false,
      items: [
        {
          xtype: 'tr2-content',
          layout: 'container',
          margins: '3px 3px 0px 0px',
          region: 'center',
        },
        {
          xtype: 'tr2-properties',
          title: 'Properties',
          margins: '0px 3px 3px 0px',
          region: 'south',
          split: true,
          layout: 'border',
          collapsible: true,
          frame: true,
          height: 250,
        },
      ],
    },
    {
      id: 'statusbar',
      region: 'south',
      xtype: 'statusbar',
      text: 'Loading...',
      height: 22,
      margins: '0px 3px 3px 3px',
    },
  ],
  listeners: {
    render: function (): void {
      const c = Ext.getCmp('actions');
      const f = Ext.getCmp('figure');
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, { value: 0 });

      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, { value: 0 });

      document.addEventListener('keyup', (event) => {
        if (event.key === 'y' && event.ctrlKey) {
          window.canvas.redo();
        } else if (event.key === 'z' && event.ctrlKey) {
          window.canvas.undo();
        }
      });

      setInterval(function () {
        try {
          window.canvas.save().then(() => {
            setStatusBar(appMessages.tree.saved);
          });
        } catch (e) {
          setStatusBar(appMessages.serverError);
          errorAlert(
            appMessages.app_general_messages.error,
            appMessages.serverError,
          );
        }
      }, 1000 * 60 * 5);
      setTimeout(init, 1000);
    },
    afterRender: function (): void {
      const license = sessionStorage.getItem('license')!;
      const userLicense = JSON.parse(license);
      if (userLicense === null) return;
      const today = new Date();
      const month = today.getMonth() + 1; // January is 0
      const minutes = today.getMinutes();
      const day = today.getDate();
      let correctDay;
      let correctMonth;
      let correctMinutes;

      if (month <= 9) {
        correctMonth = `0${month}`;
      } else {
        correctMonth = month;
      }

      if (minutes <= 9) {
        correctMinutes = `0${minutes}`;
      } else {
        correctMinutes = minutes;
      }

      if (day <= 9) {
        correctDay = `0${day}`;
      } else {
        correctDay = day;
      }

      const date7DayFromNow = `${today.getFullYear()}-${correctMonth}-${
        today.getDate() + 7
      }`;
      const date = `${today.getFullYear()}-${correctMonth}-${correctDay}`;
      const time = `${today.getHours()}:${correctMinutes}:00`;
      const licenseDate = userLicense.validUntil.slice(0, -9);
      const licenseTime = userLicense.validUntil.substring(
        userLicense.validUntil.indexOf(' ') + 1,
      );
      if (date === licenseDate && time === licenseTime) {
        errorAlert('Error', 'License is expired, please renew your license.');
        setTimeout(() => {
          sessionStorage.clear();
          Ext.getCmp('tr2-dashboard').destroy();
          Ext.widget('tr2-login');
        }, 2500);
        return;
      }

      if (date7DayFromNow >= licenseDate) {
        errorAlert(
          'Error',
          `License is valid until ${licenseDate}. Make sure you renew your license.`,
        );
        return;
      }
    },
  },
};

Ext.define('tr2-dashboard', TR2.Dashboard);
