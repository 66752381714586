import draw2d from 'draw2d';

import BlockElement from './blockElement';
import ContextMenu from '../ui/ContextMenu';

export default class ActiveFailure extends BlockElement {
  static get _className(): string {
    return 'ActiveFailure';
  }
  public _className = 'ActiveFailure';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(x, y, guid, w, h);

    this.d2dElement.addPort(
      new draw2d.InputPort(),
      new draw2d.layout.locator.RightLocator(),
    );
    this.d2dElement.addPort(
      new draw2d.OutputPort(),
      new draw2d.layout.locator.LeftLocator(),
    );
    this.setFillColor('#666666');
  }

  showContextMenu(x: number, y: number): void {
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);

    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
