import { LoaderElementInterface } from '../loaders/LoaderElementInterface';
import EffectiveBarrier from '../elements/effectiveBarrier';
import Element from '../elements/element';
import LatentFailure from '../elements/latentFailure';
import ActiveFailure from '../elements/activeFailure';
import Comment from '../elements/comment';
import Event from '../elements/event';
import EventHazard from '../elements/eventHazard';
import EventTarget from '../elements/eventTarget';
import FailedBarrier from '../elements/failedBarrier';
import Hazard from '../elements/hazard';
import MissingBarrier from '../elements/missingBarrier';
import Precondition from '../elements/precondition';
import Target from '../elements/target';
import InadequateBarrier from '../elements/inadequateBarrier';

export const elementTypes = {
  'active failure': ActiveFailure,
  comment: Comment,
  'effective barrier': EffectiveBarrier,
  event: Event,
  'event hazard': EventHazard,
  'event target': EventTarget,
  'prepend event': Event,
  'append event': Event,
  'failed barrier': FailedBarrier,
  'inadequate barrier': InadequateBarrier,
  hazard: Hazard,
  'latent failure': LatentFailure,
  'missing barrier': MissingBarrier,
  precondition: Precondition,
  target: Target,
};

class ElementFactory {
  create(type: string, x, y: number, guid = '', w = 160, h = 100): Element {
    return new elementTypes[type](x, y, guid, w, h);
  }

  createFromLoader(elementData: LoaderElementInterface): Element {
    const element = this.create(
      elementData.type,
      elementData.x,
      elementData.y,
      elementData.guid,
      elementData.w,
      elementData.h,
    );
    element.style = elementData.style;
    element.description = elementData.description;
    element.name = elementData.name;
    if (element instanceof LatentFailure) {
      element.label = elementData.label ? elementData.label : 0;
    }
    return element;
  }

  findTypeOfElement(element: Element): string {
    for (const type in elementTypes) {
      const cls = elementTypes[type];
      if (cls._className === element._className) {
        return type;
      }
    }
    return '';
  }

  scrollElementIntoView(element: Element) {
    setTimeout(() => {
      const x =
        element.x -
        Ext.getCmp('scrollcontainer').getWidth() / 2 +
        element.width / 2;

      const y =
        element.y -
        Ext.getCmp('scrollcontainer').getHeight() / 2 +
        element.height / 2;

      Ext.getCmp('scrollcontainer').scrollTo(Math.max(x, 0), Math.max(y, 0));
    }, 0);
  }
}

export default new ElementFactory();
