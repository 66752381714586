import { updateEmail, closeEmailModal } from './services/app/account.service';

TR2.Account = Ext.extend(Ext.Window, {
  id: 'account',
  title: 'My account',
  width: 350,
  modal: true,
  plain: true,
  border: false,
  frame: false,
  closeAction: 'hide',
  initComponent: function () {
    Ext.apply(this, {
      items: {
        id: 'accountform',
        xtype: 'form',
        reference: 'form',
        autoEl: 'form',
        layout: 'form',
        url: __BASE_URL + '/tr2/auth/set-profile/',
        border: false,
        labelWidth: 120,
        items: [
          {
            xtype: 'textfield',
            id: 'email',
            fieldLabel: 'E-mail',
            name: 'email',
            vtype: 'email',
            allowBlank: false,
            width: 200,
            value: TR2.profile.email,
            listeners: {
              specialkey: function (_f: any, e: any): void {
                if (e.getKey() !== e.ENTER) {
                  return;
                }

                e.preventDefault();

                updateEmail();
              },
            },
          },
        ],
      },
      buttons: [
        {
          formBind: true,
          text: 'Update',
          handler: updateEmail,
        },
        {
          text: 'Cancel',
          handler: closeEmailModal,
        },
      ],
    });

    TR2.Account.superclass.initComponent.apply(this, arguments);
  },
});
Ext.define('tr2-account', TR2.Account);
