import Canvas from '../canvas';

TR2.Content = {
  extend: 'Ext.Panel',
  xtype: 'tr2-content',
  layout: 'container',
  id: 'tr2-content',
  tbar: window.contentToolbar,
  config: {
    width: 2880,
    height: 2880,
  },
  items: [
    {
      id: 'display-container',
      xtype: 'container',
      layout: 'container',
      style: {
        height: '100%',
      },
      items: [
        {
          xtype: 'container',
          layout: 'container',
          id: 'scrollcontainer',
          cls: 'scrollcontainer',
          autoScroll: true,
          viewConfig: {
            preserveScrollOnReload: true,
          },
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            zIndex: '3',
            backgroundColor: '#CCCCCC',
          },
          items: [
            {
              xtype: 'container',
              layout: 'container',
              id: 'display',
              width: 2880,
              height: 2880,
              listeners: {
                afterRender: function (): void {
                  window.canvas = new Canvas(2880, 2880);
                },
              },
            },
          ],
        },
      ],
    },
  ],
};

Ext.define('tr2-content', TR2.Content);
