import draw2d from 'draw2d';
import ElementFactory from '../utils/ElementFactory';
import Element from '../elements/element';

draw2d.command.CommandConvert = draw2d.command.Command.extend({
  NAME: 'draw2d.command.CommandConvert',

  init: function (canvas, type, element) {
    this._super('Convert element');
    this.element = element;
    this.type = type;
    this.canvas = canvas;
    this.newElement = ElementFactory.create(
      type,
      element.x,
      element.y,
      element.guid,
    );
    this.newElement.description = element.description;
    this.newElement.style = element.style;
  },

  canExecute: function () {
    return this.type !== ElementFactory.findTypeOfElement(this.element);
  },

  execute: function () {
    this.redo();
  },

  switchElements: function (fromElement: Element, toElement: Element): void {
    this.canvas.add(toElement, false, true);
    const connections = fromElement.d2dElement.getConnections().asArray();
    for (const connection of connections) {
      const from = connection.sourcePort.parent.userData.element;
      const to = connection.targetPort.parent.userData.element;
      if (from === this.element) {
        this.canvas.connectElements(toElement, to);
      } else {
        this.canvas.connectElements(from, toElement);
      }
      this.canvas.d2dCanvas.remove(connection);
    }
    this.canvas.d2dCanvas.remove(fromElement.d2dElement);
    toElement.redoFillColors();
  },

  redo: function () {
    this.switchElements(this.element, this.newElement);
  },

  undo: function () {
    this.switchElements(this.newElement, this.element);
  },
});
