import ApiService from './services/api.service';
import TokenService from './services/token.service';
import { data, loadCases } from './services/app/case-selector.service';
import { errorAlert } from './services/app/global.service';
import appMessages from '../app/i18n/en/appMessages.json';

if (TokenService.hasToken()) {
  loadCases(data);
}

const fileDownload = (summary, fileName) => {
  const convertedFile = atob(summary);
  const link = document.createElement('a');
  link.href =
    'data:application/octet-stream;charset=utf-8;base64,' + btoa(convertedFile);
  link.download = `${fileName}.docx`;
  document.body.appendChild(link);
  link.click();
};

TR2.WordSelector = Ext.extend(Ext.Window, {
  id: 'case-word-selector',
  title: `${appMessages.menu.report.items.word_export}`,
  width: 300,
  height: 300,
  modal: true,
  plain: true,
  closeAction: 'hide',
  border: false,
  listeners: {
    close: function (win): void {
      win.hide();
    },
    render: function (): void {
      loadCases(data);
    },
  },
  initComponent: function () {
    Ext.apply(this, {
      layout: 'fit',
      items: [
        {
          xtype: 'treepanel',
          useArrows: true,
          autoScroll: true,
          stateful: true,
          border: false,
          containerScroll: true,
          autoLoad: false,
          rootVisible: false,
          waitMsg: 'Loading...',
          store: data,
          columns: [
            {
              xtype: 'treecolumn',
              itemId: 'header',
              flex: 2,
              sortable: true,
              dataIndex: 'name',
              editable: true,
            },
          ],
        },
      ],
      buttons: [
        {
          text: 'Ok',
          handler: function (): void {
            const element = (this as any).items.get(0);
            const checked = element.getChecked();
            let currentId;
            let fileName;

            if (checked.length === 0 || checked.length >= 2) {
              errorAlert('Error', appMessages.tree.no_selection);
              return;
            }
            checked.forEach((item) => {
              if (item.data.type === 'case') {
                errorAlert('Error', appMessages.tree.no_selection);
                return;
              }

              if (item.data.type === 'tree') {
                fileName = item.data.name;
                currentId = item.data.id;
              }
            });
            if (currentId !== undefined) {
              ApiService.generateWord({
                tree: currentId,
              })
                .then((result: any) => {
                  const { summary } = result;
                  fileDownload(summary, fileName);
                  (this as any).hide();
                })
                .catch(() => {
                  errorAlert(
                    appMessages.app_general_messages.error,
                    appMessages.serverError,
                  );
                });
            }
          },
          scope: this,
        },
        {
          text: 'Cancel',
          handler: function (): void {
            (this as any).hide();
          },
          scope: this,
        },
      ],
    });

    TR2.WordSelector.superclass.initComponent.apply(this, arguments);
  },
});
Ext.define('tr2-word-selector', TR2.WordSelector);
