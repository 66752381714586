import ApiService from '../../services/api.service';
import { User } from '../../interfaces/User';
import { errorAlert, setStatusBar } from './global.service';
import appMessages from '../../i18n/en/appMessages.json';

export const updateEmail = () => {
  const f = Ext.getCmp('accountform');
  if (f.getForm().isValid()) {
    const memberId = sessionStorage.getItem('loggedInUser');
    const email = Ext.getCmp('email').getValue();
    if (memberId === null || isNaN(+memberId)) return;
    ApiService.updateLoggedInUser(+memberId, {
      email: email,
    })
      .then((result: User) => {
        setStatusBar(
          `${appMessages.app_general_messages.form_success.message_two} ${result.email}`,
        );
      })
      .catch(() => {
        errorAlert(
          appMessages.app_general_messages.error,
          appMessages.serverError,
        );
      });
  } else {
    errorAlert(
      appMessages.app_general_messages.error,
      appMessages.app_general_messages.form_errors.message_tree,
    );
  }
};

export function closeEmailModal(): void {
  TR2.account.hide();
}
