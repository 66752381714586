import {
  data,
  addElement,
  insertBarrier,
  addPrependEvent,
  addAppendEvent,
} from './services/app/actions.service';
import { errorAlert } from './services/app/global.service';
import appMessages from '../app/i18n/en/appMessages.json';

const Actions = {
  extend: 'Ext.ListView',
  xtype: 'tr2-actions',
  id: 'actions',
  columns: [
    {
      xtype: 'templatecolumn',
      header: 'Name',
      width: '100%',
      style: {
        border: 'none',
      },
      tpl: `<div id="{elementId}"><img src="${__BASE_URL}/ext/appimages/{icon}" align="absmiddle" style="padding-right: 3px; width:16px;" />{name}</div>`,
      dataIndex: 'name',
    },
  ],
  store: data,
  listeners: {
    select: function (
      el: { getStore: () => any },
      _record: any,
      index: any,
    ): void {
      const store = el.getStore();
      const record = store.getAt(index);
      const type = record.data.type;
      const selectedElement = window.canvas.getSingleSelectedElement();
      try {
        switch (record.data.idaction) {
          case 16:
            addElement('hazard', selectedElement, true);
            addElement('target', selectedElement, false);
            break;
          case 2097152:
          case 256:
          case 128:
          case 64:
            if (selectedElement) {
              insertBarrier(type, selectedElement);
            }
            break;
          case 4194304:
          case 131072:
          case 65536:
          case 32768:
          case 16384:
          case 4096:
          case 8192:
          case 4:
          case 2:
            if (selectedElement) {
              if (!selectedElement.name && !selectedElement.description) {
                return selectedElement.convertTo(type, false);
              }

              const keepElement = (btn) => {
                const keepElementData = btn === 'yes';
                selectedElement.convertTo(type, keepElementData);
              };

              Ext.MessageBox.confirm(
                `Convert to ${type}`,
                `Keep label & description from ${selectedElement._className}`,
                keepElement,
              );
            }
            break;
          case 524288:
            if (selectedElement) {
              addPrependEvent('event', selectedElement);
            }
            break;
          case 1048576:
            if (selectedElement) {
              addAppendEvent(type, selectedElement);
            }
            break;
          default:
            try {
              addElement(type, selectedElement);
            } catch (e) {
              errorAlert(
                appMessages.app_general_messages.error,
                appMessages.serverError,
              );
            }

            break;
        }
      } catch (e) {
        errorAlert(
          appMessages.app_general_messages.error,
          appMessages.serverError,
        );
      }
    },
  },
};

Ext.define('tr2-actions', Actions);
