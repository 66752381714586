import Element from './element';
import draw2d from 'draw2d';

const portLeftXPercentage = 42;
const portRightXPercentage = 58;
const portLeftYPercentage = 20;
const portRightYPercentage = 20;

const nameFieldTopRatio = 0.42;
const nameFieldLeftRatio = 0;
const nameFieldHeightRatio = 1 - nameFieldTopRatio;
const nameFieldWidthRatio = 1 - nameFieldLeftRatio;

const defaultRadius = 10;

export default class BarrierElement extends Element {
  static get _className(): string {
    return 'BarrierElement';
  }
  public _className = 'BarrierElement';

  constructor(
    x: number,
    y: number,
    guid = '',
    w: number,
    h: number,
    svg?: string,
  ) {
    super(x, y, guid, w, h);

    this.d2dElement.userData.isBarrier = true;

    if (svg) {
      this.d2dElement.setSVG(svg);
    } else {
      this.d2dElement.setSVG(
        `<svg width="${w}" height="${h}" xmlns="http://www.w3.org/2000/svg"><path stroke="#000000" d="M161.75 40C165.2 40 168 42.8 168 46.25C168 56.2 168 79.8 168 89.75C168 93.2 165.2 96 161.75 96C129.4 96 38.6 96 6.25 96C2.8 96 0 93.2 0 89.75C0 79.8 0 56.2 0 46.25C0 42.8 2.8 40 6.25 40C38.6 40 129.4 40 161.75 40Z"></path><path stroke="#000000" d="M72.27 0L96.27 0L96.27 40L72.27 40L72.27 0Z"></path></svg>`,
      );
    }

    this.setFillColor('#FFFFFF');

    const inputPort = new draw2d.InputPort();
    inputPort.setAlpha(0);
    inputPort.on('connect', () => {
      inputPort.setAlpha(0);
    });
    const outputPort = new draw2d.OutputPort();
    outputPort.setAlpha(0);
    outputPort.on('connect', () => {
      outputPort.setAlpha(0);
    });
    if (this.isRightElement()) {
      this.d2dElement.addPort(
        inputPort,
        new draw2d.layout.locator.XYRelPortLocator(
          portLeftXPercentage,
          portLeftYPercentage,
        ),
      );
      this.d2dElement.addPort(
        outputPort,
        new draw2d.layout.locator.XYRelPortLocator(
          portRightXPercentage,
          portRightYPercentage,
        ),
      );
    } else {
      this.d2dElement.addPort(
        inputPort,
        new draw2d.layout.locator.XYRelPortLocator(
          portRightXPercentage,
          portRightYPercentage,
        ),
      );
      this.d2dElement.addPort(
        outputPort,
        new draw2d.layout.locator.XYRelPortLocator(
          portLeftXPercentage,
          portLeftYPercentage,
        ),
      );
    }

    this.addNameField(
      this.nameFieldWidth,
      this.nameFieldHeight,
      nameFieldLeftRatio * 100,
      nameFieldTopRatio * 100,
      defaultRadius,
      true,
    );
    this.addDescriptionField();
    this.addExportTooltip();

    this._d2dElement.off('resize');
    this._d2dElement.on('resize', () => {
      if (!this._nameLabel || !this._labelRectangle) {
        return;
      }

      this._labelRectangle.setWidth(this.nameFieldWidth);
      this._labelRectangle.userData.width = this.nameFieldWidth;
      this._labelRectangle.setHeight(this.nameFieldHeight);
      this._labelRectangle.setRadius(this.radius);

      this._nameLabel.setWidth(this._labelRectangle.getWidth() - this._labelRectangle.getWidth() * 0.05);
      this._nameLabel.userData.width = this._labelRectangle.getWidth() - this._labelRectangle.getWidth() * 0.05;
      this._nameLabel.setHeight(this._labelRectangle.getHeight() - this._labelRectangle.getHeight() * 0.05);
      this._nameLabel.setRadius(this._labelRectangle.getRadius());

      // This causes the TextBreaker to recalculate the text it can display
      setTimeout(() => {
        this.name = this.name;
      }, 0);
    });
  }

  get nameFieldHeight(): number {
    return super.height * nameFieldHeightRatio;
  }

  get nameFieldWidth(): number {
    return super.width * nameFieldWidthRatio;
  }

  get radius(): number {
    return defaultRadius * (this.width / 328);
  }
}
