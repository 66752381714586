import draw2d from 'draw2d';
import BlockElement from './blockElement';
import ContextMenu from '../ui/ContextMenu';

export default class Target extends BlockElement {
  static get _className(): string {
    return 'Target';
  }
  public _className = 'Target';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(x, y, guid, w, h);

    if (window.canvas.isBowtie) {
      this.d2dElement.addPort(
        new draw2d.InputPort(),
        new draw2d.layout.locator.LeftLocator(),
      );
    } else {
      this.d2dElement.addPort(
        new draw2d.InputPort(),
        new draw2d.layout.locator.RightLocator(),
      );
    }
    this.setFillColor('#79D327');
  }

  showContextMenu(x: number, y: number) {
    const hasEvent = !!window.canvas.getFirstEvent();
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);
    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };
    if (window.canvas.isBowtie) {
      this.elementContextMenu.titleFactory(
        contextMenuTitle.element,
        contextMenuTitle.id,
      );
      if (!hasEvent) {
        this.elementContextMenu.addElementFactory('event');
      }
      this.elementContextMenu.addElementFactory('target');
      this.elementContextMenu.addElementFactory('hazard');
      this.elementContextMenu.addBarrierFactory('effective barrier');
      this.elementContextMenu.addBarrierFactory('failed barrier');
      this.elementContextMenu.addBarrierFactory('missing barrier');
      this.elementContextMenu.addDivider();
      this.elementContextMenu.defaultElementFactory(this);
      this.elementContextMenu.show(x, y);
      return;
    }

    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.addElementFactory('event');
    this.elementContextMenu.addElementFactory('target');
    this.elementContextMenu.addElementFactory('hazard');
    this.elementContextMenu.addElementFactory('latent failure');
    this.elementContextMenu.addElementFactory('precondition');
    this.elementContextMenu.addBarrierFactory('effective barrier');
    this.elementContextMenu.addBarrierFactory('failed barrier');
    this.elementContextMenu.addBarrierFactory('missing barrier');
    this.elementContextMenu.convertElement(this, 'event target');
    this.elementContextMenu.addDivider();
    this.elementContextMenu.addElementFactory('comment');
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
