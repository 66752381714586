import ApiService from './services/api.service';
import appMessages from './i18n/en/appMessages.json';
import { errorAlert, setStatusBar } from './services/app/global.service';

TR2.Password = Ext.extend(Ext.Window, {
  id: 'password',
  title: 'Change password',
  padding: 10,
  width: 400,
  closeAction: 'show',
  plain: false,
  modal: true,
  border: false,
  frame: false,
  initComponent: function () {
    Ext.apply(this, {
      items: {
        id: 'passwordform',
        xtype: 'form',
        reference: 'changePasswordForm',
        autoEl: 'form',
        layout: 'form',
        border: false,
        items: [
          {
            xtype: 'textfield',
            fieldLabel: 'Current password',
            inputType: 'password',
            name: 'old',
            allowBlank: false,
            id: 'current-password',
          },
          {
            id: 'password1',
            xtype: 'textfield',
            fieldLabel: 'New password',
            name: 'new',
            inputType: 'password',
            allowBlank: false,
          },
          {
            xtype: 'textfield',
            fieldLabel: 'Re-enter password',
            inputType: 'password',
            id: 'repeat-password',
            allowBlank: false,
            validator: function (v2): string | boolean {
              const v1 = Ext.getCmp('password1').getValue();
              if (v1 != v2) {
                return appMessages.app_general_messages.form_errors.message_two;
              }
              return true;
            },
          },
        ],
      },
      buttons: [
        {
          formBind: true,
          text: 'Ok',
          handler: function (): void {
            const currentPassword = Ext.getCmp('current-password').getValue();
            const newPassword = Ext.getCmp('password1').getValue();
            const repeatPassword = Ext.getCmp('repeat-password').getValue();
            const passwordForm = Ext.getCmp('passwordform');
            const memberId = sessionStorage.getItem('loggedInUser');

            if (newPassword !== repeatPassword) {
              errorAlert(
                appMessages.app_general_messages.error,
                appMessages.app_general_messages.form_errors.message_two,
              );
              return;
            }

            if (passwordForm.isValid()) {
              if (memberId === null) return;
              ApiService.changePassword(+memberId, {
                // eslint-disable-next-line @typescript-eslint/camelcase
                current_password: currentPassword,
                // eslint-disable-next-line @typescript-eslint/camelcase
                new_password: newPassword,
              })
                .then(() => {
                  setStatusBar(
                    appMessages.app_general_messages.form_success.message_one,
                  );
                })
                .catch(() => {
                  errorAlert(
                    appMessages.app_general_messages.error,
                    appMessages.serverError,
                  );
                });
            } else {
              errorAlert(
                appMessages.app_general_messages.error,
                appMessages.app_general_messages.form_errors.message_two,
              );
            }
          },
        },
        {
          text: 'Cancel',
          handler: function (): void {
            TR2.password.hide();
          },
        },
      ],
    });
    TR2.Password.superclass.initComponent.apply(this, arguments);
  },
});

Ext.define('tr2-password', TR2.Password);
