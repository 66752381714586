import ApiService from './services/api.service';
import appMessages from './i18n/en/appMessages.json';
import { errorAlert } from './services/app/global.service';

TR2.Feedback = Ext.extend(Ext.Window, {
  id: 'feedback',
  title: 'Feedback',
  padding: 10,
  width: 400,
  closeAction: 'show',
  plain: false,
  modal: true,
  border: false,
  frame: false,
  initComponent: function () {
    Ext.apply(this, {
      items: {
        id: 'feedbackform',
        xtype: 'form',
        reference: 'form',
        autoEl: 'form',
        layout: 'form',
        border: false,
        items: [
          {
            id: 'feedback_subject',
            xtype: 'combobox',
            width: 100,
            typeAhead: false,
            triggerAction: 'all',
            lazyRender: true,
            mode: 'local',
            selectOnFocus: true,
            disable: true,
            displayField: 'label',
            valueField: 'label',
            fieldLabel: 'Subject',
            editable: false,
            style: {
              zIndex: 2,
            },
            store: new Ext.data.ArrayStore({
              fields: ['label'],
              data: [
                ['Software problem'],
                ['General comment'],
                ['Point of improvement'],
                ['Account problem'],
              ],
            }),
          },
          {
            id: 'feedback_content',
            xtype: 'textarea',
            fieldLabel: 'Feedback',
            inputType: 'text',
            allowBlank: false,
          },
        ],
      },
      buttons: [
        {
          disabled: false,
          id: 'feedbackSubmit',
          formBind: true,
          text: 'Ok',
          handler: function (): void {
            const feedbackForm = Ext.getCmp('feedbackform');
            const feedbackSubmit = Ext.getCmp('feedbackSubmit');
            const feedbackSubject = Ext.getCmp('feedback_subject').getValue();
            const feedbackContent = Ext.getCmp('feedback_content').getValue();

            feedbackSubmit.setDisabled(true);
            const isNotEmpty = feedbackSubject !== '' && feedbackContent !== '';
            if (isNotEmpty) {
              ApiService.sendFeedback({
                subject: feedbackSubject,
                feedback: feedbackContent,
              })
                .then((send: any) => {
                  if (send.data.success) {
                    Ext.Msg.alert(
                      appMessages.feedbackForm.title,
                      appMessages.feedbackForm.successMessage,
                    );
                    TR2.feedback.hide();
                    feedbackForm.reset();
                    feedbackSubmit.setDisabled(false);
                  } else {
                    feedbackSubmit.setDisabled(false);
                    errorAlert(
                      appMessages.feedbackForm.title,
                      appMessages.feedbackForm.errorMessage,
                    );
                  }
                })
                .catch(() => {
                  feedbackSubmit.setDisabled(false);
                  errorAlert(
                    appMessages.feedbackForm.title,
                    appMessages.serverError,
                  );
                });
            } else {
              feedbackSubmit.setDisabled(false);
              errorAlert(
                appMessages.feedbackForm.title,
                appMessages.feedbackForm.requiredFields,
              );
            }
          },
        },
        {
          text: 'Cancel',
          handler: function (): void {
            const feedbackForm = Ext.getCmp('feedbackform');
            feedbackForm.reset();
            TR2.feedback.hide();
          },
        },
      ],
    });

    TR2.Feedback.superclass.initComponent.apply(this, arguments);
  },
});

Ext.define('tr2-feedback', TR2.Feedback);
