import Element from './element';
import LatentFailure from './latentFailure';

export default class BlockElement extends Element {
  static width = 160;
  static height = 100;

  constructor(
    x: number,
    y: number,
    guid = '',
    w: number,
    h: number,
    svg?: string,
  ) {
    super(x, y, guid, w, h);

    if (svg) {
      this.d2dElement.setSVG(svg);
    } else {
      this.d2dElement.setSVG(
        `<svg width="${w}" height="${h}" xmlns="http://www.w3.org/2000/svg">` +
          '<path fill="none" stroke="#000000" d="M157.39,0C163.55,0,168.55,5,168.55,11.16C168.55,28.93,168.55,71.07,168.55,88.84C168.55,95,163.55,100,157.39,100C125.91,100,42.64,100,11.16,100C5,100,0,95,0,88.84C0,71.07,0,28.93,0,11.16C0,5,5,0,11.16,0C42.64,0,125.91,0,157.39,0Z" stroke-width="0" stroke-scale="true" class="element-LatentFailure" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); opacity: 1; fill: rgb(250, 238, 79);" opacity="1" transform="matrix(0.9493,0,0,1,1222.6433,1751.0678)"></path></svg>',
      );
    }

    if (!(this instanceof LatentFailure)) {
      this.addNameField();
    }

    this.addDescriptionField();
    this.addExportTooltip();
  }
}
