import ApiService from './../api.service';
import { Trees } from '../../interfaces/Trees';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { errorAlert, setStatusBar } from './global.service';
import appMessages from '../../i18n/en/appMessages.json';

export const data = Ext.create('Ext.data.TreeStore', {
  id: 'datastore',
  autoSync: true,
  autoLoad: false,
});

function zipFiles(fileName: string, arr: any): void {
  const zip = new JSZip();
  let count = 0;
  arr.forEach((element) => {
    zip.file(`${element.name}`, element.xml, { binary: true });
    ++count;
    if (count === arr.length) {
      zip
        .generateAsync({ type: 'blob' })
        .then((blob) => {
          saveAs(blob, `${fileName}.twm`);
        })
        .catch(() => {
          errorAlert(
            appMessages.app_general_messages.error,
            appMessages.serverError,
          );
        });
    }
  });
}

export function loadExplorer(caseId?: number): void {
  let rootNode, resultData, groupItem, caseItem, treeItem;
  ApiService.getTrees()
    .then((result: Trees) => {
      rootNode = {
        nodeType: 'async',
        name: 'TR2',
        id: 'root',
        expanded: true,
        editable: true,
        children: [],
      };
      resultData = result;
      resultData.forEach((groupData) => {
        groupItem = {
          id: `group-${groupData.id}`,
          groupDataID: groupData.id,
          name: groupData.name,
          icon: '/ext/appimages/database_gear.png',
          expanded: true,
          children: [],
        };

        groupData.projects.forEach((caseData) => {
          caseItem = {
            id: `extModel-${caseData.id}`,
            caseDataID: caseData.id,
            name: caseData.name,
            icon: '/ext/appimages/briefcase.png',
            type: 'case',
            expanded: caseData.id === caseId,
            children: [],
          };

          caseData.trees.forEach((treeData) => {
            if (treeData.bowtie) {
              treeItem = {
                id: treeData.id,
                treeId: treeData.id,
                name: treeData.name,
                bowtie: treeData.bowtie,
                icon: '/ext/appimages/bowtie.png',
                type: 'tree',
                leaf: true,
                xml: treeData.xml,
              };
              caseItem.children.push(treeItem);
              return;
            }
            treeItem = {
              id: treeData.id,
              treeId: treeData.id,
              name: treeData.name,
              bowtie: treeData.bowtie,
              icon: '/ext/appimages/chart_organisation.png',
              type: 'tree',
              leaf: true,
              xml: treeData.xml,
            };
            caseItem.children.push(treeItem);
          });

          groupItem.children.push(caseItem);
        });

        rootNode.children.push(groupItem);
      });
      data.setRootNode(rootNode);
    })
    .catch(() => {
      errorAlert(
        appMessages.app_general_messages.error,
        appMessages.expiredLicense,
      );
    });
}

export function showHideLoadingMask(comp: Ext): void {
  const showMask = new Ext.LoadMask({
    msg: 'Loading...',
    target: comp,
  });
  showMask.show();
  setTimeout(() => {
    showMask.hide();
  }, 2000);
}

export function showLoadingMask(comp: Ext): void {
  const showMask = new Ext.LoadMask({
    msg: 'Loading...',
    target: comp,
  });
  showMask.show();
  return showMask;
}

export function hideLoadingMask(mask: any): void {
  mask.hide();
}

export function promptWindow(
  promptWindow: boolean,
  title: string,
  id: string,
  buttons: any,
  fn: any,
): void {
  return Ext.MessageBox.show({
    prompt: promptWindow,
    title,
    id,
    buttons,
    fn,
  });
}

export function downloadCase(parentId: number): void {
  let selectedCase, fileName;
  ApiService.getCase(parentId)
    .then((result: Trees) => {
      selectedCase = result;
      fileName = result.name;
      if (selectedCase.trees.length === 0) {
        errorAlert(appMessages.case.name, appMessages.case.no_trees);
        return;
      }
      zipFiles(fileName, selectedCase.trees);
    })
    .catch(() => {
      errorAlert(
        appMessages.app_general_messages.error,
        appMessages.serverError,
      );
    });
}

export function downloadTree(parentId: number, treeId: number): void {
  let fileName, excludeFEFileName;
  ApiService.getTree(parentId, +treeId)
    .then((result: Trees) => {
      const trees: any[] = [];
      fileName = result.name;
      excludeFEFileName = fileName.includes('.xml')
        ? fileName.substring(0, fileName.indexOf('.xml'))
        : fileName;
      trees.push(result);
      zipFiles(excludeFEFileName, trees);
    })
    .catch(() => {
      errorAlert(
        appMessages.app_general_messages.error,
        appMessages.serverError,
      );
    });
}

function duplicateApiCall(caseId, treeId, loadingComponent): void {
  let copyData: Trees = {};
  ApiService.getTree(caseId, treeId)
    .then((result: Trees) => {
      const { name, bowtie, xml } = result;
      copyData = {
        name,
        bowtie,
        xml,
      };
      showHideLoadingMask(loadingComponent);
      ApiService.createTree(caseId, copyData)
        .then((result: Trees) => {
          const { name } = result;
          loadExplorer(caseId);
          setStatusBar(
            `${appMessages.app_general_messages.duplication_message}: ${name}`,
          );
        })
        .catch(() =>
          errorAlert(
            appMessages.app_general_messages.error,
            appMessages.serverError,
          ),
        );
    })
    .catch(() =>
      errorAlert(
        appMessages.app_general_messages.error,
        appMessages.serverError,
      ),
    );
}

export function duplicate(
  bowtie: boolean,
  caseId: number,
  treeId: number,
  loadingComponent?: any,
): void {
  if (!bowtie) {
    duplicateApiCall(caseId, treeId, loadingComponent);
  } else {
    duplicateApiCall(caseId, treeId, loadingComponent);
  }
}

export function deleteCase(loadingComponent: any, projectId: number): void {
  ApiService.deleteCase(projectId!)
    .then((result) => {
      if (!result.success) return;
      showHideLoadingMask(loadingComponent);
      loadExplorer();
    })
    .catch(() => {
      errorAlert(
        appMessages.app_general_messages.error,
        appMessages.serverError,
      );
    });
}

export function deleteTree(
  loadingComponent: any,
  projectId: number,
  treeId: number,
): void {
  ApiService.deleteTree(projectId!, treeId!)
    .then((result) => {
      if (!result.success) return;
      showHideLoadingMask(loadingComponent);
      loadExplorer(projectId);
    })
    .catch(() => {
      errorAlert(
        appMessages.app_general_messages.error,
        appMessages.serverError,
      );
    });
}

export function loadCasesInExplorer(parentId: number, treeId: number): void {
  window.canvas.loadFromUrl(parentId, treeId);
  Ext.getCmp('actions').store.clearFilter();
  Ext.getCmp('actions').store.filterBy(window.elementsFilter, {
    value: 1 + 262144,
  });
  Ext.getCmp('figure').store.clearFilter();
  Ext.getCmp('figure').store.filterBy(window.elementsFilter, {
    value: 0,
  });
  setStatusBar(appMessages.tree.loaded);
}
