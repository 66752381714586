import draw2d from 'draw2d';

import BlockElement from './blockElement';
import ContextMenu from "../ui/ContextMenu";

export default class EventTarget extends BlockElement {
  static get _className(): string {
    return 'EventTarget';
  }
  public _className = 'EventTarget';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(
      x,
      y,
      guid,
      w,
      h,
      '<svg width="160" height="100" xmlns="http://www.w3.org/2000/svg"><path d="M157.39,0H84.28V100h73.11a11.16,11.16,0,0,0,11.16-11.16V11.16A11.16,11.16,0,0,0,157.39,0Z"/><path d="M11.16,0H84.28V100H11.16A11.16,11.16,0,0,1,0,88.84V11.16A11.16,11.16,0,0,1,11.16,0Z"/></svg>',
    );

    this.d2dElement.addPort(
      new draw2d.InputPort(),
      new draw2d.layout.locator.RightLocator(),
    );
    this.d2dElement.addPort(
      new draw2d.OutputPort(),
      new draw2d.layout.locator.LeftLocator(),
    );
    this.setFillColor('#D33931', 1);
    this.setFillColor('#79D327');
  }

  showContextMenu(x: number, y: number) {
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);
    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.addElementFactory('event');
    this.elementContextMenu.addElementFactory('target');
    this.elementContextMenu.addElementFactory('hazard');
    this.elementContextMenu.convertElement(this, 'event');
    this.elementContextMenu.addBarrierFactory('effective barrier');
    this.elementContextMenu.addBarrierFactory('failed barrier');
    this.elementContextMenu.addBarrierFactory('missing barrier');
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
