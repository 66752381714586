/* eslint-disable prettier/prettier */
import 'jquery-layout/jquery.layout';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';

import './app/commands/CommandMove';
import './app/commands/CommandDelete';
import './app/commands/CommandAdd';
import './app/commands/CommandConvert';
import './app/commands/CommandInsert';
import './app/tr2';
import './app/tr2toolbar';
import './app/explorer';
import './app/cases';
import './app/actions';
import './app/content-tbar';
import './app/figure-panel';
import './app/content';
import './app/properties';
import './app/password';
import './app/account';
import './app/feedback';
import './app/accumulate';
import './app/case-selector';
import './app/case-word-selector';
import './app/about';
import './app/import';
import './app/login';
import './app/dashboard';
