import ElementFactory from '../../utils/ElementFactory';
import Element from '../../elements/element';
import draw2d from 'draw2d';
import BlockElement from '../../elements/blockElement';

export const insertBarrier = function (
  type: string,
  selectedElement: Element,
): void {
  const validTypes = [
    'event',
    'event hazard',
    'event target',
    'failed barrier',
    'inadequate barrier',
    'missing barrier',
    'effective barrier',
  ];
  const parent = selectedElement.parents.find((p) => {
    return validTypes.includes(ElementFactory.findTypeOfElement(p.element));
  });

  if (!parent) {
    return;
  }
  const lerp = parent.connection.lerp(0.5);
  const barrier = ElementFactory.create(type, lerp.x - 80, lerp.y);
  const command = new draw2d.command.CommandInsert(
    window.canvas,
    parent.connection,
    barrier,
    parent.element,
    selectedElement,
  );
  window.canvas.d2dCanvas.getCommandStack().execute(command);
};

export const addElement = function (
  type: string,
  selectedElement: Element | null,
  selectElement = true,
): void {
  const offset = 50;
  const offsetBoundingBox = 80;
  const elementsBoundingBox = window.canvas.getAllElementsBoundingBox();
  const allElements = window.canvas.getAllElements();

  let x = window.canvas.viewportCenterX - BlockElement.width / 2;
  let y = window.canvas.viewportCenterY - BlockElement.height / 2;

  if (
    type === 'event' &&
    window.canvas.isBowtie &&
    window.canvas.getFirstEvent()
  ) {
    throw new Error('Only 1 event is allowed on a bowtie');
  }

  if (selectedElement) {
    const event = window.canvas.getFirstEvent()!;
    const elementHeight = selectedElement.height + offset;
    if (window.canvas.isBowtie && event && event.x < selectedElement.x) {
      x = selectedElement.x + selectedElement.width + offset;
    } else if (
      window.canvas.isBowtie &&
      event &&
      event.x === selectedElement.x &&
      type === 'target'
    ) {
      x = selectedElement.x + selectedElement.width + offset;
    } else {
      x = selectedElement.x - selectedElement.width - offset;
    }

    if (type === 'hazard') {
      const lowestHazard = window.canvas.getLowestPositionedElementOfType(type);

      if (!lowestHazard) {
        y = selectedElement.y - elementHeight;
      } else {
        y = lowestHazard.y + elementHeight;
        x = lowestHazard.x;
      }
    }

    if (type === 'target') {
      const lowestTarget = window.canvas.getLowestPositionedElementOfType(type);

      if (!lowestTarget) {
        y = selectedElement.y - elementHeight;
      } else {
        y = lowestTarget.y + elementHeight;
        x = lowestTarget.x;
      }
    }
  } else {
    if (allElements.length !== 0) {
      x = elementsBoundingBox.x + offsetBoundingBox;
      y = elementsBoundingBox.y + offsetBoundingBox;
    }
  }

  const element = ElementFactory.create(type, x, y);
  window.canvas.add(element, true, selectElement);
  if (selectedElement) {
    window.canvas.connectElements(selectedElement, element);
  }
};

export const addPrependEvent = function (
  type: string,
  selectedElement: Element | null,
  selectElement = true,
): void {
  const offset = 450;
  let x = window.canvas.centerX;
  let y = window.canvas.centerY;
  if (selectedElement) {
    const event = window.canvas.getFirstEvent();
    const elementHeight = selectedElement.height;
    if (window.canvas.isBowtie && event && event.x < selectedElement.x) {
      x = selectedElement.x + selectedElement.width + offset;
    } else {
      x = selectedElement.x - selectedElement.width + offset;
    }
    y =
      elementHeight +
      selectedElement.y -
      selectedElement.numberOfChildren * elementHeight;
  }
  const element = ElementFactory.create(type, x, y);
  window.canvas.add(element, true, selectElement);
  if (selectedElement) {
    window.canvas.connectPrependEvent(selectedElement, element);
  }
};

export const addAppendEvent = (
  type: string,
  selectedElement: Element | null,
  selectElement = true,
): void => {
  const offset = 50;
  let x = window.canvas.centerX;
  let y = window.canvas.centerY;

  if (selectedElement) {
    const elementHeight = selectedElement.height + offset;

    if (window.canvas.isBowtie && type === 'append event') {
      x = selectedElement.x - selectedElement.width - offset;
    } else {
      x = selectedElement.x + selectedElement.width + offset;
    }

    y =
      selectedElement.y -
      elementHeight +
      selectedElement.numberOfChildren * elementHeight;
  }

  const element = ElementFactory.create(type, x, y);
  window.canvas.add(element, true, selectElement);
  if (selectedElement) {
    window.canvas.connectAppendEvent(selectedElement, element);
  }
};

Ext.define('Action', {
  extend: 'Ext.data.Model',
  idProperty: 'idaction',
  fields: [
    { name: 'idaction', type: 'int' },
    { name: 'elementId', type: 'string' },
    { name: 'name', type: 'string' },
    { name: 'type', type: 'string' },
    { name: 'icon', type: 'string' },
  ],
});

export const data = Ext.create('Ext.data.ArrayStore', {
  model: 'Action',
  data: [
    [1, '#event', 'Add event', 'event', 'event.png'],
    [8, '#hazard', 'Add hazard', 'hazard', 'hazard.png'],
    [
      16,
      '#hazardtarget',
      'Add hazard &amp; target',
      'hazard and event',
      'hazard_and_event.png',
    ],
    [32, '#target', 'Add target', 'target', 'target.png'],
    [
      128,
      '#failedbarrier',
      'Insert failed barrier',
      'failed barrier',
      'failed_barrier.png',
    ],
    [
      256,
      '#missingbarrier',
      'Insert missing barrier',
      'missing barrier',
      'missing_barrier.png',
    ],
    [
      2097152,
      '#InadequateBarrier',
      'Insert inadequate berrier',
      'inadequate barrier',
      'inadequate_barrier.png',
    ],
    [
      64,
      '#effectivebarrier',
      'Insert functioning barrier',
      'effective barrier',
      'effective_barrier.png',
    ],
    [
      512,
      '#activefailure',
      'Add active failure',
      'active failure',
      'active_failure.png',
    ],
    [
      1024,
      '#precondition',
      'Add precondition',
      'precondition',
      'precondition.png',
    ],
    [
      2048,
      '#latentfailure',
      'Add latent failure',
      'latent failure',
      'latent_failure.png',
    ],
    [
      4096,
      '#eventarget',
      'Convert to event target',
      'event target',
      'event-target.png',
    ],
    [
      8192,
      '#eventhazard',
      'Convert to event hazard',
      'event hazard',
      'event-hazard.png',
    ],
    [16384, '#target', 'Convert to target', 'target', 'target.png'],
    [32768, '#hazardconvert', 'Convert to hazard', 'hazard', 'hazard.png'],
    [
      65536,
      '#convertfailedbarrier',
      'Convert to failed barrier',
      'failed barrier',
      'failed_barrier.png',
    ],
    [131072, '#convertevent', 'Convert to event', 'event', 'event.png'],
    [262144, '#comment', 'Add comment', 'comment', 'comment.png'],
    [524288, '#prependevent', 'Prepend Event', 'prepend event', 'event.png'],
    [1048576, '#appendevent', 'Append Event', 'append event', 'event.png'],
    [
      2,
      '#converteffectivebarrier',
      'Convert to functioning barrier',
      'effective barrier',
      'effective_barrier.png',
    ],
    [
      4,
      '#convermissingbarrier',
      'Convert to missing barrier',
      'missing barrier',
      'missing_barrier.png',
    ],
    [
      4194304,
      '#convertInadequateBarrier',
      'Convert to inadequate barrier',
      'inadequate barrier',
      'inadequate_barrier.png',
    ],
  ],
});
