const ElementDescription = {
  exportWithDescription: function () {
    const elements = window.canvas
      .getAllElements()
      .sort((a, b) => a.sequence - b.sequence);
    const types = [
      'Hazard',
      'Event',
      'Target',
      'EventHazard',
      'EventTarget',
      'FailedBarrier',
      'InadequateBarrier',
      'MissingBarrier',
      'EffectiveBarrier',
      'ActiveFailure',
      'Precondition',
      'LatentFailure',
      'Comment',
    ];

    const orderedElements: any[] = [];
    types.forEach((type) => {
      elements.forEach((element) => {
        if (element._className !== type) {
          return;
        }
        orderedElements.push(element);
      });
    });
    let sequenceNumber = 0;
    return orderedElements.map((element) => {
      return {
        exportId: ++sequenceNumber,
        element,
        label: element._nameLabel.text,
        description: element.descriptionTooltip.text,
      };
    });
  },
  exportWithNoDescription() {
    return window.canvas.getAllElements().map((element, index) => {
      return {
        label: element._nameLabel.text,
        exportIdTooltip: element.exportIdTooltip,
        elementId: index,
        element,
      };
    });
  },
};
export default ElementDescription;
