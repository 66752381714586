/* eslint-disable @typescript-eslint/ban-ts-ignore */
import draw2d from 'draw2d';
import ConnectionConstraints from '../utils/ConnectionConstraints';

export const DropInterceptorPolicy = draw2d.policy.canvas.DropInterceptorPolicy.extend(
  {
    delegateTarget: function (connectInquirer, connectIntent) {
      connectIntent = this._super(connectInquirer, connectIntent);

      if (connectIntent === null) {
        return null;
      }

      const a = connectInquirer.parent.userData.element;
      const b = connectIntent.parent.userData.element;
      const connectionConstraints = new ConnectionConstraints();
      if (
        connectInquirer instanceof draw2d.Port &&
        connectIntent instanceof draw2d.Port &&
        !connectionConstraints.isAllowed(a, b)
      ) {
        return null;
      }

      return connectIntent;
    },
  },
);
