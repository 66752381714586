import draw2d from 'draw2d';
import extend from '../../../node_modules/draw2d/src/util/extend';

export const CoronaDecorationPolicy = draw2d.policy.canvas.DecorationPolicy.extend(
  {
    NAME: 'draw2d.policy.canvas.CoronaDecorationPolicy',

    /**
     * @constructor
     *
     */
    init: function (attr, setter, getter) {
      this.startDragX = 0;
      this.startDragY = 0;
      this.diameterToBeFullVisible = 0;
      this.diameterToBeVisible = 0;
      this.sumDiameter = 0;

      this._super(
        extend({ diameterToBeVisible: 200, diameterToBeFullVisible: 20 }, attr),

        extend(
          {
            diameterToBeVisible: this.setDiameterToBeVisible,
            diameterToBeFullVisible: this.setDiameterToBeFullVisible,
          },
          setter,
        ),

        extend(
          {
            diameterToBeVisible: this.getDiameterToBeVisible,
            diameterToBeFullVisible: this.getDiameterToBeFullVisible,
          },
          getter,
        ),
      );
    },

    setDiameterToBeVisible: function (diameter) {
      this.diameterToBeVisible = diameter;
      this.sumDiameter =
        this.diameterToBeFullVisible + this.diameterToBeVisible;
    },

    getDiameterToBeVisible: function () {
      return this.diameterToBeVisible;
    },

    setDiameterToBeFullVisible: function (diameter) {
      this.diameterToBeFullVisible = diameter;
      this.sumDiameter =
        this.diameterToBeFullVisible + this.diameterToBeVisible;
    },

    getDiameterToBeFullVisible: function () {
      return this.diameterToBeFullVisible;
    },

    /**
     * @inheritdoc
     */
    onInstall: function (canvas) {
      this._super(canvas);
      canvas.getFigures().each((_i, figure) => {
        figure.getPorts().each((_i, p) => p.setVisible(false));
      });
    },

    /**
     * @inheritdoc
     */
    onUninstall: function (canvas) {
      this._super(canvas);
      canvas.getFigures().each(function (_i, figure) {
        figure.getPorts().each(function (_i, p) {
          if (p.__origAlpha) {
            p.setAlpha(p.__origAlpha);
            delete p.__origAlpha;
          }
          p.setVisible(true);
        });
      });
    },

    /**
     * @inheritdoc
     */
    onMouseDown: function (_canvas, x, y, _shiftKey, _ctrlKey) {
      this.startDragX = x;
      this.startDragY = y;
    },

    /**
     * @inheritdoc
     */
    onMouseMove: function (canvas, x, y, _shiftKey, _ctrlKey) {
      this.updatePorts(canvas, x, y);
    },

    /**
     * @inheritdoc
     */
    onMouseDrag: function (canvas, dx, dy, _dx2, _dy2, _shiftKey, _ctrlKey) {
      this.updatePorts(canvas, this.startDragX + dx, this.startDragY + dy);
    },

    /**
     * @method
     * Update all ports with the new calculated opacity in relation to the distance to the current
     * mouse position
     *
     * @param canvas
     * @param x
     * @param y
     * @private
     */
    updatePorts: function (canvas, x, y) {
      canvas.getFigures().each((_i, figure) => {
        if (figure instanceof draw2d.shape.node.Node) {
          if (
            figure.isVisible() === true &&
            figure.hitTest(x, y, this.sumDiameter) === true
          ) {
            figure.getPorts().each((_i, p) => {
              if (p.isVisible() === false) {
                p.__origAlpha = figure.getAlpha();
              }
              const dist = figure
                .getBoundingBox()
                .getDistance(new draw2d.geo.Point(x, y));
              const alpha =
                1 -
                ((100 /
                  (this.diameterToBeVisible - this.diameterToBeFullVisible)) *
                  dist) /
                  100.0;
              p.setAlpha(alpha);
              p.setVisible(true);
            });
          } else {
            figure.getPorts().each((_i, p) => {
              if (p.__origAlpha) {
                p.setAlpha(p.__origAlpha);
                delete p.__origAlpha;
              }
              p.setVisible(false);
            });
          }
        }
      });
    },
  },
);
