import draw2d from 'draw2d';

export default function (
  sourcePort?: any,
  targetPort?: any,
): draw2d.Connection {
  return new draw2d.Connection({
    router: new draw2d.layout.connection.DirectRouter(),
    stroke: 2,
    color: '#000000',
    radius: 10,
    outlineColor: '#666666',
    source: sourcePort,
    target: targetPort,
  });
}
