import draw2d from 'draw2d';

import BlockElement from './blockElement';
import ContextMenu from '../ui/ContextMenu';

export default class EventHazard extends BlockElement {
  static get _className(): string {
    return 'EventHazard';
  }
  public _className = 'EventHazard';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(
      x,
      y,
      guid,
      w,
      h,
      '<svg width="160" height="100" xmlns="http://www.w3.org/2000/svg"><path d="M116.63,100h29.25L81.22,0H52Z"/><path d="M87.33,100h29.25L51.92,0H22.67Z"/><path d="M58,100H87.28L22.63,0H11.16A11.17,11.17,0,0,0,0,10.31Z"/><path d="M28.74,100H58L0,10.38V55.56Z"/><path d="M0,55.63V88.84A11.16,11.16,0,0,0,11.16,100H28.69Z"/><path d="M110.52,0H81.27l64.65,100h11.47a11.16,11.16,0,0,0,11.12-10.31Z"/><path d="M139.82,0H110.56l57.95,89.62V44.44Z"/><path d="M168.55,44.37V11.16A11.16,11.16,0,0,0,157.39,0H139.86Z"/><path d="M11.16,0H84.28V100H11.16A11.16,11.16,0,0,1,0,88.84H0V11.16A11.16,11.16,0,0,1,11.16,0Z"/></svg>',
    );

    this.d2dElement.addPort(
      new draw2d.InputPort(),
      new draw2d.layout.locator.RightLocator(),
    );
    this.d2dElement.addPort(
      new draw2d.OutputPort(),
      new draw2d.layout.locator.LeftLocator(),
    );
    this.setFillColor('#faee4f', 0);
    this.setFillColor('#22221B', 1);
    this.setFillColor('#faee4f', 2);
    this.setFillColor('#22221B', 3);
    this.setFillColor('#faee4f', 4);
    this.setFillColor('#22221B', 5);
    this.setFillColor('#faee4f', 6);
    this.setFillColor('#22221B', 7);
    this.setFillColor('#D33931', 8);
  }

  showContextMenu(x: number, y: number) {
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);
    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.addElementFactory('event');
    this.elementContextMenu.addElementFactory('target');
    this.elementContextMenu.addElementFactory('hazard');
    this.elementContextMenu.convertElement(this, 'event');
    this.elementContextMenu.addBarrierFactory('effective barrier');
    this.elementContextMenu.addBarrierFactory('failed barrier');
    this.elementContextMenu.addBarrierFactory('missing barrier');
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
