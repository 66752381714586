const TOKEN_KEY = 'accessToken';

const TokenService = {
  getToken() {
    return sessionStorage.getItem(TOKEN_KEY);
  },

  setToken(accessToken) {
    sessionStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    sessionStorage.removeItem(TOKEN_KEY);
  },

  hasToken() {
    return !!this.getToken();
  },
};

export default TokenService;
