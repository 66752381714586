import ApiService from './services/api.service';
import TokenService from './services/token.service';

const parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

Ext.define('TR2.Application', {
  extend: 'Ext.app.Application',
  name: 'TR2',
});

Ext.application({
  name: 'TR2',
  launch: function () {
    const cookie = parseCookie(document.cookie);
    if (cookie.token && !TokenService.getToken()) {
      window.history.pushState({}, '', '/');
      TokenService.setToken(cookie.token);
      ApiService.getMe().then((response) => {
        const { trial, validUntil } = response.license;
        const licenseObject = {
          trial,
          validUntil,
        };
        sessionStorage.setItem('loggedInUser', String(response.id));
        sessionStorage.setItem('license', JSON.stringify(licenseObject));
      });
    }
    const loggedIn = sessionStorage.getItem('accessToken');
    Ext.create({
      xtype: loggedIn ? 'tr2-dashboard' : 'tr2-login',
    });
    Ext.get('mask').hide();
  },
});
