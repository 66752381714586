import draw2d from 'draw2d';

import BlockElement from './blockElement';
import ContextMenu from "../ui/ContextMenu";

export default class Precondition extends BlockElement {
  static get _className(): string {
    return 'Precondition';
  }
  public _className = 'Precondition';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(x, y, guid, w, h);

    const inputPort = new draw2d.InputPort();
    inputPort.on('connect', (_emitter, { connection }) => {
      connection.setDashArray('-');
    });

    if (this.isRightElement()) {
      this.d2dElement.addPort(
        inputPort,
        new draw2d.layout.locator.LeftLocator(),
      );
      this.d2dElement.addPort(
        new draw2d.OutputPort(),
        new draw2d.layout.locator.RightLocator(),
      );
    } else {
      this.d2dElement.addPort(
        inputPort,
        new draw2d.layout.locator.RightLocator(),
      );
      this.d2dElement.addPort(
        new draw2d.OutputPort(),
        new draw2d.layout.locator.LeftLocator(),
      );
    }
    this.setFillColor('#38a4fb');
  }

  showContextMenu(x: number, y: number): void {
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);

    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
