import ElementFactory from '../../utils/ElementFactory';

window.elementsFilter = function (_rec): boolean {
  return !!(_rec.id & (this as any).value);
};

window.trackEvent = function (data: any): void {
  if (window._gaq !== undefined) {
    window._gaq.push(data);
  }
};

window.onNoElementsSelected = function (): void {
  const c = Ext.getCmp('actions');
  const f = Ext.getCmp('figure');
  const firstEvent = window.canvas.getFirstEvent();

  if (window.canvas.isBowtie && firstEvent) {
    c.store.filterBy(window.elementsFilter, { value: 262144 });
  } else {
    c.store.filterBy(window.elementsFilter, { value: 1 + 262144 });
  }

  f.store.filterBy(window.elementsFilter, { value: 0 });
  Ext.getCmp('description').disable().setValue('');
  Ext.getCmp('font-size').disable();
  Ext.getCmp('submit').disable();
};

window.onMultipleElementsSelected = function (): void {
  const c = Ext.getCmp('actions');
  c.store.filterBy(window.elementsFilter, { value: 0 });
  Ext.getCmp('description').disable().setValue('');
  Ext.getCmp('font-size').disable();
  Ext.getCmp('submit').disable();
};

window.onOneElementSelected = function (): void {
  const element = window.canvas.getSingleSelectedElement();
  if (!element) {
    return;
  }

  Ext.getCmp('description').enable().setValue(element.description);
  Ext.getCmp('font-size').enable().setValue(element.style.fontSize);
  Ext.getCmp('submit').enable();

  const c = Ext.getCmp('actions');
  const f = Ext.getCmp('figure');
  switch (ElementFactory.findTypeOfElement(element)) {
    case 'comment':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'comment',
        value: 0,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'event':
      if (window.canvas.isBowtie) {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'event',
          value: 32 + 8,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
        return;
      }
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'event',
        value: 8192 + 4096 + 32 + 16 + 8,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'event target':
      if (element.numberOfParents > 0) {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'event target 1',
          value: 2097152 + 16384 + 256 + 128 + 64 + 32 + 16 + 8,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
      } else {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'event target 2',
          value: 1048576 + 131072 + 32 + 16 + 8,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
      }
      break;
    case 'event hazard':
      if (element.numberOfParents > 0) {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'event hazard 1',
          value: 2097152 + 524288 + 32768 + 256 + 128 + 64 + 32 + 16 + 8,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
      } else {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'event hazard 2',
          value: 1048576 + 131072 + 32 + 16 + 8,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
      }
      break;
    case 'hazard':
      if (window.canvas.isBowtie) {
        if (element.numberOfParents === 0) {
          c.store.clearFilter();
          c.store.filterBy(window.elementsFilter, {
            name: 'hazard',
            value: null,
          });

          return;
        }

        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'hazard 1',
          value: 2097152 + 256 + 128 + 64,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
        return;
      }

      if (element.numberOfParents > 0) {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'hazard 1',
          value: 2097152 + 8192 + 256 + 128 + 64,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
      } else {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'hazard 2',
          value: 8192,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
      }
      break;
    case 'target':
      if (window.canvas.isBowtie) {
        if (element.numberOfParents === 0) {
          c.store.clearFilter();
          c.store.filterBy(window.elementsFilter, {
            name: 'target 1',
            value: null,
          });
          f.store.clearFilter();
          f.store.filterBy(window.elementsFilter, {
            name: 'delete',
            value: 100,
          });
          return;
        }

        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'target 1',
          value: 2097152 + 256 + 128 + 64,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
        return;
      }

      if (element.numberOfParents > 0) {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'target 1',
          value: 2097152 + 4096 + 256 + 128 + 64,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
      } else {
        c.store.clearFilter();
        c.store.filterBy(window.elementsFilter, {
          name: 'target 2',
          value: 4096,
        });
        f.store.clearFilter();
        f.store.filterBy(window.elementsFilter, {
          name: 'delete',
          value: 100,
        });
      }
      break;
    case 'active failure':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'active failure',
        value: 1024,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'precondition':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'precondition',
        value: 2048,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'latent failure':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'latent failure',
        value: 0,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'missing barrier':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'missing barrier',
        value: 2048 + 2 + 65536 + 4194304,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'failed barrier':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'failed barrier',
        value:
          element.numberOfChildren < 2
            ? 4194304 + 512 + 4 + 2
            : 4194304 + 4 + 2,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'inadequate barrier':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'inadequate barrier',
        value:
          element.numberOfChildren < 2 ? 65536 + 512 + 4 + 2 : 65536 + 4 + 2,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'effective barrier':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'effective barrier',
        value: 4 + 65536 + 4194304,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    case 'prepend event':
      c.store.clearFilter();
      c.store.filterBy(window.elementsFilter, {
        name: 'prepend event',
        value: 8192 + 4096 + 32 + 16 + 8,
      });
      f.store.clearFilter();
      f.store.filterBy(window.elementsFilter, {
        name: 'delete',
        value: 100,
      });
      break;
    default:
  }
};
