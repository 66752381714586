import draw2d from 'draw2d';
import BlockElement from './blockElement';
import ContextMenu from '../ui/ContextMenu';

export default class Hazard extends BlockElement {
  static get _className(): string {
    return 'Hazard';
  }
  public _className = 'Hazard';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(
      x,
      y,
      guid,
      w,
      h,
      '<svg width="160" height="100" xmlns="http://www.w3.org/2000/svg"><path class="cls-1" d="M116.63,100h29.25L81.22,0H52Z"/><path d="M87.33,100h29.25L51.92,0H22.67Z"/><path class="cls-1" d="M58,100H87.28L22.63,0H11.16A11.17,11.17,0,0,0,0,10.31Z"/><path d="M28.74,100H58L0,10.38c0,.26,0,.52,0,.78v44.4Z"/><path class="cls-1" d="M0,55.63V88.84A11.16,11.16,0,0,0,11.16,100H28.69Z"/><path d="M110.52,0H81.27l64.65,100h11.47a11.16,11.16,0,0,0,11.12-10.31Z"/><path class="cls-1" d="M139.82,0H110.56l57.95,89.62c0-.26,0-.52,0-.78V44.44Z"/><path d="M168.55,44.37V11.16A11.16,11.16,0,0,0,157.39,0H139.86Z"/></svg>',
    );

    if (window.canvas.isBowtie) {
      this.d2dElement.addPort(
        new draw2d.InputPort(),
        new draw2d.layout.locator.RightLocator(),
      );
    } else {
      if (this.isRightElement()) {
        this.d2dElement.addPort(
          new draw2d.InputPort(),
          new draw2d.layout.locator.LeftLocator(),
        );
      } else {
        this.d2dElement.addPort(
          new draw2d.InputPort(),
          new draw2d.layout.locator.RightLocator(),
        );
      }
    }
    this.setFillColor('#22221B', 0);
    this.setFillColor('#faee4f', 1);
    this.setFillColor('#22221B', 2);
    this.setFillColor('#faee4f', 3);
    this.setFillColor('#22221B', 4);
    this.setFillColor('#faee4f', 5);
    this.setFillColor('#22221B', 6);
    this.setFillColor('#faee4f', 7);
  }

  showContextMenu(x: number, y: number) {
    const hasEvent = !!window.canvas.getFirstEvent();
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);
    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    if (window.canvas.isBowtie) {
      this.elementContextMenu.titleFactory(
        contextMenuTitle.element,
        contextMenuTitle.id,
      );
      if (!hasEvent) {
        this.elementContextMenu.addElementFactory('event');
      }
      this.elementContextMenu.addElementFactory('target');
      this.elementContextMenu.addElementFactory('hazard');
      this.elementContextMenu.addBarrierFactory('effective barrier');
      this.elementContextMenu.addBarrierFactory('failed barrier');
      this.elementContextMenu.addBarrierFactory('missing barrier');
      this.elementContextMenu.defaultElementFactory(this);
      this.elementContextMenu.show(x, y);
      return;
    }
    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.addElementFactory('event');
    this.elementContextMenu.addElementFactory('target');
    this.elementContextMenu.addElementFactory('hazard');
    this.elementContextMenu.convertElement(this, 'event hazard');
    this.elementContextMenu.addBarrierFactory('effective barrier');
    this.elementContextMenu.addBarrierFactory('failed barrier');
    this.elementContextMenu.addBarrierFactory('missing barrier');
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
