import draw2d from 'draw2d';
import { setStatusBar } from './services/app/global.service';
import appMessages from './i18n/en/appMessages.json';

const deleteMultipleElements = function (): void {
  window.canvas.getAllSelectedElements().forEach(function (element) {
    window.canvas.d2dCanvas
      .getCommandStack()
      .execute(new draw2d.command.CommandDelete(element.d2dElement));
  });
  Ext.getCmp('statusbar').setStatus('Deleted');
};

Ext.define('FigureAction', {
  extend: 'Ext.data.Model',
  idProperty: 'idedit',
  fields: [
    { name: 'idedit', type: 'int' },
    { name: 'name', type: 'string' },
    { name: 'type', type: 'string' },
    { name: 'icon', type: 'string' },
  ],
});

const figureStore = Ext.create('Ext.data.ArrayStore', {
  model: 'FigureAction',
  data: [[100, 'Delete selected item(s)', 'delete', 'fas fa-times-circle']],
});

const FigureToolbar = {
  extend: 'Ext.ListView',
  xtype: 'tr2-figurebar',
  id: 'figureactions',
  columns: [
    {
      xtype: 'templatecolumn',
      header: 'Name',
      width: '100%',
      style: {
        border: 'none',
      },
      tpl: `<i id="deleteAction" class="{icon}"></i> {name}`,
      dataIndex: 'name',
    },
  ],
  store: figureStore,
  listeners: {
    select: function (
      el: { getStore: () => any },
      _record: any,
      index: any,
    ): void {
      const store = el.getStore();
      const record = store.getAt(index);
      const selectedElement = window.canvas.getSingleSelectedElement();
      try {
        switch (record.data.idedit) {
          case 100:
            if (selectedElement) {
              deleteMultipleElements();
              setStatusBar(appMessages.app_general_messages.ready);
            }
            break;
          default:
        }
      } catch (e) {
        setStatusBar(appMessages.app_general_messages.general_error);
      }
    },
  },
};

Ext.define('tr2-figurebar', FigureToolbar);
