import draw2d from 'draw2d';

import BlockElement from './blockElement';
import LatentFailureLabelSelect from '../ui/latentFailureLabelSelect';
import ContextMenu from "../ui/ContextMenu";
import { debounce } from 'lodash-es';
import { LatentFailureSelectLocator } from '../locators/LatentFailureSelectLocator';

export default class LatentFailure extends BlockElement {
  static get _className(): string {
    return 'LatentFailure';
  }
  public _className = 'LatentFailure';

  _label = 0;
  public _labelElement: draw2d.shape.basic.Label;
  public _selectElement: draw2d.shape.basic.Rectangle;

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(x, y, guid, w, h);

    if (this.isRightElement()) {
      this.d2dElement.addPort(
        new draw2d.InputPort(),
        new draw2d.layout.locator.LeftLocator(),
      );
    } else {
      this.d2dElement.addPort(
        new draw2d.InputPort(),
        new draw2d.layout.locator.RightLocator(),
      );
    }

    this.setFillColor('#faee4f');
    this.addNameField(140, 50, 10, 10, 5);
    this.addSelect();

    this._d2dElement.on(
      'resize',
      debounce(() => {
        this.resizeSelectElement();

        this._labelRectangle.setRadius(this.radius);
        this._nameLabel.setRadius(this.radius);
      }, 100),
    );

    this._d2dElement.on('dragend', () => {
      this.resizeSelectElement();
    });

    setTimeout(() => {
      this.resizeSelectElement();
    }, 100);
  }

  resizeSelectElement(): void {
    this._selectElement.setWidth(
      this._d2dElement.getWidth() - (this.radius * 2),
    );
  }

  addSelect(): void {
    const selectRectangle = new draw2d.shape.basic.Rectangle({
      bgColor: '#FFFFFF',
      alpha: 0.9,
      width: this._d2dElement.getWidth(),
      height: 20,
      radius: 5,
      stroke: 0,
    });

    this.d2dElement.add(selectRectangle, new LatentFailureSelectLocator());

    this._labelElement = new draw2d.shape.basic.Label({
      text: 'Select an option ▼',
      stroke: 0,
      fontColor: '#b9b9b9',
    });

    selectRectangle.add(
      this._labelElement,
      new draw2d.layout.locator.CenterLocator(),
    );

    const showSelectOptions = () => {
      LatentFailureLabelSelect.show(this._label).then((selected) => {
        this.setLabelText(
          selected ? LatentFailureLabelSelect.getTextByKey(selected) : '',
        );
        this._label = selected;
      });
    };

    this._labelElement.on('click', showSelectOptions);

    this._selectElement = selectRectangle;

    this.resizeSelectElement();
  }

  setLabelText(text: string): void {
    if (text !== '') {
      this._labelElement.setText(text + ' ▼');
      this._labelElement.setFontColor('#0d0d0d');
    } else {
      this._labelElement.setText('Select an option ▼');
      this._labelElement.setFontColor('#b9b9b9');
    }
  }

  get label(): number {
    return this._label;
  }

  set label(value) {
    this._label = value;
    this.setLabelText(LatentFailureLabelSelect.getTextByKey(value));
  }

  showContextMenu(x: number, y: number): void {
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);

    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }

  get heightWithGutter(): number {
    return super.heightWithGutter - 30;
  }
}
