import draw2d from 'draw2d';

draw2d.command.CommandMove = draw2d.command.Command.extend({
  NAME: 'draw2d.command.CommandMove',

  init: function (figure, x, y) {
    this._super(draw2d.Configuration.i18n.command.moveShape);
    this.figure = figure;
    this.dragSubtree = window.canvas.dragSubtree;
    if (typeof x === 'undefined') {
      this.oldX = figure.getX();
      this.oldY = figure.getY();
    } else {
      this.oldX = x;
      this.oldY = y;
    }
  },

  setStartPosition: function (x, y) {
    this.oldX = x;
    this.oldY = y;
  },

  setPosition: function (x, y) {
    this.newX = x;
    this.newY = y;
  },

  canExecute: function () {
    return this.newX !== this.oldX || this.newY !== this.oldY;
  },

  execute: function () {
    this.redo(true);
  },

  undo: function () {
    this.figure.setPosition(this.oldX, this.oldY);
    if (this.dragSubtree) {
      const dx = this.newX - this.oldX;
      const dy = this.newY - this.oldY;
      this.figure.userData.element.moveSubtree(-dx, -dy);
    }
  },

  redo: function (isExecute = false) {
    this.figure.setPosition(this.newX, this.newY);
    if (this.dragSubtree && !isExecute) {
      const dx = this.newX - this.oldX;
      const dy = this.newY - this.oldY;
      this.figure.userData.element.moveSubtree(dx, dy);
    }
  },
});
