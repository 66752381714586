import ApiService from './services/api.service';
import TokenService from './services/token.service';
import { GenerateReport } from './interfaces/GenerateReport';
import {
  data,
  loadCases,
  addNumbersToArray,
  convertFile,
  saveFile,
} from './services/app/case-selector.service';
import { errorAlert } from './services/app/global.service';
import appMessages from '../app/i18n/en/appMessages.json';

if (TokenService.hasToken()) {
  loadCases(data);
}

TR2.Accumulate = Ext.extend(Ext.Window, {
  id: 'accumulate',
  xtype: 'tr2-accumulate',
  title: 'Basic Risk Factor Reports',
  width: 300,
  height: 300,
  modal: true,
  plain: true,
  border: false,
  closeAction: 'hide',
  listeners: {
    close: function (win): void {
      win.hide();
    },
    render: function (): void {
      loadCases(data);
    },
  },
  initComponent: function () {
    Ext.apply(this, {
      layout: 'fit',
      items: [
        {
          xtype: 'treepanel',
          useArrows: true,
          autoScroll: true,
          stateful: true,
          border: false,
          containerScroll: true,
          autoLoad: false,
          rootVisible: false,
          waitMsg: 'Loading...',
          store: data,
          columns: [
            {
              xtype: 'treecolumn',
              itemId: 'header',
              flex: 2,
              sortable: true,
              dataIndex: 'name',
              editable: true,
            },
          ],
        },
      ],
      buttons: [
        {
          text: 'Ok',
          handler: function (): void {
            const element = (this as any).items.get(0);
            const checked = element.getChecked();
            const selectedIds: number[] = [];
            addNumbersToArray(checked, selectedIds);

            const allSelectedItems: GenerateReport = {
              trees: selectedIds,
            };

            ApiService.generateReport(allSelectedItems)
              .then((result: GenerateReport): void => {
                const { report } = result;
                if (report === undefined) return;
                const convertedReport = convertFile(report);
                saveFile(convertedReport);
                (this as any).hide();
              })
              .catch(() => {
                errorAlert(
                  appMessages.app_general_messages.error,
                  appMessages.serverError,
                );
              });
          },
          scope: this,
        },
        {
          text: 'Cancel',
          handler: function (): void {
            (this as any).hide();
          },
          scope: this,
        },
      ],
    });
    TR2.Accumulate.superclass.initComponent.apply(this, arguments);
  },
});

Ext.define('tr2-accumulate', TR2.Accumulate);
