import TokenService from './services/token.service';
import ApiService from './services/api.service';
import { Trees } from './interfaces/Trees';
import {
  loadExplorer,
  promptWindow,
  showHideLoadingMask,
  downloadCase,
  downloadTree,
  duplicate,
} from './services/app/cases.service';
import {
  messageConfirmBox,
  setStatusBar,
  errorAlert,
  hasNetwork,
  showMessage,
} from './services/app/global.service';
import appMessages from './i18n/en/appMessages.json';

let caseComp, element, type, caseDataID, parentId, currentTreeId, items;

TR2.Toolbar = {
  extend: 'Ext.Toolbar',
  xtype: 'tr2-toolbar',
  listeners: {
    afterRender: function (): void {
      const license = sessionStorage.getItem('license')!;
      const userLicense = JSON.parse(license);
      const allMenuItems = [
        Ext.getCmp('import_export'),
        Ext.getCmp('tree_export'),
        Ext.getCmp('word_export'),
        Ext.getCmp('case_export'),
        Ext.getCmp('export_pdf'),
        Ext.getCmp('export_image'),
        Ext.getCmp('file_import'),
        Ext.getCmp('export_image_transparent'),
      ];
      if (userLicense === null) return;
      if (userLicense.trial) {
        allMenuItems.forEach((menuItem) => menuItem.setDisabled(true));
        return;
      }
    },
  },
  items: [
    {
      text: appMessages.menu.file.name,
      menu: {
        xtype: 'menu',
        items: [
          {
            iconCls: 'x-fa fa-save',
            text: appMessages.menu.file.items.save,
            handler(): void {
              window.trackEvent(['_trackEvent', 'Toolbar', 'File', 'Save']);
              try {
                window.canvas.save().then(() => {
                  setStatusBar(appMessages.tree.saved);
                });
              } catch (e) {
                setStatusBar(appMessages.tree.error + e);
              }
            },
          },
          {
            iconCls: 'x-fa fa-print',
            text: appMessages.menu.file.items.print,
            handler(): void {
              window.trackEvent(['_trackEvent', 'Toolbar', 'File', 'Print']);
              window.canvas.exportToPDF();
            },
          },
          '-',
          {
            iconCls: 'x-fa fa-plus',
            text: appMessages.menu.file.items.new_case,
            handler(): void {
              window.trackEvent(['_trackEvent', 'Toolbar', 'File', 'New case']);
              try {
                caseComp = Ext.getCmp('cases');
                promptWindow(
                  true,
                  'New case',
                  'new-case',
                  Ext.Msg.OK,
                  function (btn, value) {
                    if (btn === 'ok') {
                      showHideLoadingMask(caseComp);
                      ApiService.createCase({
                        name: value,
                      })
                        .then((result) => {
                          setStatusBar(appMessages.case.saved + result.name);
                          loadExplorer();
                        })
                        .catch(() => {
                          errorAlert(
                            appMessages.app_general_messages.error,
                            appMessages.serverError,
                          );
                        });
                    }
                  },
                );
              } catch (e) {
                setStatusBar(appMessages.case.error);
              }
            },
          },
          '-',
          {
            iconCls: 'x-fa fa-sitemap',
            text: appMessages.menu.file.items.new_tree,
            id: 'new_tree',
            disabled: true,
            handler(): void {
              window.trackEvent([
                '_trackEvent',
                'Toolbar',
                'File',
                'New Tripod tree',
              ]);
              try {
                caseComp = Ext.getCmp('cases');
                element = Ext.getCmp('cases').getSelectionModel();
                element.selected.items.forEach(function (element) {
                  type = element.data;
                  if (element.data.caseDataID) {
                    caseDataID = element.data.caseDataID;
                  }
                  parentId = element.parentNode.data.caseDataID;
                });
                if (type.type !== 'case' && type.type !== 'tree') {
                  setStatusBar(appMessages.app_general_messages.no_selection);
                  errorAlert(
                    appMessages.app_general_messages.error,
                    appMessages.app_general_messages.no_selection,
                  );
                  return;
                }
                if (caseDataID) {
                  Ext.getCmp('new_tree').setDisabled(false);
                  promptWindow(
                    true,
                    'New tree',
                    'new-tree',
                    Ext.Msg.OK,
                    function (btn, value) {
                      if (btn === 'ok') {
                        showHideLoadingMask(caseComp);
                        ApiService.createTree(caseDataID, {
                          name: value,
                          xml: ApiService.saveXML(value, false),
                        })
                          .then((result) => {
                            loadExplorer(caseDataID);
                            setStatusBar(
                              `${appMessages.tree.saved} ${result.name}`,
                            );
                          })
                          .catch(() => {
                            errorAlert(
                              appMessages.app_general_messages.error,
                              appMessages.serverError,
                            );
                          });
                      }
                    },
                  );
                }
                if (parentId) {
                  promptWindow(
                    true,
                    'New tree',
                    'new-tree',
                    Ext.Msg.OK,
                    function (btn, value) {
                      if (btn === 'ok') {
                        showHideLoadingMask(caseComp);
                        ApiService.createTree(parentId, {
                          name: value,
                          xml: ApiService.saveXML(value, false),
                        })
                          .then((result) => {
                            loadExplorer(parentId);
                            setStatusBar(
                              `${appMessages.tree.saved} ${result.name}`,
                            );
                          })
                          .catch(() => {
                            errorAlert(
                              appMessages.app_general_messages.error,
                              appMessages.serverError,
                            );
                          });
                      }
                    },
                  );
                }
              } catch (e) {
                setStatusBar(
                  `${appMessages.app_general_messages.no_selection}`,
                );
              }
            },
          },
          {
            icon: `${__BASE_URL}/ext/appimages/bowtie_add.png`,
            text: appMessages.menu.file.items.new_bowtie,
            id: 'new_bowtie',
            disabled: true,
            handler(): void {
              window.trackEvent([
                '_trackEvent',
                'Toolbar',
                'File',
                'New bowtie',
              ]);
              // add bowtie)
              try {
                caseComp = Ext.getCmp('cases');
                element = Ext.getCmp('cases').getSelectionModel();
                element.selected.items.forEach(function (element) {
                  type = element.data;
                  if (element.data.caseDataID) {
                    caseDataID = element.data.caseDataID;
                  }
                  parentId = element.parentNode.data.caseDataID;
                });
                if (type.type !== 'case' && type.type !== 'tree') {
                  setStatusBar(appMessages.app_general_messages.no_selection);
                  errorAlert(
                    appMessages.app_general_messages.error,
                    appMessages.app_general_messages.no_selection,
                  );
                  return;
                }

                if (caseDataID) {
                  promptWindow(
                    true,
                    'New Bowtie',
                    'new-bowtie',
                    Ext.Msg.OK,
                    function (btn, value) {
                      if (btn === 'ok') {
                        showHideLoadingMask(caseComp);
                        ApiService.createTree(caseDataID, {
                          name: value,
                          bowtie: true,
                          xml: ApiService.saveXML(value, true),
                        })
                          .then((result) => {
                            loadExplorer(caseDataID);
                            setStatusBar(
                              `${appMessages.bowtie.saved} ${result.name}`,
                            );
                          })
                          .catch(() => {
                            errorAlert(
                              appMessages.app_general_messages.error,
                              appMessages.serverError,
                            );
                          });
                      }
                    },
                  );
                }

                if (parentId) {
                  promptWindow(
                    true,
                    'New bowtie',
                    'new-bowtie',
                    Ext.Msg.OK,
                    function (btn, value) {
                      if (btn === 'ok') {
                        showHideLoadingMask(caseComp);
                        ApiService.createTree(parentId, {
                          name: value,
                          bowtie: true,
                          xml: ApiService.saveXML(value, true),
                        })
                          .then((result) => {
                            loadExplorer(parentId);
                            setStatusBar(
                              `${appMessages.bowtie.saved} ${result.name}`,
                            );
                          })
                          .catch(() => {
                            errorAlert(
                              appMessages.app_general_messages.error,
                              appMessages.serverError,
                            );
                          });
                      }
                    },
                  );
                }
              } catch (e) {
                setStatusBar(appMessages.app_general_messages.no_selection);
              }
            },
          },
        ],
      },
    },
    {
      text: appMessages.menu.edit.name,
      menu: [
        {
          iconCls: 'x-fa fa-plus',
          text: appMessages.menu.edit.items.new_case,
          handler(): void {
            window.trackEvent(['_trackEvent', 'Toolbar', 'Edit', 'New case']);
            try {
              caseComp = Ext.getCmp('cases');
              promptWindow(true, 'New case', 'new-case', Ext.Msg.OK, function (
                btn,
                value,
              ) {
                if (btn === 'ok') {
                  showHideLoadingMask(caseComp);
                  ApiService.createCase({
                    name: value,
                  })
                    .then((result) => {
                      loadExplorer();
                      setStatusBar(`${appMessages.case.saved}: ${result.name}`);
                    })
                    .catch(() => {
                      errorAlert(
                        appMessages.app_general_messages.error,
                        appMessages.serverError,
                      );
                    });
                }
              });
            } catch (e) {
              setStatusBar(`${appMessages.case.error}`);
            }
          },
        },
        {
          iconCls: 'x-fa fa-sitemap',
          text: appMessages.menu.edit.items.new_tree,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Edit',
              'New Tripod tree',
            ]);
            try {
              caseComp = Ext.getCmp('cases');
              element = Ext.getCmp('cases').getSelectionModel();
              element.selected.items.forEach(function (element) {
                type = element.data;
                if (element.data.caseDataID) {
                  caseDataID = element.data.caseDataID;
                }
                parentId = element.parentNode.data.caseDataID;
              });
              if (type.type !== 'case' && type.type !== 'tree') {
                setStatusBar(appMessages.app_general_messages.no_selection);
                errorAlert(
                  appMessages.app_general_messages.error,
                  appMessages.app_general_messages.no_selection,
                );
                return;
              }
              if (caseDataID) {
                promptWindow(
                  true,
                  'New tree',
                  'new-tree',
                  Ext.Msg.OK,
                  function (btn, value) {
                    if (btn === 'ok') {
                      showHideLoadingMask(caseComp);
                      ApiService.createTree(caseDataID, {
                        name: value,
                        xml: ApiService.saveXML(value, false),
                      })
                        .then((result) => {
                          loadExplorer();
                          setStatusBar(
                            `${appMessages.tree.saved} ${result.name}`,
                          );
                        })
                        .catch(() => {
                          errorAlert(
                            appMessages.app_general_messages.error,
                            appMessages.serverError,
                          );
                        });
                    }
                  },
                );
              }
              if (parentId) {
                promptWindow(
                  true,
                  'New tree',
                  'new-tree',
                  Ext.Msg.OK,
                  function (btn, value) {
                    if (btn === 'ok') {
                      showHideLoadingMask(caseComp);
                      ApiService.createTree(parentId, {
                        name: value,
                        xml: ApiService.saveXML(value, false),
                      })
                        .then((result) => {
                          loadExplorer();
                          setStatusBar(
                            `${appMessages.tree.saved} ${result.name}`,
                          );
                        })
                        .catch(() => {
                          errorAlert(
                            appMessages.app_general_messages.error,
                            appMessages.serverError,
                          );
                        });
                    }
                  },
                );
              }
            } catch (e) {
              setStatusBar(appMessages.app_general_messages.no_selection);
            }
          },
        },
        {
          iconCls: 'x-fa fa-copy',
          text: appMessages.menu.edit.items.duplicate,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Edit',
              'Duplicate tree / bowtie',
            ]);
            try {
              caseComp = Ext.getCmp('cases');
              element = Ext.getCmp('cases').getSelectionModel();
              element.selected.items.forEach(function (element) {
                type = element.data;
                if (!element.data.bowtie) {
                  caseDataID = element.parentNode.data.caseDataID;
                  currentTreeId = element.data.id;
                }
                caseDataID = element.parentNode.data.caseDataID;
                currentTreeId = element.data.id;
              });
              if (type.type !== 'tree') {
                setStatusBar(appMessages.tree.no_selection);
                errorAlert('Error', appMessages.tree.no_selection);
                return;
              }
              if (window.canvas.isDirty()) {
                messageConfirmBox(
                  appMessages.app_general_messages.save,
                  appMessages.app_general_messages.save_question,
                  (btn) => {
                    if (btn === 'yes') {
                      window.canvas.save().then(() => {
                        setStatusBar(appMessages.tree.saved);
                        duplicate(
                          type.bowtie,
                          caseDataID,
                          currentTreeId,
                          caseComp,
                        );
                      });
                    } else {
                      duplicate(
                        type.bowtie,
                        caseDataID,
                        currentTreeId,
                        caseComp,
                      );
                    }
                  },
                );
              } else {
                duplicate(type.bowtie, caseDataID, currentTreeId, caseComp);
              }
            } catch (e) {
              setStatusBar(appMessages.tree.no_selection);
            }
          },
        },
        '-',
        {
          iconCls: 'x-fa fa-trash',
          text: appMessages.menu.edit.items.delete,
          handler(): void {
            window.trackEvent(['_trackEvent', 'Toolbar', 'Edit', 'Delete']);
            try {
              caseComp = Ext.getCmp('cases');
              element = Ext.getCmp('cases').getSelectionModel();
              element.selected.items.forEach(function (element) {
                type = element;
              });
              if (type.data.type !== 'case' && type.data.type !== 'tree') {
                setStatusBar(appMessages.app_general_messages.no_selection);
                errorAlert(
                  appMessages.app_general_messages.error,
                  appMessages.app_general_messages.no_selection,
                );
                return;
              }
              if (type.data.caseDataID) {
                messageConfirmBox(
                  appMessages.app_general_messages.delete,
                  `${appMessages.app_general_messages.delete_question} ${type.data.type} - ${type.data.name}`,
                  function (btn) {
                    if (btn == 'yes') {
                      showHideLoadingMask(caseComp);
                      ApiService.deleteCase(type.data.caseDataID)
                        .then((result: Trees) => {
                          if (!result.success) return;
                          loadExplorer();
                          window.canvas.clear();
                          window.canvas.hide();
                          setStatusBar(appMessages.case.deleted);
                        })
                        .catch(() => {
                          errorAlert(
                            appMessages.app_general_messages.error,
                            appMessages.serverError,
                          );
                        });
                    }
                  },
                );
              }
              if (typeof type.data.id === 'number') {
                messageConfirmBox(
                  appMessages.app_general_messages.delete,
                  `${appMessages.app_general_messages.delete_question} ${type.data.type} - ${type.data.name}`,
                  function (btn) {
                    if (btn == 'yes') {
                      showHideLoadingMask(caseComp);
                      ApiService.deleteTree(
                        type.parentNode.data.caseDataID,
                        type.id,
                      )
                        .then((result: Trees) => {
                          if (!result.success) return;
                          setStatusBar(appMessages.tree.deleted);
                          loadExplorer();
                          window.canvas.clear();
                          window.canvas.hide();
                          window.canvas.setDirty(false);
                        })
                        .catch(() => {
                          errorAlert(
                            appMessages.app_general_messages.error,
                            appMessages.serverError,
                          );
                        });
                    }
                  },
                );
              }
            } catch (e) {
              setStatusBar(appMessages.app_general_messages.no_selection);
            }
          },
        },
        {
          iconCls: 'x-fa fa-pencil-alt',
          text: appMessages.menu.edit.items.rename,
          handler(): void {
            window.trackEvent(['_trackEvent', 'Toolbar', 'Edit', 'Rename']);
            try {
              let itemId;
              caseComp = Ext.getCmp('cases');
              items = caseComp.getSelectionModel().selected.items;
              const caseComponentEditor = caseComp.findPlugin('cellediting');
              items.forEach((key) => (itemId = key.id));
              caseComponentEditor.startEdit(
                caseComp.getStore().getNodeById(itemId),
                caseComp.down('treecolumn'),
              );
              caseComponentEditor.complete();
            } catch (e) {
              setStatusBar(appMessages.app_general_messages.no_selection);
            }
          },
        },
      ],
    },
    {
      text: appMessages.menu.account.name,
      menu: [
        {
          iconCls: 'x-fa fa-sign-out-alt',
          text: appMessages.menu.account.items.logout,
          handler(): void {
            window.trackEvent(['_trackEvent', 'Toolbar', 'Account', 'Logout']);
            if (window.canvas.isDirty()) {
              const confirmAlert = confirm(
                appMessages.app_general_messages.confirm_logout,
              );

              if (!confirmAlert) {
                return;
              }
            }
            Ext.getCmp('tr2-dashboard').destroy();
            TokenService.removeToken();
            sessionStorage.clear();
            Ext.widget('tr2-login');
          },
        },
        {
          iconCls: 'x-fa fa-key',
          text: appMessages.menu.account.items.change_password,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Account',
              'Change password',
            ]);
            if (!TR2.password) {
              TR2.password = new TR2.Password();
            }
            TR2.password.show();
          },
        },
        {
          iconCls: 'x-fa fa-user',
          text: appMessages.menu.account.items.profile,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Account',
              'My account',
            ]);
            if (!TR2.account) {
              TR2.account = new TR2.Account();
            }
            TR2.account.show();
          },
        },
      ],
    },
    {
      text: appMessages.menu.file.items.import.name,
      id: 'import_export',
      menu: [
        {
          iconCls: 'x-fa fa-file-import',
          id: 'file_import',
          text: appMessages.menu.file.items.import.items.from_file,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Import/Export',
              'Import case/tree from file',
            ]);
            const importModal = new TR2.Import();
            importModal.show();
          },
        },
        {
          iconCls: 'x-fa fa-file-image',
          id: 'export_image',
          text: appMessages.menu.file.items.import.items.image,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Import/Export',
              'Export to png image',
            ]);
            try {
              if (window.canvas.isDirty()) {
                messageConfirmBox(
                  appMessages.app_general_messages.save,
                  appMessages.app_general_messages.save_question,
                  (btn) => {
                    if (btn === 'yes') {
                      // todo: check if canvas is open.
                      window.canvas.save().then(() => {
                        setStatusBar(appMessages.tree.saved);
                        window.canvas.exportToPng(false);
                      });
                    } else {
                      window.canvas.exportToPng(false);
                    }
                  },
                );
              } else {
                window.canvas.exportToPng(false);
              }
            } catch (e) {
              setStatusBar(appMessages.tree.error);
            }
          },
        },
        {
          iconCls: 'x-fa fa-file-image',
          id: 'export_image_transparent',
          text: appMessages.menu.file.items.import.items.image_transparent,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Import/Export',
              'Export to transparent png image',
            ]);
            try {
              if (window.canvas.isDirty()) {
                messageConfirmBox(
                  appMessages.app_general_messages.save,
                  appMessages.app_general_messages.save_question,
                  (btn) => {
                    if (btn === 'yes') {
                      // todo: check if canvas is open.
                      window.canvas.save().then(() => {
                        setStatusBar(appMessages.tree.saved);
                        window.canvas.exportToPng(true);
                      });
                    } else {
                      window.canvas.exportToPng(true);
                    }
                  },
                );
              } else {
                window.canvas.exportToPng(true);
              }
            } catch (e) {
              setStatusBar(appMessages.tree.error);
            }
          },
        },
        {
          iconCls: 'x-fa fa-file-pdf',
          id: 'export_pdf',
          text: appMessages.menu.file.items.import.items.pdf,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Import/Export',
              'Export to vector pdf image',
            ]);
            window.canvas.exportToPDF();
          },
        },
        {
          iconCls: 'x-fa fa-file-export',
          id: 'case_export',
          text: appMessages.menu.file.items.import.items.case,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Import/Export',
              'Export case to file',
            ]);
            try {
              element = Ext.getCmp('cases').getSelectionModel();
              element.selected.items.forEach(function (element) {
                type = element.data;
                caseDataID = element.data.caseDataID;
              });
              if (type.type !== 'case') {
                setStatusBar(appMessages.case.no_selection);
                errorAlert('Error', appMessages.case.no_selection);
                return;
              }
              if (window.canvas.isDirty()) {
                messageConfirmBox(
                  appMessages.app_general_messages.save,
                  appMessages.app_general_messages.save_question,
                  (btn) => {
                    if (btn === 'yes') {
                      window.canvas.save().then(() => {
                        setStatusBar(appMessages.case.saved);
                        downloadCase(caseDataID);
                      });
                    } else {
                      downloadCase(caseDataID);
                    }
                  },
                );
              } else {
                downloadCase(caseDataID);
              }
            } catch (e) {
              setStatusBar(appMessages.case.no_selection);
            }
          },
        },
        {
          iconCls: 'x-fa fa-file-export',
          id: 'tree_export',
          text: appMessages.menu.file.items.import.items.tree,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Import/Export',
              'Export tree to file',
            ]);
            try {
              element = Ext.getCmp('cases').getSelectionModel();
              element.selected.items.forEach(function (element) {
                type = element.data;
                caseDataID = element.parentNode.data.caseDataID;
                currentTreeId = element.data.treeId;
              });
              if (type.type !== 'tree') {
                setStatusBar(appMessages.tree.no_selection);
                errorAlert('Error', appMessages.tree.no_selection);
                return;
              }
              if (window.canvas.isDirty()) {
                messageConfirmBox(
                  appMessages.app_general_messages.save,
                  appMessages.app_general_messages.save_question,
                  (btn) => {
                    if (btn === 'yes') {
                      window.canvas.save().then(() => {
                        downloadTree(caseDataID, currentTreeId);
                        setStatusBar(appMessages.tree.saved);
                      });
                    } else {
                      downloadTree(caseDataID, currentTreeId);
                    }
                  },
                );
              } else {
                downloadTree(caseDataID, currentTreeId);
              }
            } catch (_e) {
              setStatusBar(appMessages.tree.no_selection);
            }
          },
        },
        {
          iconCls: 'x-fa fa-file-alt',
          id: 'word_export',
          text: appMessages.menu.report.items.word_export,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Report',
              'Word summary',
            ]);

            if (!TR2.wordselector) {
              TR2.wordselector = new TR2.WordSelector();
            }

            TR2.wordselector.show();
          },
        },
      ],
    },
    {
      text: appMessages.menu.report.name,
      menu: [
        {
          iconCls: 'x-fa fa-list',
          text: appMessages.menu.report.items.risk_factors,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Report',
              'Basic Risk Factors',
            ]);
            if (!TR2.accumulate) {
              TR2.accumulate = new TR2.Accumulate();
            }
            TR2.accumulate.show();
          },
        },
        {
          iconCls: 'x-fa fa-file-alt',
          text: appMessages.menu.report.items.summary,
          handler(): void {
            window.trackEvent([
              '_trackEvent',
              'Toolbar',
              'Report',
              'Tree/bowtie summary',
            ]);
            if (!TR2.caseselector) {
              TR2.caseselector = new TR2.CaseSelector();
            }
            TR2.caseselector.show();
          },
        },
      ],
    },
    {
      text: appMessages.menu.help.name,
      menu: {
        xtype: 'menu',
        items: [
          {
            iconCls: 'x-fa fa-question-circle',
            text: appMessages.menu.help.items.faq,
            href:
              'https://tripodincidentanalyse.nl/veelgestelde-vragen/',
            hrefTarget: '_blank',
            handler(): void {
              window.trackEvent([
                '_trackEvent',
                'Toolbar',
                'Help',
                'FAQ manual',
              ]);
            },
          },
          {
            iconCls: 'x-fa fa-book',
            text: appMessages.menu.help.items.manual,
            href: 'https://tripodincidentanalyse.nl/handleidingen',
            hrefTarget: '_blank',
            handler(): void {
              window.trackEvent([
                '_trackEvent',
                'Toolbar',
                'Help',
                'Help manual',
              ]);
            },
          },
          {
            iconCls: 'x-fa fa-info-circle',
            text: appMessages.menu.help.items.about,
            handler(): void {
              window.trackEvent([
                '_trackEvent',
                'Toolbar',
                'Help',
                'About TR2',
              ]);
              if (!TR2.about) {
                TR2.about = new TR2.About();
              }
              TR2.about.show();
            },
          },
        ],
      },
    },
    {
      text: appMessages.menu.feedback.name,
      handler(): void {
        window.trackEvent(['_trackEvent', 'Toolbar', 'Feedback', 'Feedback']);
        if (!TR2.feedback) {
          TR2.feedback = new TR2.Feedback();
        }
        TR2.feedback.show();
      },
      hrefTarget: '_blank',
      cls: 'feedback',
      style: {
        background: 'transparent',
        border: 'none',
      },
    },
    {
      id: 'status',
      listeners: {
        beforeRender(btn): void {
          btn.setText(
            appMessages.app_general_messages.network_status.online.name,
          );
          window.addEventListener('load', () => {
            hasNetwork(navigator.onLine);
            window.addEventListener('online', () => {
              hasNetwork(true);
              showMessage(
                appMessages.app_general_messages.network_status.online.name,
                appMessages.app_general_messages.network_status.online.message,
              );
              btn.setText(
                appMessages.app_general_messages.network_status.online.name,
              );
            });

            window.addEventListener('offline', () => {
              hasNetwork(false);
              showMessage(
                appMessages.app_general_messages.network_status.offline.name,
                appMessages.app_general_messages.network_status.offline.message,
              );
              btn.setText(
                appMessages.app_general_messages.network_status.offline.name,
              );
            });
          });
        },
      },
      handler(): void {
        window.trackEvent(['_trackEvent', 'Toolbar', 'Feedback', 'Status']);
      },
      hrefTarget: '_blank',
      cls: 'status',
      style: {
        background: 'transparent',
        border: 'none',
      },
    },
  ],
};

Ext.define('tr2-toolbar', TR2.Toolbar);
