import ApiService from './services/api.service';
import appMessages from './i18n/en/appMessages.json';
import { errorAlert } from './services/app/global.service';

const login = async (): Promise<void> => {
  const email = Ext.getCmp('email').getValue();
  const password = Ext.getCmp('password').getValue();

  const validInputs = email !== '' && password !== '';
  if (!validInputs) {
    errorAlert(
      appMessages.app_general_messages.error,
      appMessages.app_general_messages.form_errors.message_one,
    );
    return;
  }
  Ext.getCmp('signInBtn').setDisabled(true);
  Ext.get('mask').show();

  try {
    await ApiService.login({ email, password });
    Ext.getCmp('tr2-login').destroy();
    Ext.widget('tr2-dashboard');
  } catch (e) {
    errorAlert(
      appMessages.app_general_messages.error,
      appMessages.loginForm.invalidCredentials,
    );
    Ext.getCmp('signInBtn').setDisabled(false);
  } finally {
    Ext.get('mask').hide();
  }
};

TR2.Login = {
  extend: 'Ext.Viewport',
  xtype: 'tr2-login',
  id: 'tr2-login',
  layout: {
    type: 'vbox',
    align: 'middle',
    pack: 'center',
  },
  items: [
    {
      xtype: 'panel',
      title: 'Login',
      width: 400,
      items: [
        {
          xtype: 'form',
          reference: 'loginForm',
          autoEl: 'form',
          bodyPadding: 10,
          border: false,
          frame: false,
          items: [
            {
              xtype: 'textfield',
              id: 'email',
              name: 'email',
              fieldLabel: 'E-mail',
              allowBlank: false,
              width: '100%',
              listeners: {
                specialkey: function (_f: any, e: any): void {
                  if (e.getKey() !== e.ENTER) {
                    return;
                  }
                  login();
                },
              },
            },
            {
              xtype: 'textfield',
              name: 'password',
              inputType: 'password',
              fieldLabel: 'Password',
              id: 'password',
              allowBlank: false,
              width: '100%',
              listeners: {
                specialkey: function (_f: any, e: any): void {
                  if (e.getKey() !== e.ENTER) {
                    return;
                  }
                  login();
                },
              },
            },
          ],
          buttons: [
            {
              text: 'Sign in',
              formBind: true,
              id: 'signInBtn',
              disabled: false,
              listeners: {
                click: login,
              },
            },
          ],
        },
      ],
    },
    {
      xtype: 'container',
      width: 400,
      height: 120,
      cls: 'logo-container',
      bodyStyle: { 'background-color': 'unset' },
      items: [
        {
          xtype: 'image',
          src: `${__BASE_URL}/assets/logo-tripod.png`,
          cls: 'main-logo',
        },
      ],
    },
  ],
};

Ext.define('tr2-login', TR2.Login);
