import BlockElement from './blockElement';
import ContextMenu from "../ui/ContextMenu";

export default class Comment extends BlockElement {
  static get _className(): string {
    return 'Comment';
  }
  public _className = 'Comment';

  constructor(x: number, y: number, guid = '', w: number, h: number) {
    super(x, y, guid, w, h);

    this.setFillColor('#D5D5D5');
  }

  showContextMenu(x: number, y: number): void {
    const canvasElement = document.getElementById('scrollcontainer')! as HTMLElement;
    this.elementContextMenu = new ContextMenu(canvasElement);

    const contextMenuTitle = {
      element: `${this._className}`,
      id: 'menu-title',
    };

    this.elementContextMenu.titleFactory(
      contextMenuTitle.element,
      contextMenuTitle.id,
    );
    this.elementContextMenu.defaultElementFactory(this);

    this.elementContextMenu.show(x, y);
  }
}
