import draw2d from 'draw2d';

draw2d.layout.locator.LatentFailureSelectLocator = draw2d.layout.locator.Locator.extend(
  {
    NAME: 'draw2d.layout.locator.LatentFailureSelectLocator',

    /**
     * @constructor
     *
     *
     */
    init: function () {
      this._super();
    },

    /**
     * @method
     * Relocates the given Figure.
     *
     * @param {Number} _index child index of the target
     * @param {draw2d.Figure} target The figure to relocate
     **/
    relocate: function (_index, target) {
      const d2dElement = target.getParent();
      const boundingBox = d2dElement.getBoundingBox();

      const xFactor = d2dElement.userData.element.radius / boundingBox.w;
      const yFactor = (boundingBox.h - 30) / boundingBox.h;

      target.setPosition(boundingBox.w * xFactor, boundingBox.h * yFactor);
    },
  },
);

export const LatentFailureSelectLocator =
  draw2d.layout.locator.LatentFailureSelectLocator;
