import draw2d from 'draw2d';
import ElementDescription from './utils/ElementDescription';

window.contentToolbar = [
  {
    icon: __BASE_URL + '/ext/appimages/magnifier_zoom_in.png',
    tooltip: 'Zoom in',
    handler: function (): void {
      window.canvas.setZoom(window.canvas.getZoom() + 0.1);
    },
  },
  {
    icon: __BASE_URL + '/ext/appimages/magifier_zoom_out.png',
    tooltip: 'Zoom out',
    handler: function (): void {
      window.canvas.setZoom(window.canvas.getZoom() - 0.1);
    },
  },
  {
    icon: __BASE_URL + '/ext/appimages/arrow_out.png',
    tooltip: 'Zoom fit',
    handler: function (): void {
      window.canvas.zoomFit();
    },
  },
  {
    id: 'zoom',
    xtype: 'combobox',
    width: 100,
    typeAhead: true,
    triggerAction: 'all',
    lazyRender: true,
    mode: 'local',
    value: '100%',
    selectOnFocus: true,
    displayField: 'label',
    valueField: 'label',
    style: {
      zIndex: 2,
    },
    store: new Ext.data.ArrayStore({
      fields: ['label'],
      data: [['150%'], ['100%'], ['75%'], ['50%'], ['25%'], ['10%']],
    }),
    listeners: {
      specialkey: function (_field, e): void {
        if (e.getKey() == e.ENTER) {
          const val = (this as any).el.dom.value;
          const v = '0123456789';
          let z = '';
          for (let i = 0; i < val.length; i++) {
            if (v.indexOf(val[i]) >= 0) {
              z = z + val[i];
            }
          }
          let parsedValue = parseInt(z) / 100;
          if (!isNaN(parsedValue)) {
            parsedValue = parsedValue < 0.1 ? 0.1 : parsedValue;
            window.canvas.setZoom(parsedValue);
          }
        }
      },
      select: function (_field, rec): void {
        let z = parseInt(rec.get('label').replace('%', '')) / 100;
        z = z < 0.1 ? 0.1 : z;
        window.canvas.setZoom(z);
      },
    },
  },
  '-',
  {
    id: 'toolbar-drag-subtree',
    icon: __BASE_URL + '/ext/appimages/chart_organisation_add.png',
    text: 'Drag subtree',
    tooltip: 'Drag subtree',
    enableToggle: true,
    handler: function (): void {
      window.canvas.setDragSubtree(this.pressed);
    },
  },
  '-',
  {
    icon: __BASE_URL + '/ext/appimages/arrow_undo.png',
    tooltip: 'Undo',
    handler: function (): void {
      window.canvas.undo();
    },
  },
  {
    icon: __BASE_URL + '/ext/appimages/arrow_redo.png',
    tooltip: 'Redo',
    handler: function (): void {
      window.canvas.redo();
    },
  },
  {
    icon: __BASE_URL + '/ext/appimages/align-vert.png',
    tooltip: 'Align vertical',
    handler: function (): void {
      window.canvas.alignSelectionVertical();
    },
  },
  {
    icon: __BASE_URL + '/ext/appimages/align-horz.png',
    tooltip: 'Align horizontal',
    handler: function (): void {
      window.canvas.alignSelectionHorizontal();
    },
  },
  {
    iconCls: 'x-fa fa-hand-pointer',
    tooltip: 'Drag canvas',
    handler: function (): void {
      document.getElementById('display')!.style.cursor = 'pointer';
      window.canvas.d2dCanvas.installEditPolicy(
        new draw2d.policy.canvas.PanningSelectionPolicy(),
      );
    },
  },
  {
    iconCls: 'x-fa fa-object-group',
    tooltip: 'Scroll canvas',
    handler: function (): void {
      document.getElementById('display')!.style.cursor = 'auto';
      window.canvas.d2dCanvas.uninstallEditPolicy(
        new draw2d.policy.canvas.PanningSelectionPolicy(),
      );
      window.canvas.d2dCanvas.installEditPolicy(
        new draw2d.policy.canvas.BoundingboxSelectionPolicy(),
      );
    },
  },

  {
    iconCls: 'x-fa fa-th',
    tooltip: 'Show canvas grid',
    handler: function (): void {
      window.canvas.d2dCanvas.installEditPolicy(
        new draw2d.policy.canvas.SnapToGridEditPolicy(),
      );
    },
  },
  {
    iconCls: 'x-fa fa-square-full',
    tooltip: 'Remove canvas grid',
    handler: function (): void {
      window.canvas.d2dCanvas.uninstallEditPolicy(
        new draw2d.policy.canvas.SnapToGridEditPolicy(),
      );
    },
  },
  { xtype: 'tbfill' },
  {
    text: 'Show descriptions #',
    tooltip: 'Show element description number',
    handler: function (btn) {
      const body = ElementDescription.exportWithDescription();
      if (btn.text === 'Show descriptions #') {
        body.forEach((canvasElement, _index) => {
          const { exportId } = canvasElement;
          canvasElement.element.exportIdTooltip.text = `${exportId}`;
          canvasElement.element.showExportTooltip();
        });
        btn.setText('Hide descriptions #');
      } else if (btn.text === 'Hide descriptions #') {
        body.forEach((canvasElement, _index) => {
          canvasElement.element.d2dElement.remove(
            canvasElement.element.exportIdTooltip,
          );
          canvasElement.element.exportIdTooltip.text = '';
        });
        btn.setText('Show descriptions #');
      }
    },
  },
];

export default toolbar;
