import CanvasLoader from './CanvasLoader';
import { LoaderLinkInterface } from './LoaderLinkInterface';
import { LoaderElementInterface } from './LoaderElementInterface';

export default class XMLLoader extends CanvasLoader {
  constructor(xmlString: string) {
    const domParser = new DOMParser();
    const xml = domParser.parseFromString(xmlString, 'application/xml');
    const trees = xml.getElementsByTagName('tree');
    const tree = trees[0];

    window.canvas.treeData = {
      name: tree.getElementsByTagName('name')[0].childNodes[0]
        .nodeValue as string,
      bowtie: parseInt(
        tree.getElementsByTagName('bowtie')[0].childNodes[0]
          .nodeValue as string,
      ),
    };

    const elements: LoaderElementInterface[] = [];
    const links: LoaderLinkInterface[] = [];

    for (const element of tree.getElementsByTagName('element')) {
      const label = element.getElementsByTagName('label');
      elements.push({
        guid: element.getElementsByTagName('guid')[0].childNodes[0]
          .nodeValue as string,
        name: element.getElementsByTagName('name')[0].childNodes[0]
          .nodeValue as string,
        description: element.getElementsByTagName('description')[0]
          .childNodes[0].nodeValue as string,
        type: element.getElementsByTagName('type')[0].childNodes[0]
          .nodeValue as string,
        w: element.getElementsByTagName('w').length
          ? parseInt(
              element.getElementsByTagName('w')[0].childNodes[0]
                .nodeValue as string,
            )
          : 160,
        h: element.getElementsByTagName('h').length
          ? parseInt(
              element.getElementsByTagName('h')[0].childNodes[0]
                .nodeValue as string,
            )
          : 100,
        x:
          parseInt(
            element.getElementsByTagName('x')[0].childNodes[0]
              .nodeValue as string,
          ) + window.canvas.centerX,
        y:
          parseInt(
            element.getElementsByTagName('y')[0].childNodes[0]
              .nodeValue as string,
          ) + window.canvas.centerY,
        number: parseInt(
          element.getElementsByTagName('number')[0].childNodes[0]
            .nodeValue as string,
        ),
        label: label.length
          ? parseInt(label[0].childNodes[0].nodeValue as string)
          : null,
        style: {
          fontSize: parseInt(
            element
              .getElementsByTagName('style')[0]
              .getElementsByTagName('font_size')[0].childNodes[0]
              .nodeValue as string,
          ),
        },
      });
    }

    for (const link of tree.getElementsByTagName('link')) {
      links.push({
        from: link.getElementsByTagName('from')[0].childNodes[0]
          .nodeValue as string,
        to: link.getElementsByTagName('to')[0].childNodes[0]
          .nodeValue as string,
      });
    }

    super(elements, links);
  }
}
