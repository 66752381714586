import TokenService from '../token.service';

export function errorAlert(title: string, msg: string): Ext {
  return Ext.Msg.alert(title, msg, Ext.emptyFn());
}

export function automaticSave(): void {
  if (!TokenService.hasToken()) return;
  setTimeout(() => {
    if (
      window.canvas &&
      window.navigator.onLine &&
      window.canvas.isDirty() &&
      TokenService.hasToken()
    ) {
      window.canvas.save();
      return;
    }
  }, 500);
}

export function initializeAutomaticSave(): void {
  const initDate = new Date();
  let interval;
  window.onload = function (): void {
    automaticSave();

    interval = window.setInterval(function () {
      const now = new Date();
      if (
        now.getTime() - initDate.getTime() < 8 * 60 * 60 * 1000 &&
        now.getDate() == initDate.getDate()
      ) {
        automaticSave();
      } else {
        window.clearInterval(interval);
      }
    }, 10 * 1000);
  };
}

export function setStatusBar(msg: string): Ext {
  return Ext.getCmp('statusbar').setStatus(msg);
}

export function messageConfirmBox(
  title: string,
  msg: string,
  callback: any,
): Ext {
  return Ext.MessageBox.confirm(title, msg, callback);
}

export function hasNetwork(online: boolean): void {
  const element = document.getElementById('status-btnInnerEl')!;
  if (element) {
    if (online) {
      element.classList.remove('offline');
      element.classList.add('online');
    } else {
      element.classList.remove('online');
      element.classList.add('offline');
    }
  }
}

export function showMessage(title: string, msg: string): void {
  errorAlert(title, msg);
}
